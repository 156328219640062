import React from 'react';

import { ConsistentCartItem } from '../ConsistentCartItem';

import { CartsType, ECartStatus, IItem } from '../../../../../bi/types/cart';
import { ConsistentCartModes } from '../../../../../bi/constants/approve';

interface IConsistentCartListProps {
  checkUnderage: boolean;
  list: CartsType[];
  type: ECartStatus;
  showApproversCKR: boolean;
  onClick: (i: CartsType) => void;
  onTimeIsUp: (item: IItem) => void;
  onDelete: (i: CartsType, airToNote: boolean) => void;
  onPurchase: (i: CartsType, isUnderAge: boolean, val: boolean) => void;
  alreadySentIds?: number[];
  readonly?: boolean;
  mode?: string;
  qaAttrFirstEl?: string;
  qaAttr?: string;
}

const ConsistentCartList = ({
  list = [],
  type,
  onDelete,
  onPurchase,
  onClick,
  onTimeIsUp = () => {},
  readonly = false,
  mode = ConsistentCartModes.viewer,
  checkUnderage,
  qaAttrFirstEl = '',
  qaAttr = '',
  showApproversCKR,
  alreadySentIds,
}: IConsistentCartListProps) => {
  const html = list.map(
    (item, index) => {
      const qaAttrFirst = index === 0 ? qaAttrFirstEl : '';
      const qaAttrResult = qaAttr || qaAttrFirst;

      return (
        <ConsistentCartItem
          key={ item.id }
          type={ type }
          item={ item }
          onDelete={ onDelete }
          onPurchase={ onPurchase }
          onClick={ onClick }
          onTimeIsUp={ onTimeIsUp }
          readonly={ readonly }
          mode={ mode }
          checkUnderage={ checkUnderage }
          qaAttr={ qaAttrResult }
          showApproversCKR={ showApproversCKR }
          alreadySentIds={ alreadySentIds }
        />
      );
    },
  );

  return (
    <>
      { ...html }
    </>
  );
};

export { ConsistentCartList };
