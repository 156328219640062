import React from 'react';
import { Text } from 'new-ui';

import styles from '../styles/intercom.module.css';

const WarningAeroexpress = () => (
  <div className={ styles.text_wrapper }>
    <Text type='bold_18'>
      К сожалению, из-за сбоя на стороне поставщика, выписка билетов на Аэроэкспресс временно недоступна.
    </Text>
    <Text type='bold_18' className={ styles.text }>
      Мы уже отправили отчет техническим специалистам, но точных сроков, когда все исправят, пока нет.
    </Text>
    <Text type='bold_18' className={ styles.text }>
      Попробуйте, пожалуйста, повторить поиск позже.
    </Text>
  </div>
);

export { WarningAeroexpress };
