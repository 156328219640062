import React, { useMemo, useState } from 'react';
import { Moment } from 'moment';
import { Text, Button, Dialog, IconButton, Price } from 'new-ui';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { EditableText } from '../EditableText';

import EventService from '../../bi/services/events';

import { formatRangeDateWithSimplicity } from '../../bi/utils/formatDate';
import { svgColor } from '../../bi/utils/env';
import { MainAnalytic } from '../../bi/utils/analytics';

import styles from './styles/index.module.css';

interface ITripNameProps {
  name: string,
  price?: number,
  isCheckout?: boolean,
  minDate: Moment | null,
  maxDate: Moment,
  eventService: EventService,
  onChange(value: string): void,
  onClick(): void,
  airlineBaggageServiceDeleteOffers(): void,
  qaAttrName: string,
  qaAttrInput?: string,
  qaAttrSave: string,
  qaAttrClose: string,
  qaAttrDeleteAll?: string,
  qaAttrModalWrapper?: string,
  qaAttrModalSuccess?: string,
  qaAttrModalCancel?: string,
  qaAttrModalClose?: string,
  qaAttrPrice?: string,
  hasBaggageOffers: boolean,
}

export const createLabels = (t: TFunction) => ({
  REMOVE_ALL: t('components:tripName.removeAll'),
  DIALOG_TITLE: t('components:tripName.dialog.title'),
  DIALOG_DESCRIPTION: t('components:tripName.dialog.description'),
  OK: t('common:ok'),
  CANCEL: t('common:cancel'),
});

const TripName = ({
  name,
  price,
  isCheckout,
  minDate,
  maxDate,
  eventService,
  onChange,
  onClick,
  airlineBaggageServiceDeleteOffers,
  qaAttrName = '',
  qaAttrInput = '',
  qaAttrSave = '',
  qaAttrClose = '',
  qaAttrDeleteAll = '',
  qaAttrModalWrapper = '',
  qaAttrModalSuccess = '',
  qaAttrModalCancel = '',
  qaAttrModalClose = '',
  qaAttrPrice = '',
  hasBaggageOffers,
}:ITripNameProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const [showDialog, setShowDialog] = useState(false);
  const {
    setOpenSwitcher,
    setValueSwitcher,
  } = eventService;

  const handleDelete = () => {
    onClick();
    setShowDialog(false);

    setOpenSwitcher(false);
    setValueSwitcher('');

    if (!isCheckout) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.CART_DELETE_EVERYTHING);
    }

    if (hasBaggageOffers) {
      airlineBaggageServiceDeleteOffers();
    }
  };

  const handleChange = (value: string) => {
    onChange(value);

    if (!isCheckout) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.CART_CHANGED_NAME);
    }
  };

  const datesString = formatRangeDateWithSimplicity(minDate?.isValid() ? minDate : maxDate, maxDate);
  const datesContent = datesString && (
  <Text className={ styles.dates } type='NORMAL_14' color='gray'>
    { datesString }
  </Text>
  );

  const rightHtml = isCheckout && price ? (
    <div className={ styles.price }>
      <Price qaAttr={ qaAttrPrice } value={ Number(price) }/>
    </div>
  ) : (
    <Button
      qaAttr={ qaAttrDeleteAll }
      type='textual'
      onClick={ () => setShowDialog(true) }
    >
      { LABELS.REMOVE_ALL }
    </Button>
  );

  const wrapperClassNames = [styles.wrapper];

  if (isCheckout) {
    wrapperClassNames.push(styles.checkout);
  }

  return (
    <div className={ wrapperClassNames.join(' ') }>
      <div className={ styles.left }>
        <EditableText
          renderPreview={ () => (
            <IconButton
              iconType='menuTrips'
              iconColor={ svgColor }
              className={ styles.preview_icon }
            >
              <Text type={ isCheckout ? 'bold_24' : 'NORMAL_16' } className={ styles.preview_text }>
                { name }
              </Text>
            </IconButton>
          ) }
          onChange={ handleChange }
          value={ name }
          qaAttrInput={ qaAttrInput }
          qaAttrSave={ qaAttrSave }
          qaAttrClose={ qaAttrClose }
          qaAttrWrapper={ qaAttrName }
        />
        { datesContent }
      </div>
      <div className={ styles.right }>
        { rightHtml }
      </div>
      <Dialog
        show={ showDialog }
        onChange={ setShowDialog }
        showClosing
        qaAttrWrapper={ qaAttrModalWrapper }
        qaAttr={ qaAttrModalClose }
      >
        <div className={ styles.dialog_container }>
          <Text type='bold_20'>{ LABELS.DIALOG_TITLE }</Text>
          <Text className={ styles.text } type='NORMAL_14_130'>
            { LABELS.DIALOG_DESCRIPTION }
          </Text>
          <div className={ styles.actions }>
            <Button
              type='primary'
              onClick={ handleDelete }
              qaAttr={ qaAttrModalSuccess }
            >
              { LABELS.OK }
            </Button>
            <Button
              className={ styles.cancel }
              type='textual-medium'
              onClick={ () => setShowDialog(false) }
              qaAttr={ qaAttrModalCancel }
            >
              { LABELS.CANCEL }
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default TripName;
