import ACTION from '../intercom/action';
import { IUserChat } from '../../../types/chat';

class SmartChat {
  api: any;
  store: any;
  title: string;

  constructor(api: any, store: any) {
    this.api = api;
    this.store = store;

    this.title = document.title;
  }

  // TODO: fix
  getUserAuthorizeData = () => this.api.carrotQuestChat.userAuthorizeData();

  show = () => {
    window.smartwayWidget.open();
  };

  showMessages = () => {
    window.smartwayWidget.open();
  };

  hide = () => {
    window.smartwayWidget.close();
  };

  openSession = () => { // нет такого метода в Intercom, заглушка для декоратора
  };

  closeSession = () => { // нет такого метода в Intercom, заглушка для декоратора
  };

  updateSession = () => null;

  sendMessageToAdmin = (user: IUserChat | null, msg: string, note: string = '', supressWidget: boolean = false, tripId: string = '', tripItemId: number = 0) => {
    // @ts-ignore
    const messageSenderUser = 1;

    this.store.dispatch({ type: ACTION.STARTSENDINGMESSAGETOADMIN });
    // @ts-ignore
    // eslint-disable-next-line newline-before-return
    return this.api.chat.sendMessageToAdmin({
      Message: msg,
      Email: user?.email,
      note,
      MessageSender: messageSenderUser,
      TripId: tripId,
      TripItemId: tripItemId })
      .then(() => {
        if (supressWidget) {
          return;
        }

        this.showMessages();
      }, 1000)
      .finally(() => this.store.dispatch({ type: ACTION.ENDSENDINGMESSAGETOADMIN }));
  };

  requestError = (errorMsg: string) => {
    if (errorMsg) {
      // @ts-ignore
      this.sendMessageToAdmin('', errorMsg); // TODO: первый параметр должен быть объектом или null
    }
  };
}

export default SmartChat;
