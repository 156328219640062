import { ParsedQuery } from 'query-string';

import { getText } from '../../../../i18n';

import debounce from '../../api/debounce';

import type {
  FavoriteTransferItem,
  PrepareRateType,
  SearchItem,
  SearchObject,
  TransferType,
  TagType,
  CartItemType,
} from './types';
import { ISelectedSuggest, IFilter } from '../../types/transfer';
import { TravelPolicyItem } from '../userSession/types';

import { ITransferSearchStore, TransferSearchStore } from './store/search';
import { ITransferStore, TransferStore } from './store/transfer';

import { MAPCARCLASS_RU } from '../../constants/transfer';
import { FILTER_TYPE, VALUES_TABLETS } from '../../constants/transfers';
import { TRAVELPOLICYFILTER } from '../../constants/travelPolicy';

import MoneyFormat from '../../utils/money';
import { prepareRates, prepareTransferPlaces } from '../../utils/transfers';
import { MainAnalytic } from '../../utils/analytics';

const LABELS = {
  TRAVEL_POLICY: (text: string) => getText('services:transfer.store.transfer.tags.travelPolicy', { text }),
  PRICE_FROM_TO: (from: string, to: string) => getText('services:transfer.store.transfer.tags.priceFromTo', { from, to }),
  CLASS: (text: string) => getText('services:transfer.store.transfer.tags.class', { text }),
  FAVORITE: (text: string) => getText('services:transfer.store.transfer.tags.favorite', { text }),
  WITH_TABLET: getText('services:transfer.store.transfer.tags.withTablet'),
  WITHOUT_TABLET: getText('services:transfer.store.transfer.tags.withoutTablet'),
};

const DEBOUNCE_TIME = 200;

class Transfer {
  api: any;
  xhr: any | null;
  searchStore: ITransferSearchStore;
  transferStore: ITransferStore;
  xhrAutocomplete: any;
  debounceAutocomplete: any | null;

  constructor(api: any) {
    this.api = api.transfer;
    this.xhr = null;

    this.debounceAutocomplete = debounce(this.api.autocomplete, DEBOUNCE_TIME);
    this.transferStore = TransferStore;
    this.searchStore = TransferSearchStore;
  }

  updateSuggestions = async (type: string, value: string): Promise<void> => {
    if (value === '') {
      return this.autocomplete(value).catch(() => {
        this.searchStore.updateTransferFromTo(type, {
          value,
          suggestions: [],
          selected: null,
        });
      });
    }

    return this.autocomplete(value).then((response: any) => {
      this.searchStore.updateTransferFromTo(type, {
        value,
        suggestions: response || [],
      });
    });
  };

  selectedSuggest = (type: string, value: any): void => {
    this.searchStore.updateTransferFromTo(type, {
      selected: value,
      suggestions: [],
      value: value.Address,
    });
  };

  autocomplete = (query: string): Promise<void> => {
    if (this.xhrAutocomplete) this.xhrAutocomplete.abort();

    this.xhrAutocomplete = this.debounceAutocomplete(query.trim());
    this.xhrAutocomplete.then((res: ISelectedSuggest[] | []) => {
      if (res) {
        return res;
      }

      return null;
    });

    return this.xhrAutocomplete;
  };

  getSearchObject = () => {
    const {
      transferFrom: { selected: selectedFrom },
      transferTo: { selected: selectedTo },
    } = this.searchStore;

    return ({
      StartPlace: {
        Address: selectedFrom?.Address,
        Coordinates: {
          Latitude: selectedFrom?.Coordinates.Latitude,
          Longitude: selectedFrom?.Coordinates.Longitude,
        },
        Country: selectedFrom?.Country,
      },
      EndPlace: {
        Address: selectedTo?.Address,
        Coordinates: {
          Latitude: selectedTo?.Coordinates.Latitude,
          Longitude: selectedTo?.Coordinates.Longitude,
        },
        Country: selectedTo?.Country,
      },
    });
  };

  getSearchObjectByFavorites = (items: FavoriteTransferItem) => {
    const {
      Places: {
        FinishPlace: {
          Address: AddressTo,
          Coordinates: CoordinatesTo,
        },
        StartPlace: {
          Address: AddressFrom,
          Coordinates: CoordinatesFrom,
        },
      },
    } = items;

    return ({
      StartPlaceAddress: AddressFrom,
      StartPlaceLatitude: CoordinatesFrom.Latitude,
      StartPlaceLongitude: CoordinatesFrom.Longitude,
      EndPlaceAddress: AddressTo,
      EndPlaceLatitude: CoordinatesTo.Latitude,
      EndPlaceLongitude: CoordinatesTo.Longitude,
    });
  };

  changeFavoriteStatus = (id: string, favoriteId: string) => this.transferStore.setFavorite(id, favoriteId);

  swapPlaces = (): void => this.searchStore.swapPlaces(this.searchStore.transferFrom, this.searchStore.transferTo);

  failTransfer = (): void => this.transferStore.setFailTransfer();

  validationTransferFields = (): void => this.transferStore.setValidationTransferFields();

  checkValidViewerPhoneNumber = (value: boolean): void => this.transferStore.checkValidViewerPhoneNumber(value);

  setTransferCart = (items: TransferType): void => this.transferStore.setTransferCart(items);

  updateTransferCart = (item: CartItemType): void => this.transferStore.updateTransferCart(item);

  deleteTransferCart = (item: { BookId: string }): void => this.transferStore.deleteTransferCart(item);

  successHistoryLoad = (data: SearchObject[]): void => this.transferStore.setHistory(data || []);

  setNewSearch = (): void => this.transferStore.resetStore();

  search = async (travelPolicy: TravelPolicyItem[]): Promise<string | void> => {
    this.transferStore.setLoading(true);

    const body = this.getSearchObject();

    try {
      const result = await this.api.search(body);

      const { SearchId, Rates } = result;
      const rates = prepareRates(Rates);

      this.searchStore.setSearchId(SearchId);
      this.transferStore.setSources(rates, travelPolicy);
      this.createTags();
      this.transferStore.setLoading(false);

      return SearchId;
    } catch (e) {
      this.failTransfer();
    }

    return '';
  };

  searchByGuid = async (guid: string, travelPolicy: TravelPolicyItem[]): Promise<void> => {
    this.transferStore.setLoading(true);

    return this.api.getSearchByGuid(guid).then((response: SearchItem) => {
      const { Places: { StartPlace, FinishPlace }, Rates, SearchId } = response;

      const rates = prepareRates(Rates);

      this.searchStore.updateTransferFrom(prepareTransferPlaces(StartPlace));
      this.searchStore.updateTransferTo(prepareTransferPlaces(FinishPlace));

      this.searchStore.setSearchId(SearchId);
      this.transferStore.setSources(rates, travelPolicy);
      this.createTags();
      this.transferStore.setLoading(false);
    }).catch(() => {
      this.failTransfer();
    });
  };

  searchByHistoryItem = (travelPolicy: TravelPolicyItem[], body: SearchObject) => {
    this.transferStore.setLoading(true);

    return this.api.search(body).then((response: SearchItem) => {
      const { SearchId, Rates, Places: { StartPlace, FinishPlace } } = response;
      const rates = prepareRates(Rates);

      this.searchStore.updateTransferFrom(prepareTransferPlaces(StartPlace));
      this.searchStore.updateTransferTo(prepareTransferPlaces(FinishPlace));

      this.searchStore.setSearchId(SearchId);
      this.transferStore.setSources(rates, travelPolicy);
      this.createTags();
      this.transferStore.setLoading(false);

      return SearchId;
    }).catch(() => {
      this.failTransfer();
    });
  };

  getSearchHistory = (): void => this.api.getSearchHistory().then(this.successHistoryLoad);

  getTextWarning = async () => {
    const data = await this.api.getTextWarning();

    this.searchStore.setTextTemplateWarning(data?.content?.text || '');
  };

  getSearchObjectByHistory = ({
    StartPlace: {
      Address: AddressFrom,
      Coordinates: CoordinatesFrom,
      Country: CountryFrom,
    },
    EndPlace: {
      Address: AddressTo,
      Coordinates: CoordinatesTo,
      Country: CountryTo,
    },
  }: SearchObject) => ({
    StartPlace: {
      Address: AddressFrom,
      Coordinates: {
        Latitude: CoordinatesFrom.Latitude,
        Longitude: CoordinatesFrom.Longitude,
      },
      Country: CountryFrom,
    },
    EndPlace: {
      Address: AddressTo,
      Coordinates: {
        Latitude: CoordinatesTo.Latitude,
        Longitude: CoordinatesTo.Longitude,
      },
      Country: CountryTo,
    },
  });

  getSearchObjectByParams = ({
    StartPlaceAddress,
    StartPlaceLatitude,
    StartPlaceLongitude,
    EndPlaceAddress,
    EndPlaceLatitude,
    EndPlaceLongitude,
  }: ParsedQuery) => ({
    StartPlace: {
      Address: StartPlaceAddress,
      Coordinates: {
        Latitude: StartPlaceLatitude,
        Longitude: StartPlaceLongitude,
      },
    },
    EndPlace: {
      Address: EndPlaceAddress,
      Coordinates: {
        Latitude: EndPlaceLatitude,
        Longitude: EndPlaceLongitude,
      },
    },
  });

  sortSearchResult = (sort: string): void => {
    this.transferStore.setSortBy(sort);

    MainAnalytic.send(MainAnalytic.CATEGORY.TRANSFER, MainAnalytic.ACTIONS.TRANSFER.SORTING, {
      label: sort,
    });
  };

  updateFilter = (filter: IFilter): void => {
    switch (filter.name) {
      case FILTER_TYPE.POLICY: {
        this.transferStore.updateTravelPolicyFilter(filter.value);

        break;
      }
      case FILTER_TYPE.PRICE: {
        this.transferStore.updatePriceFilter(filter.value[0], filter.value[1]);

        break;
      }
      case FILTER_TYPE.CAR_CLASS:
        this.transferStore.updateCarClassFilter(filter.field, filter.value);

        break;

      case FILTER_TYPE.TABLE: {
        this.transferStore.updateTableFilter(filter.value);

        break;
      }
    }

    this.createTags();
  };

  createTags = (): void => {
    const tags = [];

    const { filter, travelPolicyAllList } = this.transferStore;

    if (filter.price.min !== filter.price.from
      || filter.price.max !== filter.price.to) {
      tags.push({
        name: LABELS.PRICE_FROM_TO(MoneyFormat.money(filter.price.from), MoneyFormat.money(filter.price.to)),
        key: FILTER_TYPE.PRICE,
        filter: FILTER_TYPE.PRICE,
      });
    }

    if (!filter.carClass.All) {
      Object.keys(filter.carClass).forEach((car) => {
        if (car !== 'All' && filter.carClass[car]) {
          const carName = MAPCARCLASS_RU[car];
          const name = `${carName.charAt(0).toUpperCase()}${carName.substring(1)}`;
          tags.push({
            name: LABELS.CLASS(name),
            key: car,
            filter: FILTER_TYPE.CAR_CLASS,
          });
        }
      });
    }

    if (filter.selectedTravelPolicy !== TRAVELPOLICYFILTER.NOTAPPLIED) {
      const travelPolicy = travelPolicyAllList.find(item => item.Id === filter.selectedTravelPolicy);

      if (!!travelPolicy && !!travelPolicy.Name) {
        tags.push({
          name: LABELS.TRAVEL_POLICY(travelPolicy.Name),
          key: FILTER_TYPE.POLICY,
          filter: FILTER_TYPE.POLICY,
        });
      }
    }

    if (filter.table) {
      tags.push({
        name: filter.table === VALUES_TABLETS.WITH_TABLET ? LABELS.WITH_TABLET : LABELS.WITHOUT_TABLET,
        key: FILTER_TYPE.TABLE,
        filter: FILTER_TYPE.TABLE,
        readOnly: filter.table === VALUES_TABLETS.WITH_TABLET,
      });
    }

    this.transferStore.setTags(tags);
  };

  deleteTransfers = (): void => this.transferStore.deleteTransfers();

  deleteTag = (tag: TagType): void => {
    switch (tag.filter) {
      case FILTER_TYPE.POLICY: {
        this.transferStore.updateTravelPolicyFilter(TRAVELPOLICYFILTER.NOTAPPLIED);

        break;
      }
      case FILTER_TYPE.PRICE: {
        this.transferStore.updatePriceFilter(this.transferStore.filter.price.min, this.transferStore.filter.price.max);

        break;
      }
      case FILTER_TYPE.CAR_CLASS: {
        this.transferStore.updateCarClassFilter(tag.key, false);

        break;
      }
      case FILTER_TYPE.TABLE: {
        this.transferStore.updateTableFilter(VALUES_TABLETS.WITH_TABLET);

        break;
      }
    }

    this.createTags();
  };

  resetFilters = (): void => {
    this.transferStore.resetFilters();
    this.createTags();
  };

  moveToCart = (item: PrepareRateType, RequestItemId: number) =>
    this.api.addToCart(this.searchStore.searchId, item.RateId, { TripId: item.TripId, RequestItemId }).then(() => {
      MainAnalytic.send(MainAnalytic.CATEGORY.TRANSFER, MainAnalytic.ACTIONS.TRANSFER.ADDTOCART, {
        label: MAPCARCLASS_RU[item.Class],
        value: item.Price,
      });
    });

  moveToFavorite = (item: PrepareRateType) => this.api.addToFavorite(item);

  moveToNote = (searchId: string, item: PrepareRateType, TripId?: string) =>
    this.api.addToNote(searchId, item.RateId, TripId).then(() => {
      const {
        StartPlace: { Address: AddressFrom },
        EndPlace: { Address: AddressTo },
      } = this.getSearchObject();

      MainAnalytic.send(MainAnalytic.CATEGORY.TRANSFER, MainAnalytic.ACTIONS.TRANSFER.ADDTONOTE, {
        label: `${AddressFrom} - ${AddressTo}`,
        value: item.Price,
      });

      return item;
    });

  setAlreadyInCart = (id: string) => {
    this.transferStore.setAlreadyInCart(id);
  };

  resetStore = () => this.searchStore.resetStore();
}

export default Transfer;
