const EXPENSE_FIELDS = {
  MAIN: '/expense-reports',
  EXPENSE_REPORT: '/expense-reports/report/',
  EXPENSE_REPORT_ID: (id: number) => `/expense-reports/report/${id}`,
};

const REPORTS_FIELDS = {
  MAIN: '/reports/main',
  TRANSAKTION: '/reports/transaktions',
};

const ROUTES = {
  SEARCH: {
    AIR: '/search/airline',
    HOTEL: '/search/hotels',
    HOTEL_PAGE: '/search/hotel',
    HOTELS_REGION: '/search/hotels/region',
    HOTEL_FAVORITE: (id: number | string) => `/search/hotel/${id}`,
    TRAIN: '/search/railway',
    TRAIN_RESULTS: '/search/railways',
    SELECTED_TRAIN: (
      searchId: string,
      trainId: number,
    ) => `/search/train/${searchId}/${trainId}`,
    TRANSFER: '/search/transfer',
    TRANSFER_RESULTS: (id: string) => `/search/transfers/${id}`,
    AEROEXPRESS: '/search/aeroexpress',
    TAXI: '/search/taxi',
    VIP: '/search/vip',
    CONFERENCE: '/search/conference',
    GROUP: '/search/group',
    EVENT: '/search/event',
  },
  APPROVE: {
    TRIPS: '/approve',
    REQUESTS: '/approve/travel',
    EXPENSE_REPORTS: '/approve/expense',
  },
  CART: {
    MAIN: '/cart',
    SPECIFIC: '/cart/',
    APPROVE: '/approve',
    CHECKOUT: '/cart/checkout',
    CHECKOUTSPECIFIC: '/cart/checkout/',
    BOOKING: '/cart/booking',
  },
  NOTE: {
    MAIN: '/note',
    BOOKING: '/note/booking',
  },
  REPORTS: REPORTS_FIELDS,
  TRIPS: {
    MAIN: '/trips',
  },
  TRIP: {
    MAIN: '/trip',
    DOCUMENTS: '/documents',
  },
  CHARTS_ANALYTICS: {
    MAIN: '/chartsAnalytics/',
    GENERAL: '/chartsAnalytics/general',
    SMART: {
      COMMON: {
        FIRST: '/chartsAnalytics/smart-common',
        SECOND: {
          AIRLINE: '/chartsAnalytics/smart-common-airline-second',
          TRAIN: '/chartsAnalytics/smart-common-train-second',
          HOTEL: '/chartsAnalytics/smart-common-hotel-second',
          TAXI: '/chartsAnalytics/smart-common-taxi-second',
          AEROEXPRESS: '/chartsAnalytics/smart-common-aeroexpress-second',
          TRANSFER: '/chartsAnalytics/smart-common-transfer-second',
          MICE: '/chartsAnalytics/smart-common-mice-second',
        },
        THIRD: {
          AIRLINE: '/chartsAnalytics/smart-common-airline-third',
          TRAIN: '/chartsAnalytics/smart-common-train-third',
          HOTEL: '/chartsAnalytics/smart-common-hotel-third',
          TAXI: '/chartsAnalytics/smart-common-taxi-third',
          AEROEXPRESS: '/chartsAnalytics/smart-common-aeroexpress-third',
          TRANSFER: '/chartsAnalytics/smart-common-transfer-third',
          MICE: '/chartsAnalytics/smart-common-mice-third',
        },
        FOURTH: {
          AIRLINE: '/chartsAnalytics/smart-common-airline-fourth',
          TRAIN: '/chartsAnalytics/smart-common-train-fourth',
          HOTEL: '/chartsAnalytics/smart-common-hotel-fourth',
          TAXI: '/chartsAnalytics/smart-common-taxi-fourth',
          AEROEXPRESS: '/chartsAnalytics/smart-common-aeroexpress-fourth',
          TRANSFER: '/chartsAnalytics/smart-common-transfer-fourth',
          MICE: '/chartsAnalytics/smart-common-mice-fourth',
        },
      },
      AIRLINE: {
        FIRST: '/chartsAnalytics/smart-airline',
        SECOND: '/chartsAnalytics/smart-airline-second',
        THIRD: '/chartsAnalytics/smart-airline-third',
      },
      TRAIN: {
        FIRST: '/chartsAnalytics/smart-train',
        SECOND: '/chartsAnalytics/smart-train-second',
        THIRD: '/chartsAnalytics/smart-train-third',
      },
      HOTEL: {
        FIRST: '/chartsAnalytics/smart-hotel',
        SECOND: '/chartsAnalytics/smart-hotel-second',
        THIRD: '/chartsAnalytics/smart-hotel-third',
      },
      TAXI: {
        FIRST: '/chartsAnalytics/smart-taxi',
        SECOND: '/chartsAnalytics/smart-taxi-second',
        THIRD: '/chartsAnalytics/smart-taxi-third',
      },
      AEROEXPRESS: {
        FIRST: '/chartsAnalytics/smart-aeroexpress',
        SECOND: '/chartsAnalytics/smart-aeroexpress-second',
        THIRD: '/chartsAnalytics/smart-aeroexpress-third',
      },
      TRANSFER: {
        FIRST: '/chartsAnalytics/smart-transfer',
        SECOND: '/chartsAnalytics/smart-transfer-second',
        THIRD: '/chartsAnalytics/smart-transfer-third',
      },
      MICE: {
        FIRST: '/chartsAnalytics/smart-mice',
        SECOND: '/chartsAnalytics/smart-mice-second',
        THIRD: '/chartsAnalytics/smart-mice-third',
      },
    },
    AIRLINE: {
      SUMMARY: '/chartsAnalytics/airline-summary',
      SUMMARY_NESTED: '/chartsAnalytics/airline-summary-nested',
      DIRECTIONS: '/chartsAnalytics/airline-directions',
      DIRECTIONS_NESTED: '/chartsAnalytics/airline-directions-nested',
      COMPANY: '/chartsAnalytics/airline-company',
      COMPANY_NESTED: '/chartsAnalytics/airline-company-nested',
      EARLINESS: '/chartsAnalytics/airline-earliness',
      EARLINESS_NESTED: '/chartsAnalytics/airline-earliness-nested',
    },
    STRUCTURE: {
      SERVICE_TYPE: '/chartsAnalytics/structure-serviceType',
      SERVICE_TYPE_NESTED: '/chartsAnalytics/structure-serviceType-nested',
      DEPARTMENTS: '/chartsAnalytics/structure-departments',
      DEPARTMENTS_NESTED: '/chartsAnalytics/structure-departments-nested',
      DEPARTMENTS_NESTED_SUB: '/chartsAnalytics/structure-departments-nested-sub',
      PROJECTS: '/chartsAnalytics/structure-projects',
      PROJECTS_NESTED: '/chartsAnalytics/structure-projects-nested',
      PROJECTS_NESTED_SUB: '/chartsAnalytics/structure-projects-nested-sub',
      CUSTOM_ANALYTICS: '/chartsAnalytics/structure-customAnalytics',
      CUSTOM_ANALYTICS_NESTED: '/chartsAnalytics/structure-customAnalytics-nested',
      CUSTOM_ANALYTICS_NESTED_SUB: '/chartsAnalytics/structure-customAnalytics-nested-sub',
    },
    TRAIN: {
      DIRECTIONS: '/chartsAnalytics/train-directions',
      DIRECTIONS_NESTED: '/chartsAnalytics/train-directions-nested',
      SUMMARY: '/chartsAnalytics/train-summary',
      SUMMARY_NESTED: '/chartsAnalytics/train-summary-nested',
    },
    HOTEL: {
      SUMMARY: '/chartsAnalytics/hotel-summary',
      SUMMARY_NESTED: '/chartsAnalytics/hotel-summary-nested',
      DIRECTIONS: '/chartsAnalytics/hotel-directions',
      DIRECTIONS_NESTED: '/chartsAnalytics/hotel-directions-nested',
      POPULAR: '/chartsAnalytics/hotel-popular',
      POPULAR_NESTED: '/chartsAnalytics/hotel-popular-nested',
    },
    TAXI: {
      SUMMARY: '/chartsAnalytics/taxi-summary',
      SUMMARY_NESTED: '/chartsAnalytics/taxi-summary-nested',
      SUMMARY_NESTED_DOUBLE: '/chartsAnalytics/taxi-summary-nested-double',
      DIRECTIONS: '/chartsAnalytics/taxi-directions',
      DIRECTIONS_NESTED: '/chartsAnalytics/taxi-directions-nested',
      DIRECTIONS_NESTED_DOUBLE: '/chartsAnalytics/taxi-directions-nested-double',
      SMART_VOUCHER: '/chartsAnalytics/smart-taxi-voucher',
      SMART_VOUCHER_COMMON: '/chartsAnalytics/smart-common-taxi-voucher',
    },
    AEROEXPRESS: {
      DIRECTIONS: '/chartsAnalytics/aeroexpress-directions',
      DIRECTIONS_NESTED: '/chartsAnalytics/aeroexpress-directions-nested',
      SUMMARY: '/chartsAnalytics/aeroexpress-summary',
      SUMMARY_NESTED: '/chartsAnalytics/aeroexpress-summary-nested',
    },
    TRANSFER: {
      DIRECTIONS: '/chartsAnalytics/transfer-directions',
      DIRECTIONS_NESTED: '/chartsAnalytics/transfer-directions-nested',
      SUMMARY: '/chartsAnalytics/transfer-summary',
      SUMMARY_NESTED: '/chartsAnalytics/transfer-summary-nested',
    },
    MICE: {
      SUMMARY: '/chartsAnalytics/mice-summary',
      SUMMARY_NESTED: '/chartsAnalytics/mice-summary-nested',
    },
  },
  SETTINGS: {
    MAIN: '/settings',
    EMPLOYEES: '/settings/employees',
    ANALYTICS: '/settings/analytics',
    TRAVEL_POLICIES: '/settings/travel-policies',
    COMPANIES: '/settings/companies',
    INTEGRATION: '/settings/integration',
    PROFILE: '/settings/profile',
    DEPARTMENTS: '/settings/departments',
    APPROVAL_SCHEMES: '/settings/approval-schemes',
    APPLICATION_SCHEME: '/settings/application-scheme',
    APPROVAL_SCHEMES_EXPENSE_REPORTS: '/settings/approval-schemes-expense-reports',
    S7: '/settings/s7',
    EXPENSE_REPORT: '/settings/expense-reports',
    DOCUMENTS_FOR_SIGNATURE: '/settings/documents-for-signature',
    DOCUMENTS_GOS_SIGN: '/settings/documents-gos-sign',
    VOUCHERS: '/settings/vouchers',
    BALANCE: '/settings/replishment-balance',
  },
  QUALITY: {
    GENERAL: '/quality',
    SUCCESS: '/quality/success',
  },
  EMPLOYEE: '/employee/',
  FAVORITES: '/favorites',
  SMARTWAY_WIKI: 'https://wiki.smartway.today',
  SMARTAGENT_AUTOREG: 'https://smartagent.online/auto-instruction',
  SMARTWAY_TELEGRAM: 'https://t.me/smartway_today',
  CUSTOM_BUSSINES_TRIPS_VMZ_OMK: 'https://portal.omk.ru/page/navigator-po-oformleniu-komandirovok',
  INTRODUCTORY_BRIEFING_BURSERVICE: ' https://life.burservis.ru/instruktazh-na-lokacii/',
  SMARTDESK: '/smartdesk',
  SEARCH_SA: {
    AIR: '/smartdesk/searchAir',
    TRAIN: '/smartdesk/searchTrain',
    TRANSFER: '/smartdesk/searchTransfer',
    VIP_HALL: '/smartdesk/searchVip',
    HOTEL: '/smartdesk/searchHotel',
    AEROEXPRESS: '/smartdesk/searchAeroexpress',
  },
  DEPARTMENT: {
    MAIN: '/department',
    NEW: '/department/new',
    BY_ID: (id: number) => `/department/${id}`,
  },
  TRAVEL_POLICY: '/travel-policy',
  TRAVEL_POLICY_UNIQUE: '/travel-policy-unique',
  APPROVAL_SCHEME: '/approval-scheme',
  APPROVAL_EXPENSE_REPORT: '/approval-expense-report-scheme',
  APPLICATION_SCHEMES: '/application-schemes',
  APPLICATION_SCHEME_EDIT: '/application-scheme/:schemeId/:isApplied',
  APPLICATION_SCHEME_CREATE: '/application-scheme/create',
  APPLICATION_SCHEME_BY_ID: (
    schemeId: string,
    isApplied: boolean,
  ) => `/application-scheme/${schemeId}/${isApplied}`,
  APPLICATION_SCHEME_REQUEST: '/application-scheme-request',
  REQUESTS: '/requests',
  EXPENSE: EXPENSE_FIELDS,
  EVENT: '/event',
  EVENT_CONTEXT: '/event:context',
  ACCOUNTING: '/accounting',
  APPLICATION: '/application',
  ANALYTICS: '/analytics',
  COSTCENTERS: '/cost-centers',
  NEW_REQUEST: '/new-request',
  TRAVEL_APPROVAL: '/travel-approval',
  TAXI: '/taxi',
  NO_MATCH: '*',
  QUICK_APPROVES: {
    MAIN: '/quick-approve/',
    TRAVEL_APPROVAL: '/travel-approval/quick-approve/',
    EXPENSE_REPORT_APPROVAL: '/expense-report-approval/quick-approve/',
  },
  OFFER: '/offer',
  OFFER_SMARTAGENT: '/offerSmartAgent',
  CHANGE_AIR_TRIP: {
    TRIP: (tripId: string | number | string[]) => `/trip/${tripId}`,
    SELECT_CHANGE: (
      tripId: string | number,
      ticketId: string | number,
    ) => `/trip/${tripId}/changeAirTrip/${ticketId}`,
    DATE_EDIT: (
      tripId: string,
      ticketId: string,
    ) => `/trip/${tripId}/changeAirTrip/${ticketId}/date`,
    DATE_RESULT: (
      tripId: string,
      ticketId: string,
      searchId: string,
    ) => `/trip/${tripId}/changeAirTripResult/${ticketId}/date/${searchId}`,
    DIRECTION_EDIT: (
      tripId: string,
      ticketId: string,
    ) => `/trip/${tripId}/changeAirTrip/${ticketId}/direction`,
    DIRECTION_RESULT: (
      tripId: string,
      ticketId: string,
      searchId: string,
    ) => `/trip/${tripId}/changeAirTripResult/${ticketId}/direction/${searchId}`,
  },
  SMART_AGENT: {
    TRVELERS: '/travelers',
  },
  ADDITIONALS: {
    MAIN: '/trip/:tripId/additionalServices/:ticketId',
    VIP_HALL: '/trip/:tripId/additionalServices/:ticketId/vipHall',
    ESCORT: '/trip/:tripId/additionalServices/:ticketId/escort',
    VIP_HALL_RESULT: '/trip/:tripId/additionalServices/:ticketId/vipHall/:guid',
    ESCORT_RESULT: '/trip/:tripId/additionalServices/:ticketId/escorts/:guid',
    VIP_HALL_ORDER: '/trip/:tripId/additionalServices/:ticketId/vip/:guid',
    ESCORT_ORDER: '/trip/:tripId/additionalServices/:ticketId/escort/:guid',
    PAYMENT_VIP: '/trip/:tripId/additionalServices/:ticketId/payment/vip/:guid',
    PAYMENT_ESCORT: '/trip/:tripId/additionalServices/:ticketId/payment/escort/:guid',
    TERMINAL: '/trip/:tripId/additionalServices/:ticketId/terminal',
    DIRECTION: '/trip/:tripId/additionalServices/:ticketId/direction',
    FLIGHT_CERTIFICATE: '/trip/:tripId/additionalServices/:ticketId/orderFlightCertificate',
    OTHER: '/trip/:tripId/additionalServices/:ticketId/otherChanges/:additionalType?',
    ACCOUNTING: '/accounting',
  },
  ADDITIONAL_SERVICES: {
    TRIP: (tripId: string | number) => `/trip/${tripId}`,
    MAIN: (
      tripId: string | number,
      ticketId: string | number,
    ) => `/trip/${tripId}/additionalServices/${ticketId}`,
    ROUTE: (
      tripId: string,
      ticketId: string,
      type: string | string[] | null,
    ) => `/trip/${tripId}/additionalServices/${ticketId}/${type}`,
    DIRECTION: (
      tripId: string,
      ticketId: string,
      type: string,
      selectedRoute: number | null,
    ) => `/trip/${tripId}/additionalServices/${ticketId}/direction?type=${type}&route=${selectedRoute}`,
    TERMINAL: (
      tripId: string,
      ticketId: string,
      type: string,
      selectedRoute: string | string[] | null,
      segment: string | string[] | null,
    ) => `/trip/${tripId}/additionalServices/${ticketId}/terminal?type=${type}&route=${selectedRoute}&segment=${segment}`,
    RESULT_VIP: (
      id: string,
      tripId: string,
      ticketId: string,
      selectedRoute: string | string[] | null,
      segment: string | string[] | null | number,
      terminal: string | null,
    ) => `/trip/${tripId}/additionalServices/${ticketId}/vipHall/${id}?route=${selectedRoute}&segment=${segment}&terminal=${terminal}`,
    RESULT_ESCORT: (
      id: string,
      tripId: string,
      ticketId: string,
      selectedRoute: string | string[] | null,
      segment: string | string[] | null | number,
      terminal: string | null,
    ) => `/trip/${tripId}/additionalServices/${ticketId}/escorts/${id}?route=${selectedRoute}&segment=${segment}&terminal=${terminal}`,
    INFO_VIP: (
      id: string,
      tripId: string,
      ticketId: string,
      guid: string,
      selectedRoute: string | string[] | null,
      segment: string | string[] | null,
      terminal: string | string[] | null,
    ) => `/trip/${tripId}/additionalServices/${ticketId}/vip/${id}?guid=${guid}&route=${selectedRoute}&segment=${segment}&terminal=${terminal}`,
    INFO_ESCORT: (
      id: string,
      tripId: string,
      ticketId: string,
      guid: string,
      selectedRoute: string | string[] | null,
      segment: string | string[] | null,
      terminal: string | string[] | null,
    ) => `/trip/${tripId}/additionalServices/${ticketId}/escort/${id}?guid=${guid}&route=${selectedRoute}&segment=${segment}&terminal=${terminal}`,
    PAYMENT_VIP: (
      id: string,
      tripId: string,
      ticketId: string,
      guid: string,
      selectedRoute: string | string[] | null,
      segment: string | string[] | null,
      terminal: string | string[] | null,
    ) => `/trip/${tripId}/additionalServices/${ticketId}/payment/vip/${id}?guid=${guid}&route=${selectedRoute}&segment=${segment}&terminal=${terminal}`,
    PAYMENT_ESCORT: (
      id: string,
      tripId: string,
      ticketId: string,
      guid: string,
      selectedRoute: string | string[] | null,
      segment: string | string[] | null,
      terminal: string | string[] | null,
    ) => `/trip/${tripId}/additionalServices/${ticketId}/payment/escort/${id}?guid=${guid}&route=${selectedRoute}&segment=${segment}&terminal=${terminal}`,
    OTHER_CHANGES: (
      tripId: string,
      ticketId: string,
      additionalType: string = '',
    ) => `/trip/${tripId}/additionalServices/${ticketId}/otherChanges${additionalType && `/${additionalType}`}`,
  },
};

export default ROUTES;
