const numeric = /^[+-]?([0-9]*[.])?[0-9]+$/;
const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const s7Code = /^(QY\d{5}\b)/;
const departmentId = /^\d*--\d*/;

const isNumeric = (value: string) => typeof value === 'string' && numeric.test(value);

const isEmail = (value: string) => typeof value === 'string' && emailRegExp.test(value);

const isS7BonusCode = (value: string) => typeof value === 'string' && s7Code.test(value);

const isDepartmentId = (value: string) => typeof value === 'string' && departmentId.test(value);

const checkMaxDecimals = (value: number | undefined, maxDecimal = 2) => {
  if (!value) return true;

  const decimalPart = value.toString().split('.')[1];

  return !decimalPart || decimalPart.length <= maxDecimal;
};

export {
  isNumeric,
  isEmail,
  isS7BonusCode,
  isDepartmentId,
  checkMaxDecimals,
};
