import { getText } from '../../../../i18n';

import { ServiceReturnType } from '../../context/services';

import { isValueComputeEnum } from '../../utils/common';

import { CustomerReceipt } from '../../types/paymentMethod';
import { IPrepareSourcesItem } from '../../services/checkout/types';
import { AcquiringLevel } from '../../services/employee/types';

const LABEL = {
  COMMISSION: getText('payment:commission'),
};

class PersonalPaymentProtocol {
  services: ServiceReturnType;

  constructor(services: ServiceReturnType) {
    this.services = services;
  }

  get canPayWithPersonalFunds() {
    const { rights: { Acquiring } } = this.services.Workspace;
    const acquiringIs = isValueComputeEnum(Acquiring, AcquiringLevel);

    return !!this.services.AccountSettings.getAccountSettings().acquiringAccess &&
      (!acquiringIs.Undefined && !acquiringIs.Unavailable);
  }

  get canViewPETransactionsPage() {
    return this.canPayWithPersonalFunds || this.services.EmployeePaymentService.store.transactionsExists;
  }

  customerReceipt = (sources: IPrepareSourcesItem[], amountToPay: number): CustomerReceipt => {
    const { getServiceName } = this.services.Booking;
    const { store: { commission } } = this.services.EmployeePaymentService;

    const receipt = sources.map(({ ServiceType, Data, Price: cost }: {
      ServiceType: string,
      Data: string,
      Price: number
    }) => ({
      label: getServiceName(ServiceType, JSON.parse(Data)) || ServiceType,
      price: cost,
      quantity: 1,
      vat: '',
      amount: cost,
    }));
    receipt.push({
      label: LABEL.COMMISSION,
      price: commission,
      quantity: 1,
      vat: '',
      amount: commission,
    });

    return ({
      Items: receipt,
      Amounts: {
        Electronic: amountToPay,
      },
    });
  };

  prepareWidgetInfo = (
    OrderId: string,
    amountToPay: number,
    description: string,
  ) => ({
    invoiceId: OrderId,
    data: {
      type: 'cartPurchase',
    },
    amount: amountToPay,
    description,
  });
}

export { PersonalPaymentProtocol };
