import React, { Component } from 'react';
import { Button, PageLoader, ItemPanel } from 'new-ui';
import { Link, RouteComponentProps } from 'react-router-dom';
import * as queryString from 'query-string';

import { getText } from '../../../i18n';

import FormatService from '../../bi/services/format';
import RequestService from '../../bi/services/requests';
import HotelsService from '../../bi/services/hotels';
import TrainService from '../../bi/services/trains';
import CartService from '../../bi/services/cart';

import ItemLayout from '../../components/ItemLayout';
import RequestAirItem from '../../components/RequestAirItem';
import RequestTrainItem from '../../components/RequestTrainItem';
import RequestTransferItem from '../../components/RequestTransferItem';
import RequestHotelItem from '../../components/RequestHotelItem';
import RequestHeader from '../../components/RequestHeader';
import RequestItemFooter from '../../components/RequestItemFooter';

import { momentObject } from '../../bi/utils/formatDate';

import { statuses, STATUSES, TYPES } from '../../../constants/Request';
import ROUTES from '../../bi/constants/routes';

import {
  IAirItemsRequest,
  IHotelItemsRequest,
  IRequest,
  ITrainItemsRequest, ITransferItemsRequest,
  TRequestItem,
  TTypeItem,
} from '../../bi/types/requests';

import styles from './styles/request.module.css';

interface IRequestProps {
  match: RouteComponentProps['match'];
  history: RouteComponentProps['history'];
  formatService: FormatService;
  requestsService: RequestService;
  hotelsService: HotelsService;
  trainService: TrainService;
  cartService: CartService,
}

interface IRequestState {
  loading: boolean;
  request: IRequest | object | null,
}

const Services = {
  [TYPES.AIR]: RequestAirItem,
  [TYPES.HOTEL]: RequestHotelItem,
  [TYPES.TRANSFER]: RequestTransferItem,
  [TYPES.TRAIN]: RequestTrainItem,
};

const DATE = {
  CHECKOUT: 'checkout.date',
  CHECKIN: 'checkin.date',
};

const HOTEL_REGION_SELECTED = 'region.selected';

const LABELS = {
  ALL_REQUESTS: getText('requests:allRequests'),
  TO_CART: getText('requests:toCart'),
  TO_TRIP: getText('requests:toTrip'),
  SEARCH: getText('common:search'),
  ADDED_TO_REUQEST: (IniciatorName: any) => getText('requests:addedToRequest', { IniciatorName }),
};

class Request extends Component<IRequestProps, IRequestState> {
  unsubscribeFn: () => void;

  constructor(props: IRequestProps) {
    super(props);

    this.state = {
      loading: true,
      request: null,
    };
  }

  componentDidMount() {
    // @ts-ignore
    const { requestsService, match: { params: { requestId } } } = this.props;

    this.unsubscribeFn = requestsService.subscribe(this.update);

    requestsService.loadRequest(requestId);
  }

  componentWillUnmount() {
    this.unsubscribeFn();
  }

  update = ({ loading, request }: IRequestState) => this.setState({ loading, request });

  goToHotel = ({ SearchOptions, EmployeesNames }: IHotelItemsRequest) => {
    const query = {
      CheckinDate: SearchOptions.CheckinDate,
      CheckoutDate: SearchOptions.CheckoutDate,
      GuestsCount: EmployeesNames.length,
      Label: SearchOptions.Name,
      TravellersCount: EmployeesNames.length,
    };

    this.props.history.push({
      pathname: `/search/hotel/${SearchOptions.Code}`,
      search: queryString.stringify(query),
    });
  };

  checkRequestsList = () => {
    const checkRequestsList = this.props.requestsService.get().requestsList.length === 0;

    if (checkRequestsList) {
      this.props.requestsService.updateLoadingRequestsFlag();
    }
  };

  findTrip = (type: TTypeItem, item: TRequestItem) => {
    const { requestsService, hotelsService, trainService, history, cartService } = this.props;
    // @ts-ignore
    const { SearchOptions: { IsRegion, Name, CheckinDate, CheckoutDate, Code, Address } } = item;
    // @ts-ignore
    const uri = ROUTES.SEARCH[type.toUpperCase()];

    const preparedParams = {
      [DATE.CHECKOUT]: momentObject(CheckoutDate),
      [DATE.CHECKIN]: momentObject(CheckinDate),
    };

    cartService.setPreselectRequest1c({ type, id: item?.RequestId });

    const hotelSetSearch = () => {
      hotelsService.setNewSearch();
      Object.keys(preparedParams).forEach(key => hotelsService.setSearch(key, preparedParams[key]));
    };

    if (uri && type === TYPES.AIR) {
      // @ts-ignore
      const { SearchOptions: { Routes } } = item;

      // @ts-ignore
      const arrivalEmpty = Routes.some(({ ArrivalAirport: { Code: ArrivalCode } }) => ArrivalCode === '');
      // @ts-ignore
      const departureEmpty = Routes.some(({ DepartureAirport: { Code: DepartureCode } }) => DepartureCode === '');

      if (arrivalEmpty || departureEmpty) {
        return history.push({ pathname: ROUTES.SEARCH.AIR });
      }
    }

    if (uri) {
      requestsService.setRequestItem(item);
    }

    if (uri && type === TYPES.HOTEL && (!IsRegion || !Name) && Name) {
      return this.goToHotel(item as IHotelItemsRequest);
    }

    if (uri && type === TYPES.HOTEL && (!IsRegion || !Name) && !Name) {
      hotelSetSearch();

      return history.push({ pathname: ROUTES.SEARCH.HOTEL, search: 'is_request=true' });
    }

    if (uri && type === TYPES.HOTEL && IsRegion && Name) {
      hotelSetSearch();
      hotelsService.setSearch(HOTEL_REGION_SELECTED, { Name, Id: Code, IsRegion, FullName: Address });
    }

    if (uri && type === TYPES.TRAIN) {
      const {
        SearchOptions: {
          // @ts-ignore
          ArrivalStation: {
            Code: ArrivalCode,
            City: ArrivalCity,
            Name: ArrivalName,
          },
          // @ts-ignore
          DepartureStation: {
            Code: DepartureCode,
            City: DepartureCity,
            Name: DepartureName,
          },
          // @ts-ignore
          DepartureDate,
        },
        EmployeesNames,
      } = item;

      const from = {
        label: DepartureName,
        selected: {
          BaseCode: '',
          Code: DepartureCode,
          Hint: '',
          Name: DepartureCity,
        },
        suggests: [],
      };

      const to = {
        label: ArrivalName,
        selected: {
          BaseCode: '',
          Code: ArrivalCode,
          Hint: '',
          Name: ArrivalCity,
        },
        suggests: [],
      };

      const travellers = EmployeesNames.length || 1;

      trainService.setNewSearch();
      trainService.setFromAndTo(from, to);
      trainService.setDate(momentObject(DepartureDate));
      trainService.setTravellers(travellers);

      return history.push({ pathname: uri, search: 'is_request=true' });
    }

    if (uri) {
      return history.push({ pathname: uri, search: 'is_request=true' });
    }

    return null;
  };

  getEmployeesNames = () => {
    // @ts-ignore
    const { request: { AirItems = [], TrainItems = [], HotelItems = [], TransferItems = [] } } = this.state;

    let employees: any = new Set();

    const extractEmployees = (item: TRequestItem) => item.EmployeesNames.forEach(employee => employees.add(employee));

    AirItems.forEach(extractEmployees);
    TrainItems.forEach(extractEmployees);
    HotelItems.forEach(extractEmployees);
    TransferItems.forEach(extractEmployees);

    employees = [...employees]; // Set -> Array

    return employees.join(', ');
  };

  renderLoading = () => <PageLoader />;

  renderHeader = () => {
    // @ts-ignore
    const { request: { Id, Status, CreatedDate, Comment } } = this.state;
    const createdDate = this.props.formatService.date(CreatedDate, 'DD.MM.YYYY HH:MM');

    return (
      <RequestHeader
        id={ Id }
        status={ Status }
        createdDate={ createdDate }
        comment={ Comment }
        employees={ this.getEmployeesNames() }
      />
    );
  };

  renderItemsList = () => {
    // @ts-ignore
    const { request: { AirItems = [], TrainItems = [], HotelItems = [], TransferItems = [] } } = this.state;

    const items: JSX.Element[] = [];

    AirItems.forEach((item: IAirItemsRequest) => items.push(this.renderItem('Air', item)));
    TrainItems.forEach((item: ITrainItemsRequest) => items.push(this.renderItem('Train', item)));
    HotelItems.forEach((item: IHotelItemsRequest) => items.push(this.renderItem('Hotel', item)));
    TransferItems.forEach((item: ITransferItemsRequest) => items.push(this.renderItem('Transfer', item)));

    return <div>{ items }</div>;
  };

  renderItemPanelButton = (type: TTypeItem, item: TRequestItem) => {
    const { formatService: { money }, history } = this.props;

    switch (item.Status.Name) {
      case STATUSES.NOTPROCESSING: {
        return (
          <div className={ styles.find }>
            <Button
              type='secondary'
              onClick={ () => this.findTrip(type, item) }
            >
              {LABELS.SEARCH}
            </Button>
          </div>
        );
      }

      case STATUSES.PROCESSING: {
        return (
          <div className={ styles.find }>
            <div className={ styles.price }>{ money(item.Status.Price) } р.</div>
            <Button
              onClick={ () => {
                history.push({ pathname: '/cart' });
              } }
            >
              {LABELS.TO_CART}
            </Button>
          </div>
        );
      }

      case STATUSES.PROCESSED: {
        return (
          <div className={ styles.find }>
            <div className={ styles.price }>{ money(item.Status.Price) } р.</div>
            <Button
              // @ts-ignore
              onClick={ () => { history.push({ pathname: `/trip/${item.Status.Id}` }); } }
            >
              {LABELS.TO_TRIP}
            </Button>
          </div>
        );
      }

      case STATUSES.CANCELED: {
        return null;
      }
    }

    return null;
  };

  renderItemPanelHeader = (item: TRequestItem) => {
    // @ts-ignore
    const { InitiatorName, Status: { Name } } = item;// TODO: InitiatorName doesn't exist

    const InitiatorNameHtml = (
      <span> {LABELS.ADDED_TO_REUQEST(InitiatorName)} </span>
    );

    const isAddingTrip = InitiatorName && InitiatorNameHtml;
    const status = statuses.get(Name);

    return (
      <div className={ styles['header-info'] }>
        <div>
          {isAddingTrip}
        </div>
        <div>
          <span className={ styles['status-name'] }>{ status }</span>
          <span className={ `${styles['status-label']} ${styles[Name.toLowerCase()]}` } />
        </div>
      </div>
    );
  };

  renderItem = (type: TTypeItem, item: TRequestItem) => {
    const html = this.renderItemPanelButton(type, item);

    return (
      <div key={ item.Id } className={ styles.wrap }>
        <ItemPanel renderHeader={ () => this.renderItemPanelHeader(item) }>
          <div className={ styles.item }>
            <ItemLayout
              serviceType={ type }
              html={ html }
            >
              { this.renderServiceItem(item, type) }
            </ItemLayout>
            <div>{ this.renderItemFooter(item.EmployeesNames) }</div>
          </div>
        </ItemPanel>
      </div>
    );
  };

  renderServiceItem = (item: TRequestItem, type: TTypeItem) => {
    if (type in Services) {
      const Service = Services[type];

      return (
        <Service
          formatService={ this.props.formatService }
          // @ts-ignore
          searchOptions={ item.SearchOptions }
          comment={ item.Comment }
        />
      );
    }

    return '';
  };

  renderItemFooter = (employees: string[]) => (<RequestItemFooter employees={ employees } />);

  render() {
    const { loading } = this.state;

    return loading ? this.renderLoading() : (
      <div>
        <div className={ styles.back }>
          <Link to='/requests' className={ styles['back-link'] } onClick={ this.checkRequestsList }>
            <i className='material-icons'>keyboard_backspace</i>
            {LABELS.ALL_REQUESTS}
          </Link>
        </div>
        { this.renderHeader() }
        { this.renderItemsList() }
      </div>
    );
  }
}

export default Request;
