import React, { useEffect, useState } from 'react';
import { CircleLoading, Icon, RadioButton, Text, Tooltip } from 'new-ui';
import { observer } from 'mobx-react';

import { getText } from '../../../../../i18n';

import { MOBX_STORES } from '../../../../bi/context/stores';
import { useStores } from '../../../../bi/context';

import Event from '../../../../bi/services/events';
import { MainAnalytic } from '../../../../bi/utils/analytics';

import { LargeCity } from './components/LargeCity';
import { ForeignCity } from './components/ForeignCity';
import { CompleteCalculator } from './components/CompetePage';

import { DIRECTION } from '../../const';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE: getText('event:calculator.title'),
  DATA: {
    TITLE: getText('event:calculator.data.title'),
    SUB_TITLE: getText('event:calculator.data.subtitle'),
    CHECKBOX: {
      LARGE: getText('event:calculator.data.type.large'),
      OTHER: getText('event:calculator.data.type.other'),
      FOREIGN: getText('event:calculator.data.type.foreign'),
    },
    TOOPLIP: getText('event:calculator.data.tooltip'),
  },
};

interface ICalculatorMice {
  isComplete: boolean,
  setIsComplete(value: boolean): void,
  eventService: Event,
  setShowCalculator(): void,
  onOpenAddEvent(value: boolean): void,
}

const CalculatorMice = observer(({
  isComplete,
  setIsComplete,
  eventService: {
    calculatorSend,
    setCalculatorResMini,
    setIsValueMini,
    resetCalculatorRes,
  },
  setShowCalculator,
  onOpenAddEvent,
}: ICalculatorMice) => {
  const {
    eventStore: {
      calculatorRes: {
        EventEstimate,
        Direction,
      },
      calculator: {
        value,
        isLoading,
        isError,
      },
    },
  } = useStores([MOBX_STORES.EVENT_STORE]);

  const [direction, setDirection] = useState<string>(DIRECTION.LARGE_CITY);

  useEffect(() => {
    if (EventEstimate) {
      setDirection(Direction);
    }

    return () => setIsComplete(false);
  }, []);

  useEffect(() => {
    if (direction === DIRECTION.LARGE_CITY || direction === DIRECTION.OTHER_CITY) {
      setIsValueMini(false);
    }
  }, [direction]);

  const onSetIsComplete = () => setIsComplete(!isComplete);

  const onSetIsCompleteWithAnalytics = () => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.CALCULATOR.BACK_PRESSED);
  
    setIsComplete(!isComplete);
  };

  const renderLargeCity = () => {
    if (direction === DIRECTION.FOREIGN_CITY) {
      return null;
    }

    return (
      <div className={ styles.wrapper_sub_large }>
        <LargeCity
          direction={ direction }
          calculatorSend={ calculatorSend }
          setShowCalculator={ setShowCalculator }
          setIsCompete={ onSetIsComplete }
          resetCalculatorRes={ resetCalculatorRes }
        />
      </div>
    );
  };

  const renderForeignCity = () => {
    if (direction !== DIRECTION.FOREIGN_CITY) {
      return null;
    }

    return (
      <div className={ styles.wrapper_sub_large }>
        <ForeignCity
          setCalculatorResMini={ setCalculatorResMini }
          setShowCalculator={ setShowCalculator }
          onOpenAddEvent={ onOpenAddEvent }
          setIsValue={ setIsValueMini }
        />
      </div>
    );
  };

  const renderTooltip = () => (
    <Text type='NORMAL_14' color='white' className={ styles.tooltip }>
      { LABELS.DATA.TOOPLIP }
    </Text>
  );

  const renderCity = () => (
    <div className={ styles.wrapper }>
      <Text className={ styles.title } type='bold_32'>
        { LABELS.TITLE }
      </Text>
      <div className={ styles.wrapper_data }>
        <Text className={ styles.title } type='bold_20'>
          { LABELS.DATA.TITLE }
        </Text>
        <Text type='NORMAL_16'>
          { LABELS.DATA.SUB_TITLE }
        </Text>
        <div className={ styles.wrapper_checkbox }>
          <RadioButton
            value={ DIRECTION.LARGE_CITY }
            checked={ direction === DIRECTION.LARGE_CITY }
            onChange={ () => setDirection(DIRECTION.LARGE_CITY) }
          >
            <div className={ styles.wrapper_large }>
              <Text type='NORMAL_16'>
                { LABELS.DATA.CHECKBOX.LARGE }
              </Text>
              <Tooltip renderContent={ () => renderTooltip() }>
                <Icon className={ styles.icon } type='question'/>
              </Tooltip>
            </div>
          </RadioButton>
          <RadioButton
            value={ DIRECTION.OTHER_CITY }
            checked={ direction === DIRECTION.OTHER_CITY }
            onChange={ () => setDirection(DIRECTION.OTHER_CITY) }
          >
            <Text type='NORMAL_16'>
              { LABELS.DATA.CHECKBOX.OTHER }
            </Text>
          </RadioButton>
          <RadioButton
            value={ DIRECTION.FOREIGN_CITY }
            checked={ direction === DIRECTION.FOREIGN_CITY }
            onChange={ () => setDirection(DIRECTION.FOREIGN_CITY) }
          >
            <Text type='NORMAL_16'>
              { LABELS.DATA.CHECKBOX.FOREIGN }
            </Text>
          </RadioButton>
        </div>
      </div>
      { renderLargeCity() }
      { renderForeignCity() }
    </div>
  );

  const renderComplete = () => (
    <CompleteCalculator
      value={ value }
      onOpenAddEvent={ onOpenAddEvent }
      onSetIsComplete={ onSetIsCompleteWithAnalytics }
    />
  );

  const renderLoading = () => (
    <div className={ styles.wrapper_loading }>
      <CircleLoading size={ 100 }/>
    </div>
  );

  if (isLoading) {
    return renderLoading();
  }

  return isComplete && !isError ? renderComplete() : renderCity();
});

export { CalculatorMice };
