import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Text } from 'new-ui';
import { getText } from '../../../i18n';

import QualityService from '../../bi/services/quality';
import NotificationService from '../../bi/services/notification';
import WorkspaceSettings from '../../bi/services/workspace';
import UiSettingsService from '../../bi/services/uiSettings';

import ROUTES from '../../bi/constants/routes';
import { QUALITY } from '../../bi/constants/quality';

import { MainAnalytic } from '../../bi/utils/analytics';

import MainPart from './components/mainPart';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE: getText('quality:title'),
};

interface QualityProps {
  qualityService: QualityService,
  notificationService: NotificationService,
  workspaceService: WorkspaceSettings,
  uiSettingsService: UiSettingsService,
  uiSettingsProtocol: any,
  history: any,
  location: { pathname: string },
  store: { // здесь должен быть store из quality, не из Main, добавить наблюдателя за стором
    message: string,
    result: boolean,
    successResponse: boolean,
    loadingButton: boolean,
  },
}
@observer
export default class Quality extends Component<QualityProps> {
  componentDidUpdate({ location: { pathname: prevPathname } }: { location: { pathname: string } }) {
    const { location: { pathname: nextPathname }, qualityService: { goBack } } = this.props;

    if (prevPathname === ROUTES.QUALITY.SUCCESS && nextPathname === ROUTES.QUALITY.GENERAL) {
      goBack();
    }
  }

  componentWillUnmount() {
    const { goBack, clear } = this.props.qualityService;

    goBack();
    clear();
  }

  setNotification = () => {
    const {
      store: {
        result,
      },
      qualityService: {
        clear,
      },
      notificationService: {
        send,
      },
      uiSettingsService: { getCompanyName },
    } = this.props;

    if (result) {
      clear();
      send({
        message: QUALITY.NOTIFICATION(getCompanyName()),
      });

      MainAnalytic.send(MainAnalytic.CATEGORY.QUALITYCONTROL, MainAnalytic.ACTIONS.QUALITYCONTROL.COMPLAINT);
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.QUALITYCONTROL.CLAIM);
    }
  };

  handleSend = () => {
    const {
      uiSettingsProtocol: { sendQuality },
      history: { push },
    } = this.props;

    sendQuality()
      .then(() => {
        this.setNotification();
        push(ROUTES.QUALITY.SUCCESS);
      });
  };

  renderSuccessPart = () => (
    <Text type='NORMAL_16_140'>
      { QUALITY.NOTIFICATION(this.props.uiSettingsService.getCompanyName()) }
    </Text>
  );

  render() {
    const {
      workspaceService: {
        isDemo,
      },
      store: {
        message,
        successResponse,
        loadingButton,
      },
      qualityService: {
        setMessage,
      },
      uiSettingsService: { getCompanyName },
    } = this.props;

    const html = successResponse ? this.renderSuccessPart() : (
      <MainPart
        value={ message }
        isDemo={ isDemo }
        loading={ loadingButton }
        companyName={ getCompanyName() }
        onClick={ this.handleSend }
        onChange={ setMessage }
      />
    );

    return (
      <div className={ styles.wrapper }>
        <Text type='bold_32' className={ styles.title }>
          { LABELS.TITLE }
        </Text>
        { html }
      </div>
    );
  }
}
