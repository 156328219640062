import { getText } from '../../../../i18n';

import debounce from '../../api/debounce';
import { api } from '../../apiV2';

import { accountStore } from '../workspace/stores/account';
import { EmployeePaymentStore } from '../employeePayment/store';
import { CloudPaymentStoreType, PaymentMethodsStore } from './store';

import NetworkStatesStore from '../utils/network/networkStatesStore';
import { bound, withIM } from '../utils/dectrators';
import { widget } from './widget';

import { CommissionType, PAYMENT_INFO, PAYMENT_METHODS, PaymentMethodType } from '../../types/paymentMethod';
import { LoadingStatus } from '../utils/network/types';

const DEBOUNCE_TIME = 500;

const LABELS = {
  TOP_UP_DESCRIPTION: getText('payment:topUpPersonalAccount.providerMetaDescription'),
};

export enum LoadingFields {
  setPaymentType,
  calculateCommision,
}

class CloudPaymentService {
  store: CloudPaymentStoreType;
  api: typeof api.employeePersonalPayment;

  getCommissionInfoDeb = debounce(api.employeePersonalPayment.getCommissionInfo, DEBOUNCE_TIME);

  networkStatesStore = new NetworkStatesStore<LoadingFields>();

  constructor() {
    this.store = PaymentMethodsStore;
    this.api = api.employeePersonalPayment;
  }

  get currentPaymentMethod() {
    return this.store.paymentMethod;
  }

  get isPersonalPaymentMethod() {
    return this.store.paymentMethod !== PAYMENT_METHODS.ORGANIZATION_ACCOUNT;
  }

  get isCardPaymentMethod() {
    return this.store.paymentMethod === PAYMENT_METHODS.EMPLOYEE_CARD;
  }

  getAccountId = () => this.store.accountId;

  setAccountId = (accountId: string) => {
    this.store.setAccountId(accountId);
  };

  clearStore = () => {
    this.store.clearStore();
  };

  @bound
  @withIM((o) => o.networkStatesStore.withLoaderFlow(LoadingFields.setPaymentType))
  async setPaymentMethod(cartId: number, paymentMethod: PaymentMethodType) {
    const data = await this.api.setPaymentMethod(cartId, paymentMethod);

    this.store.setPaymentMethod(data);
  }

  openPaymentWidget = (paymentInfo: Omit<PAYMENT_INFO, 'accountId'>, paymentType?: string) => {
    widget({ ...paymentInfo, accountId: this.getAccountId() }, paymentType);
  };

  onChangeEmail = (value: string) => {
    this.store.setEmail(value);
  };

  @bound
  @withIM((o) => o.networkStatesStore.withAbortRequestAxios(LoadingFields.calculateCommision))
  @withIM((o) => o.networkStatesStore.withLoaderFlow(LoadingFields.calculateCommision))
  async calculateCommision(type = CommissionType.card) {
    try {
      const commission = await this.getCommissionInfoDeb(
        Number(this.store.amountOfDeposit),
        type,
        this.networkStatesStore.getAbortTokenAxios(LoadingFields.calculateCommision),
      );

      this.store.setCommission(commission);
      this.store.setCommissionError(false);
    } catch (e) {
      this.store.setCommissionError(true);
    }
  }

  onChangeAmountOfDeposit = (value: string) => {
    this.store.setAmountOfDeposit(value);

    if (!this.store.errorsForm.amount) this.calculateCommision();
  };

  recollectData = async () => {
    if (EmployeePaymentStore.cachedParams) {
      this.api
        .getEmployeePersonalTransactions(EmployeePaymentStore.cachedParams)
        .then(EmployeePaymentStore.setTransactions)
        .catch();
    }

    this.api
      .getPersonalBalance()
      .then(EmployeePaymentStore.setPersonalBalance)
      .catch();
  };

  callPaymentFormTopUpPersonal = () => {
    this.networkStatesStore.setStatus(LoadingFields.setPaymentType, LoadingStatus.LOADING);
    widget({
      amount: this.store.getComputedAmount,
      accountId: accountStore.userId as string,
      description: LABELS.TOP_UP_DESCRIPTION,
      disableEmail: true,
      data: {
        type: 'balanceIncoming',
        commission: this.store.getCommission,
        email: this.store.email,
      },
      onSuccess: async () => {
        this.recollectData();
        this.networkStatesStore.setStatus(LoadingFields.setPaymentType, LoadingStatus.LOADED);
      },
      onFail: () => this.networkStatesStore.setStatus(LoadingFields.setPaymentType, LoadingStatus.ERROR),
    }, 'charge');
  };
}

export default CloudPaymentService;
