import React from 'react';
import { Text, Icon, Price as PriceComponent, IconType, Button } from 'new-ui';
import clsx from 'clsx';

import { getText } from '../../../i18n';

import { TRIPSTATUS } from '../../bi/constants/trips';
import ROUTES from '../../bi/constants/routes';
import { SERVICETYPE } from '../../bi/constants/serviceType';

import { formatRangeDateWithSimplicity } from '../../bi/utils/formatDate';
import { getIconType } from '../../bi/utils/trip';
import { MainAnalytic } from '../../bi/utils/analytics';

import { ITripData } from '../../bi/services/events/type';

import styles from './styles/index.module.css';

interface ITripCompactProps {
  value: ITripData,
}

const LABELS = {
  GO_TO_TRIP: getText('event:events.goToTrip'),
};

const TripCompact = ({
  value: {
    Name,
    Id,
    CheckinDate,
    CheckoutDate,
    Price,
    Services,
    Status,
  },
}: ITripCompactProps) => {
  const isRenderPrice = !!Price && Status !== TRIPSTATUS.AWAITINGTICKETING;

  const classwrapper = clsx(
    styles.wrapper, {
      [styles.wrapper_custom]: Services.length === 1 && Services[0] === SERVICETYPE.CUSTOM,
    },
  );

  const handleFollowLink = () => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.GO_TO_TRIP);

    window.location.href = `${ROUTES.TRIP.MAIN}/${Id}`;
  };

  const servicesHtml = (service: string, key: number) => {
    const iconType: IconType | null = getIconType(service);

    if (!iconType) {
      return null;
    }

    return (
      <div className={ styles.icon } key={ `${service}_${key}` }>
        <Icon type={ iconType } color='white' className={ styles.service }/>
      </div>
    );
  };

  const renderPrice = () => {
    if (!isRenderPrice) {
      return null;
    }

    return (
      <PriceComponent
        marginSmall
        type='SEMIBOLD_18'
        value={ Price }
      />
    );
  };

  return (
    <div
      className={ classwrapper }
      key={ Id }
    >
      <div className={ `${styles.column} ${styles.three}` }>
        <Text
          type='bold_18'
        >
          { Name }
        </Text>
      </div>
      <div
        className={ `${styles.column} ${styles.icons} ${styles.three}` }
      >
        { Services.map(servicesHtml) }
      </div>
      <div className={ `${styles.column} ${styles.three}` }>
        <Text type='NORMAL_18'>
          { formatRangeDateWithSimplicity(CheckinDate, CheckoutDate) }
        </Text>
      </div>
      <div className={ `${styles.column} ${styles.two}` }>
        { renderPrice() }
      </div>
      <div className={ `${styles.column} ${styles.three}` }>
        <Button
          type='textual'
          onClick={ () => handleFollowLink() }
        >
          { LABELS.GO_TO_TRIP }
        </Button>
      </div>
    </div>
  );
};

export {
  TripCompact,
};
