import React from 'react';
import { Text } from 'new-ui';
import { Link } from 'react-router-dom';

interface ITransactionBaseTableCellProps {
  value: string;
  link?: string | null;
}

export const TransactionBaseTableCell = ({ value, link }: ITransactionBaseTableCellProps) => {
  if (link) {
    return (
      <Link to={ link }>
        { value }
      </Link>
    );
  }

  return (
    <Text type='NORMAL_16_140'>
      { value }
    </Text>
  );
};
