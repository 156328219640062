import React, { useEffect, useState } from 'react';
import { Dialog, Text, Button, ItemPanel } from 'new-ui';

import { getText } from '../../../../../../i18n';

import Timer from '../../../../../components/Timer';
import { AddresserInfoItem } from '../../../../../components/AddresserInfoItem';
import {
  ReturnPurchaseButton,
  ConsistentCartDeleteButton,
  ApproveStatusListItem,
} from '../../ApproveStatuses/approveStatuses';

import { formatDate, isBeforeDate, getDateDescriptor } from '../../../../../bi/utils/formatDate';
import { cartHasAirUnderageByProviders } from '../../../../../bi/utils/cart';

import { CONSISTENTCARTMODES } from '../../../../../bi/constants/approve';
import { SERVICETYPE } from '../../../../../bi/constants/serviceType';
import { PATTERN } from '../../../../../bi/constants/dateFormats';

import { CartsType, ECartStatus, IItem, IMessage } from '../../../../../bi/types/cart';

import styles from './styles/index.module.css';

export const LABELS = {
  COMMENTS: getText('cart:consistentCartItem.comments'),
  COMMENT_HISTORY: getText('cart:consistentCartItem.commentHistory'),
  TIME_FOR_RESERVE_CANCELLATION: getText('cart:consistentCartItem.timeForReserveCancellation'),
  APPROVAL_CKR: getText('approve:approvalsCKR'),
  TRAVEL_ASSISTANT: getText('cart:consistentCartItem.travelAssistant'),
};

const filterNil = (list: JSX.Element[]) => list
  .filter(item => item !== null && item !== undefined);

interface IRenderApproveComments {
  Messages: IMessage[],
  Email: string,
  Id?: string,
  isOffline: boolean,
}

interface IConsistentCartItemProps {
  type: ECartStatus;
  item: CartsType;
  showApproversCKR: boolean;
  checkUnderage: boolean;
  onClick: (i: CartsType) => void;
  onTimeIsUp: (item: IItem) => void;
  onDelete: (i: CartsType, airToNote: boolean) => void;
  onPurchase: (i: CartsType, isUnderAge: boolean, val: boolean) => void;
  mode?: string;
  qaAttr?: string;
  readonly?: boolean;
  alreadySentIds?: number[];
}

export const ConsistentCartItem = ({
  type,
  item,
  readonly = false,
  showApproversCKR,
  checkUnderage,
  onDelete,
  onPurchase,
  onClick,
  onTimeIsUp,
  mode = CONSISTENTCARTMODES.VIEWER,
  qaAttr = '',
  alreadySentIds = [],
}: IConsistentCartItemProps) => {
  const [showComments, setShowComments] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleLoading = () => setLoading((prev) => !prev);

  useEffect(() => () => {
    if (loading) {
      toggleLoading();
    }
  }, []);

  const handleDelete = (cartItem: CartsType, airToNote: boolean) => {
    toggleLoading();
    onDelete(cartItem, airToNote);
  };

  const renderHeader = () => {
    const renderConsistentCartDeleteButton = !readonly && !showApproversCKR ? (
      <ConsistentCartDeleteButton item={ item } type={ type } onDelete={ handleDelete } />
    ) : null;

    return (
      <div className={ styles.header }>
        <Text type='NORMAL_18'>{ item.name }</Text>
        { renderConsistentCartDeleteButton }
      </div>
    );
  };

  const renderPurchaseButton = () => {
    // const haveFouls = !!item.fouls && item.fouls.length > 0;

    const isUnderage = checkUnderage && cartHasAirUnderageByProviders(item.sources);

    return (
      <ReturnPurchaseButton
        type={ type }
        item={ item }
        onPurchase={ onPurchase }
        onClick={ onClick }
        // haveFouls={ haveFouls }
        hasUnderage={ isUnderage }
        alreadySentIds={ alreadySentIds }
      />
    );
  };

  const renderApproveList = () => {
    const { isOffline, approve } = item;

    const sortedByDateApprovers = approve.sort((a, b) => {
      if (isBeforeDate(a.CreatedDate, b.CreatedDate)) {
        return -1;
      }

      return 0;
    });

    return sortedByDateApprovers.map(({ Resolution, Name, Email, CreatedDate }, i) => {
      const preparedEmail = showApproversCKR ? LABELS.APPROVAL_CKR : Email;

      return <ApproveStatusListItem
        key={ `${Email}_${i}` }
        email={ preparedEmail }
        name={ Name }
        date={ getDateDescriptor(CreatedDate).toLowerCase() }
        resolution={ Resolution }
        showApproversCKR={ showApproversCKR }
        isOffline={ isOffline }
      />;
    });
  };

  const renderAddresserComment = ({ Messages, Email, isOffline }: IRenderApproveComments) => {
    const addresserComment = Messages.find(message => message.Email !== Email && message.Comment);

    let addresserName = '';

    if (addresserComment) {
      addresserName = isOffline ? LABELS.TRAVEL_ASSISTANT : addresserComment.Name;
    }

    return (
      addresserComment &&
      <Text type='NORMAL_16_140' className={ styles.comment }>
        {formatDate(addresserComment.Date, PATTERN.FULLDATEWITHTIME)}, {addresserName} - {addresserComment.Comment}
      </Text>
    );
  };

  const renderApproverComment = ({ Messages, Email, Id, isOffline }: IRenderApproveComments, i: number) => {
    let messageHtml = null;

    Messages.forEach((message, idx) => {
      const messageName = isOffline && idx === 0 ? LABELS.TRAVEL_ASSISTANT : message.Name;

      if (message.Email === Email && message.Comment) {
        messageHtml = (
          <Text type='NORMAL_16_140' className={ styles.comment } key={ `${Id}_${i}` }>
            { formatDate(message.Date, PATTERN.FULLDATEWITHTIME)}, { messageName } - { message.Comment }
          </Text>
        );
      }
    });

    return messageHtml;
  };

  const renderComments = () => {
    const { approve, isOffline } = item;

    const approversCommentsListHtml = filterNil(approve.map(({ Messages, Id, Email }, ind) => (
      <div key={ `${Email}_${ind}` }>
        { renderApproverComment({ Messages, Email, Id, isOffline }, ind) }
      </div>
    )));

    const addresserCommentsListHtml = filterNil(approve.map(({ Messages, Email }, ind) => (
      <div key={ `${Email}_${ind}` }>
        { renderAddresserComment({ Messages, Email, isOffline }) }
      </div>
    )));

    const addresserCommentHtml = addresserCommentsListHtml[0];

    if (!approversCommentsListHtml.length || !addresserCommentsListHtml.length) {
      return null;
    }

    return (
      <div className={ styles.comments } onClick={ (e) => e.stopPropagation() }>
        <Button
          type='textual'
          onClick={ (e) => {
            e.stopPropagation();
            setShowComments(true);
          } }
        >
          {LABELS.COMMENTS}
        </Button>
        <Dialog
          show={ showComments }
          showClosing
          onChange={ setShowComments }
        >
          <div className={ styles['dialog-content'] }>
            <Text type='bold_20' className={ styles['dialog-header'] }>{ LABELS.COMMENT_HISTORY }</Text>
            <>
              { addresserCommentHtml }
              { approversCommentsListHtml }
            </>
          </div>
        </Dialog>
      </div>
    );
  };

  const renderAddresser = () => {
    const { isOffline, approve: [firstItem] } = item;
    const { Messages: [addresser] } = firstItem;

    const date = getDateDescriptor(addresser.Date).toLowerCase();

    return (
      <AddresserInfoItem
        email={ addresser.Email }
        name={ addresser.Name }
        date={ date }
        isOffline={ isOffline }
      />
    );
  };

  const renderApproveBlock = () => (
    mode === CONSISTENTCARTMODES.VIEWER ? renderApproveList() : renderAddresser()
  );

  const renderTimer = () => {
    let minReservedItem = item.sources && item.sources.length && item.sources.length > 0
      ? item.sources.find(cartItem => cartItem.IsReserved && !!cartItem.BookDeadline && cartItem.ServiceType === SERVICETYPE.AIR)
      : null;

    if (minReservedItem) {
      item.sources.forEach((sourceItem) => {
        if (sourceItem.BookDeadline
          && sourceItem.IsReserved
          && sourceItem.ServiceType === SERVICETYPE.AIR
          && isBeforeDate(sourceItem.BookDeadline, minReservedItem?.BookDeadline)) {
          minReservedItem = sourceItem;
        }
      });
    }

    return !!minReservedItem && !readonly ? (
      <div className={ styles.timer }>
        <Text type='NORMAL_14'>
          {LABELS.TIME_FOR_RESERVE_CANCELLATION}
        </Text>
        &nbsp;
        <Timer
          deadLine={ minReservedItem.BookDeadline as string }
          currentTime={ item.serverTime }
          onTimeIsUp={ () => onTimeIsUp(minReservedItem as IItem) }
          className={ styles.time }
        />
      </div>
    ) : null;
  };

  return (
    <ItemPanel
      renderHeader={ renderHeader }
      className={ styles.wrapper }
      loading={ loading }
    >
      <div className={ styles.body } onClick={ () => onClick?.(item) } data-qa={ qaAttr }>
        <div>
          { renderApproveBlock() }
          { renderComments() }
        </div>
        <div className={ styles.right }>
          {
              !readonly && (
                <div className={ styles.purchase }>
                  { renderPurchaseButton() }
                </div>
              )
            }
          { renderTimer() }
        </div>
      </div>
    </ItemPanel>
  );
};
