import { getText } from '../../../../../i18n';

export const LABELS = {
  EMPLOYEE_PERSONAL_ACCOUNT: getText('constants:settings.employeePersonalAccount'),
  AVAILABLE_BALANCE: getText('constants:settings.availableBalance'),
  TOP_UP_CARD: getText('constants:settings.topUpCard'),
  RETURN_TO_CARD: getText('constants:settings.returnMoneyToCard'),
  USER_TRANSACTIONS: getText('constants:settings.userTransactions'),
  INCOMING_TRANSACTION: getText('constants:settings.incoming'),
  OUTGOING_TRANSACTION: getText('constants:settings.outgoing'),
  TABLE_HEADERS: {
    SERVICE: getText('constants:settings.transactionsHistoryTable.service'),
    TRANSACTION_TYPE: getText('constants:settings.transactionsHistoryTable.transactionType'),
    AMOUNT: getText('constants:settings.transactionsHistoryTable.amount'),
    DATE: getText('constants:settings.transactionsHistoryTable.date'),
    FEE: getText('constants:settings.transactionsHistoryTable.fee'),
  },
  NOTHING_FOUND: getText('constants:settings.transactions_not_found'),
};

export const ITEM_PER_PAGE = 6;

export enum SortTransactionsTypeEnum {
  Asc = 'Ascending',
  Desc = 'Descending',
}
