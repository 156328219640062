import React, { FC } from 'react';
import { Moment } from 'moment';
import { Button, Dialog, Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import { formatDate } from '../../../../bi/utils/formatDate';

import { PATTERN } from '../../../../bi/constants/dateFormats';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import styles from './style.module.css';

interface ReservationFailedItem {
  Arrival: string;
  Departure: string;
  Date: Moment | string;
}

interface ApproveErrorDialogProps {
  reservationFailedItems: ReservationFailedItem[];
  show: boolean;
  toogleDialog(value: boolean): void;
}

const LABELS = {
  ON: getText('common:on'),
  TITLE: getText('cart:approveErrorDialog.title'),
  CLOSE: getText('cart:approveErrorDialog.close'),
};

const ApproveErrorDialog: FC<ApproveErrorDialogProps> = ({
  reservationFailedItems,
  show,
  toogleDialog,
}) => {
  const itemsHtml = reservationFailedItems.map(({
    Arrival,
    Departure,
    Date: departureDate,
  }, i) => <Text key={ i }>{Departure} - {Arrival} {LABELS.ON} {formatDate(departureDate, PATTERN.DAY_OF_MONTH)}</Text>);

  return (
    <Dialog
      show={ show }
      onChange={ toogleDialog }
      showClosing
    >
      <div className={ styles['dialog-content'] }>
        <Text type='NORMAL_16_140' qaAttr={ QA_ATTRIBUTES.cart.approval.errorDialog }>
          { LABELS.TITLE }
        </Text>
        <div className={ styles.content }>
          { itemsHtml }
        </div>
        <div className={ styles.actions }>
          <Button onClick={ () => toogleDialog(false) }>{ LABELS.CLOSE }</Button>
        </div>
      </div>
    </Dialog>
  );
};

export { ApproveErrorDialog };
