import { Moment } from 'moment';
import { action, computed, makeObservable, observable } from 'mobx';
import { getStartMonth, isAfterDate, isMoment, lastDayPreviousMonths } from '../../utils/formatDate';
import FORMATS from '../../constants/formats';

const maxDateTo = lastDayPreviousMonths();

export interface IRequestDialogStore {
  showRequestDialog: boolean,
  showRequestErrorDialog: boolean,
  isErrorPaymentDetails: boolean,
  loadingRequestDialog: boolean,
  date: Moment | null,
  dateTo: Moment | null,
  detailed: number,
  format: string,
  reportEmail: string,
  selectedCompanies: number[],
  showSelection: boolean,
  loadingReportAct: boolean,
  reportAct: {
    Success: null | boolean,
    Message: string,
  },
  setShowRequestDialog: (value: boolean) => void,
  setShowRequestErrorDialog: (value: boolean) => void,
  setIsErrorPaymentDetails: (value: boolean) => void,
  setShowSelection: (value: boolean) => void,
  setDate: (value: Moment | null) => void,
  setDateTo: (value: Moment | null) => void,
  setDetailed: (value: number) => void,
  setFormat: (value: string) => void,
  setSelectedCompanies: (list: number[]) => void,
  requestIsValid: () => boolean,
  setReportEmail: (value: string) => void,
}

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable showRequestDialog = false;
  @observable showRequestErrorDialog = false;
  @observable loadingRequestDialog = false;
  @observable isErrorPaymentDetails = false;

  @observable date: Moment | null = getStartMonth(maxDateTo);
  @observable dateTo: Moment | null = maxDateTo;
  @observable detailed = 0;
  @observable format = FORMATS.PDF;
  @observable reportEmail = '';
  @observable selectedCompanies: number[] = [];
  @observable showSelection = false;

  @observable loadingReportAct = false;
  @observable reportAct: { Success: null | boolean, Message: string } = {
    Success: null,
    Message: '',
  };

  @action
  setShowRequestDialog = (value: boolean) => {
    this.showRequestDialog = value;
  };

  @action
  setShowRequestErrorDialog = (value: boolean) => {
    this.showRequestErrorDialog = value;
  };

  @action
  setIsErrorPaymentDetails = (value: boolean) => {
    this.isErrorPaymentDetails = value;
  };

  @action
  setLoadingRequestDialog = (value: boolean) => {
    this.loadingRequestDialog = value;
  };

  @action
  setLoadingReportAct = (value: boolean) => {
    this.loadingReportAct = value;
  };

  @action
  setReportAct = (date: { Success: null | boolean, Message: string }) => {
    this.reportAct = date;
  };

  @action
  setDate = (value: Moment | null) => {
    this.date = value;
  };

  @action
  setDateTo = (value: Moment | null) => {
    this.dateTo = value;
  };

  @action
  setDetailed = (value: number) => {
    this.detailed = value;
  };

  @action
  setShowSelection = (value: boolean) => {
    this.showSelection = value;
  };

  @action
  setFormat = (value: string) => {
    this.format = value;
  };

  @action
  setReportEmail = (value: string) => {
    this.reportEmail = value;
  };

  @action
  setSelectedCompanies = (list: number[]) => {
    this.selectedCompanies = list;
  };

  @computed
  get requestIsValid(): boolean {
    return Boolean(!this.date || !this.dateTo || !isMoment(this.date)
      || !isMoment(this.dateTo) || isAfterDate(this.date, this.dateTo));
  }
}

const RequestDialogStore = new Store();

export { RequestDialogStore, Store as RequestDialogStoreType };
