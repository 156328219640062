import React, { Component } from 'react';
import { History } from 'history';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { Tooltip, LinkButton, Text, AccumulationBarChart } from 'new-ui';

import { getText, getTextArray } from '../../../../../i18n';

import { withStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';
import { UseServicesReturnType } from '../../../../bi/context/services';

import { IUiSettingsStore } from '../../../../bi/services/uiSettings/store';
import Workspace from '../../../../bi/services/workspace';

import { DownloadDepositDialog } from '../../../DownloadDepositDialog';
import { DownloadInvoiceDialog } from '../../../OperationsTable/components/dialogs/DownloadInvoice';

import MoneyFormat from '../../../../bi/utils/money';
import toDecline from '../../../../bi/utils/toDecline';
import { dateUtcFormat } from '../../../../bi/utils/formatDate';
import { isSmartAgent } from '../../../../bi/utils/env';
import { MainAnalytic } from '../../../../bi/utils/analytics';

import { PATTERN } from '../../../../bi/constants/dateFormats';
import { FINANCEDOCUMENTSRIGHT } from '../../../../bi/constants/rights';
import ROUTES from '../../../../bi/constants/routes';
import { PAYMENT_TYPE } from '../../../../bi/constants/payment';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import CloudPayments from '../../../CloudPayments';

import { DateType } from '../../../../bi/types/shared';
import { IInvoiceDebitData } from '../../../../bi/types/report';
import { BalancesType } from '../../../../bi/services/bonusProgram/types';
import { Rights } from '../../../../bi/types/workspace';
import { ICompanyFunds } from '../../../../bi/types/app';

import styles from './styles/index.module.css';

const DEFAULTSUMADDBALANCE = '50000';

const LABELS = {
  moreAvailable: getText('components:header.balance.moreAvailable'),
  more: getText('components:header.balance.more'),
  availableForBooking: getText('components:header.balance.availableForBooking'),
  bonus: getTextArray('components:header.balance.bonusDeclines'),
  payToAccount: getText('components:header.balance.payToAccount'),
  payToAccountSmartagent: getText('components:header.balance.payToAccountSmartagent'),
  sumDebt: getText('components:header.balance.sumDebt'),
  newBookNoAvailable: getText('components:header.balance.newBookNoAvailable'),
  newBookNoAvailableBeginning: getText('components:header.balance.newBookNoAvailableBeginning'),
  newBookNoAvailableEnd: getText('components:header.balance.newBookNoAvailableEnd'),
  noLaterThan: getText('components:header.balance.noLaterThan'),
  outdatedDebt: getText('components:header.balance.outdatedDebt'),
  includingOverpayment: getText('components:header.balance.includingOverpayment'),
  limit: getText('components:header.balance.limit'),
  available: getText('components:header.balance.available'),
  nearestDebit: getText('components:header.balance.nearestDebit'),
  debitNext: getText('components:header.balance.debitNext'),
  registration: getText('components:header.balance.registration'),
  notConnectedAdmin: getText('components:header.balance.notConnectedAdmin'),
  notConnected: getText('components:header.balance.notConnected'),
  companyBalance: getText('components:header.balance.companyBalance'),
  balanceError: getText('components:header.balance.balanceError'),
  s7Corporate: getText('components:header.balance.s7Corporate'),
  goToBalance: getText('components:header.balance.goTo'),
};

const S7_CORPORATE_MASK = 'quw';

const DEFAULT_OPTION = { label: '', value: 0 };

type TMandatoryServices =
  'BonusProgram'
  | 'Report'
  | 'FeatureFlags'
  | 'Smartdesk'
  | 'Payment';

interface IServicesForBalans extends UseServicesReturnType<TMandatoryServices> {}

type TBalanceProps = IServicesForBalans & {
  history: History;
  accountRights: Rights;
  balances: ICompanyFunds[];
  disableS7Balance: boolean;
  company: ReturnType<Workspace['getCompanyData']>;
  isDemo?: boolean;
  showPaymentButton: boolean;
  stores?: {
    uiSettingsStore?: IUiSettingsStore;
  }
};

interface IBalanceState {
  currentCompanyId: null | number;
  currentDebitCompanyId: null | number;
  showInvoiceDialog: boolean;
  isCutInfo: boolean;
  showTooltipSA: boolean;
}

const reducedAmount = (balances: ICompanyFunds[], isCutInfo: boolean) =>
  balances.reduce(
    (
      { current, account },
      { HasDebt, BookingAmount, MaxVisibleBookingAmount },
    ) => {
      let bookingAmount = BookingAmount;

      if (
        isCutInfo &&
        MaxVisibleBookingAmount !== null &&
        MaxVisibleBookingAmount < BookingAmount
      ) {
        bookingAmount = MaxVisibleBookingAmount;
      }

      const fn = (bk: number, acc1: number) => (HasDebt ? acc1 : acc1 + bk);

      return {
        current: fn(bookingAmount, current),
        account: fn(BookingAmount, account),
      };
    },
    { current: 0, account: 0 },
  );

@withStores([MOBX_STORES.UI_SETTINGS])
@observer
class Balance extends Component<TBalanceProps, IBalanceState> {
  static defaultProps = {
    onShowBalance: () => {},
  };

  constructor(props: TBalanceProps) {
    super(props);

    const { Finance } = props.accountRights;

    this.state = {
      currentCompanyId: null,
      currentDebitCompanyId: null,
      isCutInfo: Finance !== FINANCEDOCUMENTSRIGHT.Available,
      showInvoiceDialog: false,
      showTooltipSA: false,
    };
  }

  notShowReportPayment = (id: number) => {
    const { company: { Companies } } = this.props;

    return Companies.some(({ IsShowReporting, CompanyId }) =>
      !IsShowReporting && id === CompanyId);
  };

  handleChangeCurrentCompanyId = (currentCompanyId: number | null) =>
    this.setState({ currentCompanyId });

  handleDownloadDeposit = (sum: string, format: string) => {
    const { reportService } = this.props;
    const { currentCompanyId } = this.state;

    return sum
      ? reportService
        .getDeposit(currentCompanyId as number, sum, format)
        .then(() => this.handleChangeCurrentCompanyId(null))
      : null;
  };

  handleDownloadInvoiceForDateRange = (startDate: DateType, endDate: DateType, format: string) => {
    const { currentDebitCompanyId } = this.state;

    this.props.reportService.downloadInvoiceForDateRange(currentDebitCompanyId as number, startDate, endDate, format);
  };

  handleDownloadInvoiceDebt = (data: IInvoiceDebitData) => {
    const { currentDebitCompanyId } = this.state;

    return this.props.reportService.downloadInvoiceDebt(currentDebitCompanyId as number, data);
  };

  handleonCloseInvoiceDialog = () => this.setState({ showInvoiceDialog: false });

  handlePayTheDebit = (paymentRedirectUrl: string | null, CompanyId: null | number) => {
    if (paymentRedirectUrl) {
      return null;
    }

    return this.setState({ showInvoiceDialog: true, currentDebitCompanyId: CompanyId });
  };

  renderDialogDeposit = () => {
    const { balances } = this.props;
    const { currentCompanyId } = this.state;

    const currentBalance = balances.find(
      balance => balance.CompanyId === currentCompanyId,
    );

    const sumToAd = currentBalance && currentBalance.HasDebt && !!currentBalance.Debt
      ? String(currentBalance.Debt)
      : DEFAULTSUMADDBALANCE;

    return (
      <DownloadDepositDialog
        show={ !!currentCompanyId }
        autoSelect
        onDownloadDeposit={ this.handleDownloadDeposit }
        defaultValue={ sumToAd }
        onClose={ () => this.handleChangeCurrentCompanyId(null) }
      />
    );
  };

  // Логика отображения кнопки "Пополнить счет"
  renderAddBalance = (balance: ICompanyFunds) => {
    const { HasDebt, CompanyId } = balance;
    const {
      stores,
      accountRights: { Finance },
    } = this.props;
    const showPaymentScore = stores?.uiSettingsStore?.settings?.showPaymentScore;
    const paymentRedirectUrl = stores?.uiSettingsStore?.settings?.paymentRedirectUrl;

    const showButton = (showPaymentScore && Finance === FINANCEDOCUMENTSRIGHT.Available && !HasDebt);

    if (!showButton || this.notShowReportPayment(CompanyId)) {
      return null;
    }

    const renderAddBalanceText = isSmartAgent ? LABELS.payToAccountSmartagent : LABELS.payToAccount;

    const renderAddBalanceButtonLink = (url: string) => (
      <a href={ url } target='_blank' rel='noreferrer'>
        { renderAddBalanceText }
      </a>
    );

    const renderAddBalanceButton = (balances: ICompanyFunds) => (
      <div onClick={ () => this.handleChangeCurrentCompanyId(balances.CompanyId) }>
        { renderAddBalanceText }
      </div>
    );

    return paymentRedirectUrl
      ? renderAddBalanceButtonLink(paymentRedirectUrl)
      : renderAddBalanceButton(balance);
  };

  renderCloudPayment = ({ CompanyId: CompanyIdProps }: ICompanyFunds) => {
    const {
      paymentService,
      isDemo,
      showPaymentButton,
      company: { Email, CompanyId, CompanyInn, CompanyName, Companies },
    } = this.props;

    if (isDemo || !showPaymentButton || !Companies.length) {
      return null;
    }

    return (
      <CloudPayments
        companies={ Companies }
        email={ Email }
        companyId={ CompanyId }
        companyInn={ CompanyInn }
        companyName={ CompanyName }
        allCompanies={ Companies }
        paymentService={ paymentService }
        companyIdProps={ CompanyIdProps }
        notShowReportPayment={ this.notShowReportPayment(CompanyIdProps) }
      />
    );
  };

  renderDownloadInvoiceDialogHtml = () => {
    const { showInvoiceDialog } = this.state;
    const { reportService } = this.props;

    return (
      <DownloadInvoiceDialog
        show={ showInvoiceDialog }
        reportService={ reportService }
        onDownloadInvoiceForDateRange={ this.handleDownloadInvoiceForDateRange }
        onDownloadInvoiceDebt={ this.handleDownloadInvoiceDebt }
        onCloseDialog={ this.handleonCloseInvoiceDialog }
      />
    );
  };

  renderHtmlPayTheDebit = ({
    Debt,
    CompanyId,
    HasDebt,
    MaxVisibleBookingAmount,
  }: ICompanyFunds) => {
    const {
      accountRights: { Finance },
      stores,
    } = this.props;
    const showPaymentScore = stores?.uiSettingsStore?.settings?.showPaymentScore;
    const paymentRedirectUrl = stores?.uiSettingsStore?.settings?.paymentRedirectUrl;

    const overdueDebt = (Debt > 0 && HasDebt);
    const url = showPaymentScore ? paymentRedirectUrl : null;

    if (overdueDebt && Finance === FINANCEDOCUMENTSRIGHT.Available && showPaymentScore) {
      return (
        <Text
          className={ styles.row_pay_debit }
          type='NORMAL_16'
        >
          { LABELS.newBookNoAvailableBeginning }
          <a
            href={ url as string }
            target='_blank'
            rel='noreferrer'
            className={ styles.btn_link }
            onClick={ () => this.handlePayTheDebit(paymentRedirectUrl as string | null, CompanyId) }
          >
            { LABELS.newBookNoAvailableEnd }
          </a>
        </Text>
      );
    }

    if (overdueDebt && MaxVisibleBookingAmount === null) {
      return (
        <Text
          className={ styles.row_pay_debit }
          type='NORMAL_16'
        >
          { LABELS.newBookNoAvailable }
        </Text>
      );
    }

    return null;
  };

  renderDebt = ({
    HasDebt,
    Debt,
    TotalOverdraft,
    BookingAmount,
    MaxVisibleBookingAmount,
    PaymentType,
    NextPaymentAmount,
    NextPaymentDate,
    TotalDebt,
  }: ICompanyFunds) => {
    const { accountRights: { Finance } } = this.props;

    let objBalance = DEFAULT_OPTION;
    let objNearestDebit = DEFAULT_OPTION;
    let objLimit = DEFAULT_OPTION;
    let objDebitNext = DEFAULT_OPTION;
    let objOverdueDebit = DEFAULT_OPTION;
    let valuePaymentType = PaymentType;

    let valueBalance = BookingAmount < 0 ? 0 : BookingAmount;
    const valueDebitNext = TotalDebt - NextPaymentAmount;
    const notNullMaxVisibleBookingAmount = MaxVisibleBookingAmount !== null;
    const flagFinance = Finance !== FINANCEDOCUMENTSRIGHT.Available;

    //  если есть ограничение и нет флага финансов и ограничение меньше лимита компании, тогда лимит равен ограничению, иначе лимит равен лимиту компании
    const valueLimit = (notNullMaxVisibleBookingAmount && flagFinance && MaxVisibleBookingAmount < TotalOverdraft)
      ? MaxVisibleBookingAmount
      : TotalOverdraft;

    // если есть ограничение и нет флага финансов и ограничение меньше баланса компании, то баланс равен ограничению
    if (notNullMaxVisibleBookingAmount && flagFinance && MaxVisibleBookingAmount < BookingAmount) {
      valueBalance = MaxVisibleBookingAmount;
    }

    if (valuePaymentType === PAYMENT_TYPE.PREPAYMENT) {
      objBalance = { label: LABELS.availableForBooking, value: valueBalance };
      objNearestDebit = { label: LABELS.sumDebt, value: Debt };

      // если есть ограничение и нет флага финансов и есть задолженность, то общая задолженность равна нулю, чтобы не показывать её пользователю с ограничением
      if (notNullMaxVisibleBookingAmount && HasDebt && flagFinance) {
        objNearestDebit = { label: '', value: 0 };
      }
    }

    if (valuePaymentType === PAYMENT_TYPE.PAYMENT_CLOSING || valuePaymentType === PAYMENT_TYPE.POST_PAYMENT) {
      objLimit = { label: LABELS.limit, value: valueLimit };
      objBalance = { label: LABELS.availableForBooking, value: valueBalance };
      objDebitNext = { label: LABELS.debitNext, value: valueDebitNext };
      objNearestDebit = {
        label: (NextPaymentDate === null)
          ? LABELS.nearestDebit
          : (`${LABELS.nearestDebit} (${LABELS.noLaterThan} ${dateUtcFormat(NextPaymentDate, PATTERN.DAY_OF_MONTH_LONG)})`),
        value: NextPaymentAmount,
      };

      if (HasDebt) {
        objOverdueDebit = { label: LABELS.outdatedDebt, value: Debt };
      }

      // если есть ограничение и нет флага финансов, то мы скрываем(обнулением) всё кроме баланса от пользователя с ограничением
      if (notNullMaxVisibleBookingAmount && flagFinance) {
        objNearestDebit = DEFAULT_OPTION;
        objDebitNext = DEFAULT_OPTION;
        objOverdueDebit = DEFAULT_OPTION;
        valuePaymentType = PAYMENT_TYPE.PREPAYMENT;

        // если есть ещё и задолженность, то мы скрываем(обнулением) всё от пользователя с ограничением
        if (HasDebt) {
          objBalance = { label: LABELS.availableForBooking, value: 0 };
        }
      }
    }

    const preparedPaymentValue = ({
      [PAYMENT_TYPE.PREPAYMENT]: 1,
      [PAYMENT_TYPE.PAYMENT_CLOSING]: 2,
      [PAYMENT_TYPE.POST_PAYMENT]: 3,
    })[valuePaymentType];

    return (
      <AccumulationBarChart
        label=''
        showContent
        clientType={ preparedPaymentValue }
        limit={ objLimit }
        balance={ objBalance }
        nearestDebit={ objNearestDebit }
        debitNext={ objDebitNext }
        оverdueDebit={ objOverdueDebit }
        debitTotalLabel={ LABELS.sumDebt }
        overpaymentLabel={ LABELS.includingOverpayment }
      />
    );
  };

  renderCheckTypeAccountForCloudPayment = (balance: ICompanyFunds) => {
    const { accountRights: { Finance } } = this.props;

    const flagFinance = Finance === FINANCEDOCUMENTSRIGHT.Available;

    return isSmartAgent && flagFinance ? this.renderCloudPayment(balance) : null;
  };

  renderButtonActionBalance = (balance: ICompanyFunds) => {
    const {
      accountRights: {
        Finance,
      },
      featureFlagsService: {
        getShowElementForSmartagent,
      },
      smartdeskService: {
        selectCompany,
      },
      history,
    } = this.props;
    const { showTooltipSA } = this.state;

    if (isSmartAgent && Finance === FINANCEDOCUMENTSRIGHT.Available && getShowElementForSmartagent()) {
      const goToBalancePage = (id: number) => {
        this.setState({ showTooltipSA: !showTooltipSA });
        selectCompany(id);
        MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SMART_AGENT.REDISIGN.MAINBALANCE_ADD_PRESSED);
        history.push({ pathname: ROUTES.SETTINGS.BALANCE });
      };

      return (
        <div
          className={ styles.text_container }
          onClick={ () => goToBalancePage(balance.CompanyId) }
        >
          <Text
            type='NORMAL_14'
            className={ styles.btn_link }
          >
            { LABELS.goToBalance }
          </Text>
        </div>
      );
    }

    return (
      <div className={ styles.text_container }>
        { this.renderCheckTypeAccountForCloudPayment(balance) }
        <Text
          type='NORMAL_14'
          className={ styles.btn_link }
        >
          { this.renderAddBalance(balance) }
        </Text>
      </div>
    );
  };

  renderBalance = () => this.props.balances.map((balance, index) => {
    const border = index !== this.props.balances.length - 1 && (
      <div className={ styles.border }/>
    );

    return (
      <div className={ styles.balance_item } key={ `${balance.CompanyId}_${index}` }>
        <div className={ `${styles.row} ${styles.title}` }>
          <Text
            className={ styles.text_col_first }
            type='SEMIBOLD_16'
          >
            {balance.Name || ' '}
          </Text>
          { this.renderButtonActionBalance(balance) }
        </div>
        { this.renderDebt(balance) }
        { this.renderHtmlPayTheDebit(balance) }
        { border }
      </div>
    );
  });

  setShowTooltipSA = () => {
    const { showTooltipSA } = this.state;
    const {
      featureFlagsService: {
        getShowElementForSmartagent,
      },
    } = this.props;

    if (isSmartAgent && getShowElementForSmartagent() && !showTooltipSA) {
      this.setState({
        showTooltipSA: !showTooltipSA,
      });
    }
  };

  renderLinkBalance = ({ current }: { current: number }) => {
    const word = '';

    return (
      <Text type='NORMAL_14'>
        {LABELS.available} {word}
        <span data-qa={ QA_ATTRIBUTES.header.balance.available }>{MoneyFormat.moneyWithDecimal(current)}</span> ₽
      </Text>
    );
  };

  renderBonusRegistration = () => {
    const {
      bonusProgramService: {
        store: {
          bonuses: { UserIsAdmin, AdminEmail, AdminName },
        },
      },
    } = this.props;

    if (UserIsAdmin) {
      return (
        <div className={ styles.row }>
          <Text
            className={ styles.text_col_first }
            type='NORMAL_14'
          >
            {LABELS.notConnected}
          </Text>
          <NavLink to={ ROUTES.SETTINGS.S7 }>
            <Text
              className={ styles.add_link }
              color={ 'accent' }
              type='NORMAL_14'
            >
              {LABELS.registration}
            </Text>
          </NavLink>
        </div>
      );
    }

    return (
      <Text type='NORMAL_14_130'>
        {LABELS.notConnectedAdmin}({AdminName},{' '}
        <a className={ styles.mail } href={ `mailto:${AdminEmail}` }>
          {AdminEmail}
        </a>
        )
      </Text>
    );
  };

  renderBalancesContent = (failedBalances: BalancesType[], balances: BalancesType[]) =>
    balances.map(({ Company, Balance: BonusBalance }) => {
      const title =
      // @ts-ignore
        balances.length > 1 || !failedBalances.length > 1
          ? Company
          : LABELS.companyBalance;

      return (
        <div className={ styles.row } key={ Company }>
          <Text
            className={ styles.text_col_first }
            type='NORMAL_16'
          >
            {title}
          </Text>
          <Text type='SEMIBOLD_14'>
            {MoneyFormat.moneyWithDecimal(BonusBalance)}
            &nbsp;
            {toDecline(Balance as unknown as number, LABELS.bonus)}
          </Text>
        </div>
      );
    });

  renderFailedBalancesContent = (failedBalances: BalancesType[], balances: BalancesType[]) =>
    failedBalances.map(({ Company }) => {
      const companyName =
        failedBalances.length > 1 || !!balances ? Company : '';

      return (
        <div className={ styles.row } key={ Company }>
          <Text type='NORMAL_14'>
            {LABELS.balanceError} {companyName}
          </Text>
        </div>
      );
    });

  renderAlreadyBonuses = () => {
    const {
      bonusProgramService: {
        store: {
          bonuses: { Balances, FailedBalances },
        },
      },
    } = this.props;
    const balancesContentHtml =
      !!Balances?.length &&
      this.renderBalancesContent(FailedBalances as BalancesType[], Balances);
    const failedBalancesContentHtml =
      !!FailedBalances?.length &&
      this.renderFailedBalancesContent(FailedBalances as BalancesType[], Balances as BalancesType[]);

    return (
      <div>
        {balancesContentHtml}
        {failedBalancesContentHtml}
      </div>
    );
  };

  renderS7Balance = () => {
    if (isSmartAgent) return null;

    const {
      disableS7Balance,
      bonusProgramService: {
        store: {
          bonuses: { Status, FailedBalances, UserIsAdmin, error },
        },
      },
      stores,
    } = this.props;
    const showS7Program = stores?.uiSettingsStore?.settings?.showS7Program;

    const s7BalanceByFeatureFlag = !disableS7Balance || UserIsAdmin;
    const s7BalanceIsDiscount = Status !== 2;
    const isFailedBalances = FailedBalances?.length &&
      FailedBalances.some(({ Code = '' }) =>
        Code.toLowerCase().includes(S7_CORPORATE_MASK),
      );

    const showS7Balances =
      s7BalanceByFeatureFlag &&
      s7BalanceIsDiscount &&
      !error &&
      showS7Program &&
      !isFailedBalances;

    if (!showS7Balances) return null;

    const content = Status
      ? this.renderAlreadyBonuses()
      : this.renderBonusRegistration();

    return (
      <div className={ styles['balance-item'] }>
        <div className={ styles.border_top } />
        <div className={ styles['bonus-title'] }>
          <Text type='SEMIBOLD_16'>
            {LABELS.s7Corporate}
          </Text>
        </div>
        {content}
      </div>
    );
  };

  render() {
    const { balances, featureFlagsService: { getShowElementForSmartagent } } = this.props;
    const { isCutInfo, showTooltipSA } = this.state;

    const isCutInfoWithOneCompany = isCutInfo && balances.length === 1;
    const isShowTooltip = !(isSmartAgent && getShowElementForSmartagent() && !showTooltipSA);

    const availableHtml = () => {
      if (isCutInfoWithOneCompany && balances[0].MaxVisibleBookingAmount === 0) {
        return null;
      }

      return this.renderLinkBalance(reducedAmount(balances, isCutInfo));
    };

    const renderTooltip = () => (
      <div className={ styles['dialog-content'] }>
        { this.renderBalance() }
        { this.renderS7Balance() }
      </div>
    );

    return (
      <div className={ styles.wrapper }>
        <Tooltip
          className={ styles.tooltip }
          show={ isShowTooltip }
          renderContent={ () => renderTooltip() }
          clickable
          position='bottom'
          theme='white-without-foot'
        >
          <LinkButton>
            <div onClick={ () => this.setShowTooltipSA() }>
              { availableHtml() }
            </div>
          </LinkButton>
        </Tooltip>
        { this.renderDialogDeposit() }
        { this.renderDownloadInvoiceDialogHtml() }
      </div>
    );
  }
}

export default Balance;
