import { DepartmentsListItem } from '../services/departments/types';

type Department = {
  Id: number;
  Name: string;
};

type Company = {
  CompanyId: number;
  ShortCompanyName: string;
  CompanyName: string;
  Departments: Department[];
};

type PayloadRequest = {
  Companies: number[];
  Departments: number[];
};

type Payload = {
  payloadRequests: PayloadRequest[];
  payloadDepartments: Company[];
};

type MappedDepartment = {
  value: number;
  label: string;
};

type MappedCompany = {
  value: number;
  label: string;
  nested?: MappedDepartment[] | null;
};

const departmentsListMapper = (payload: Payload): MappedCompany[] => {
  const { payloadRequests, payloadDepartments } = payload;
  const companiesSet = new Set();
  const departmentsSet = new Set();

  payloadRequests.forEach(({ Companies = [], Departments = [] }) => {
    Companies.forEach(i => companiesSet.add(i));
    Departments.forEach(i => departmentsSet.add(i));
  });

  const deparmentToObject = (departments: Department[]) =>
    departments.reduce((res: MappedDepartment[], { Id, Name }) => {
      if (departmentsSet.has(Id)) {
        return [...res, { value: Id, label: Name }];
      }

      return res;
    }, []);

  return payloadDepartments.reduce((
    res: MappedCompany[],
    { CompanyId, ShortCompanyName, CompanyName, Departments },
  ) => {
    if (companiesSet.has(CompanyId)) {
      const reducedDepartments = deparmentToObject(Departments);

      const nested = reducedDepartments.length ? reducedDepartments : null;
      const newCompany: MappedCompany = { value: CompanyId, label: ShortCompanyName || CompanyName };

      if (nested) {
        newCompany.nested = nested;
      }

      return [...res, newCompany];
    }

    return res;
  }, []);
};

const companiesWithDepartmentsMapper = (list: DepartmentsListItem[]) => list.map(({
  CompanyId = null,
  CompanyName = '',
  ShortCompanyName = '',
  Departments = [],
}) =>
  ({
    value: CompanyId,
    label: ShortCompanyName || CompanyName,
    nested: Departments.map(({ Id, Name: dName }) => ({ value: Id, label: dName })),
  }),
);

const getDepartmentNames = (Departments: string[] = []): string => (Departments.length !== 0 ? `(${Departments.join(', ')})` : '');

export {
  departmentsListMapper,
  companiesWithDepartmentsMapper,
  getDepartmentNames,
};
