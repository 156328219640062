import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { Select, Text, RadioButton } from 'new-ui';
import { getText } from '../../../../../../i18n';

import { RightsDialogWrap } from '../RightsDialogWrap';

import { isSmartAgent } from '../../../../../bi/utils/env';

import { STEPSRIGHTS, FIELDRIGHTS } from '../../../../../bi/constants/rights';
import { QA_ATTRIBUTES } from '../../../../../bi/constants/attributesForTests';

import styles from '../index.module.css';

const LABELS = {
  MANUALLY: getText('settings:employees.employee.steps.rights.steps.create.manually'),
  COPY: getText('settings:employees.employee.steps.rights.steps.create.copy'),
};

const { employee: { documents: { access: { modals } } } } = QA_ATTRIBUTES;

interface Item {
  label: string,
  value: string,
  name?: string,
}

interface ICreateStep {
  active: string,
  header: string,
  rights: {
    manually: boolean,
    copy: boolean,
    copyUser: string,
  },
  users: {
    withRights: Item[],
  },
  getCopyUserRights(value: string): Promise<void>
  onClose(): void,
  onChangeStep(value: string): void,
  onChangeRights(field: string, value: boolean | string): void,
  onChangeUser(value: string): void,
}

const CreateStep = observer(({
  active,
  header,
  rights: {
    manually,
    copy,
    copyUser,
  },
  users: {
    withRights,
  },
  getCopyUserRights,
  onClose,
  onChangeStep,
  onChangeRights,
  onChangeUser,
}: ICreateStep) => {
  const [loading, setLoading] = useState(false);

  const handleChangeStep = () => {
    if (manually) {
      return onChangeStep(STEPSRIGHTS.MAIN);
    }

    setLoading(true);

    return getCopyUserRights(copyUser)
      .then(() => onChangeStep(STEPSRIGHTS.COPY))
      .finally(() => setLoading(false));
  };

  const handleChange = (value: string) => {
    onChangeRights(FIELDRIGHTS.COPY, true);
    onChangeUser(value);
  };

  const renderLabel = (text: Item) =>
    <Text color='accent'>
      { text.name }
    </Text>;

  const renderCopyUser = () => {
    if (withRights.length === 1) {
      return (
        <Text className={ styles['selected-item'] }>
          { withRights[0].name }
        </Text>
      );
    }

    return (
      <Select
        search
        value={ copyUser }
        theme='open-blue'
        items={ withRights }
        renderLabel={ renderLabel }
        onChange={ handleChange }
        className={ styles['selected-item'] }
        qaAttr={ QA_ATTRIBUTES.employee.documents.access.modals.create.selectEmployee }
        qaAttrInput={ QA_ATTRIBUTES.employee.documents.access.modals.create.selectEmployeeInput }
      />
    );
  };

  return (
    <RightsDialogWrap
      header={ header }
      theme={ active }
      onClickButton={ handleChangeStep }
      onClickCancel={ onClose }
      qaAttrButton={ modals.buttonNext }
      qaAttrLink={ modals.create.buttons.cancel }
      qaAttrWrapper={ modals.create.wrapper }
      loading={ loading }
    >
      <RadioButton
        className={ styles.item }
        checked={ manually }
        alternativeDesign={ isSmartAgent }
        onChange={ () => onChangeRights(FIELDRIGHTS.MANUALLY, true) }
        qaAttr={ modals.create.radioButtons.manually }
      >
        { LABELS.MANUALLY }
      </RadioButton>
      <div className={ `${styles['multiple-item']} ${styles.item}` }>
        <RadioButton
          checked={ copy }
          className={ styles.item }
          alternativeDesign={ isSmartAgent }
          onChange={ () => handleChange(copyUser) }
          qaAttr={ modals.create.radioButtons.copy }
        >
          { LABELS.COPY }
        </RadioButton>
        { renderCopyUser() }
      </div>
    </RightsDialogWrap>
  );
});

export { CreateStep };
