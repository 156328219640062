import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Text, Button, Tooltip } from 'new-ui';

import { getText } from '../../../../../i18n';

import Employee from '../../../../bi/services/employee';

import { DocumentForm } from '../../../Employee/components/Documents';

import { formatDate } from '../../../../bi/utils/formatDate';
import { sanitizeText } from '../../../../bi/utils/text';
import { employeeMayHavePassport } from '../../../../bi/utils/employees';
import { checkUaWithDomesticPassport } from '../../../../bi/utils/documents';
import { MainAnalytic } from '../../../../bi/utils/analytics';

import { PATTERN } from '../../../../bi/constants/dateFormats';
import PASSPORTS from '../../../../bi/constants/passport';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';
import ROUTES from '../../../../bi/constants/routes';
import { PASSPORTS_ADDITIONAL_CHANGE } from '../../../../bi/constants/changeAirTrip';
import COUNTRIES from '../../../../bi/constants/countries';
import { NOTIFICATION_LEVEL } from '../../../../bi/constants/notifications';
import { ANALYTIC_SERVICE_TYPES } from '../../../../bi/constants/serviceType';

import { ICompanyEasy,
  IDocumentUser,
  IEmployees,
  IErrorEmployee,
  IPassportUser,
  IPassportView,
} from '../../../../bi/services/employee/types';
import { UserSessionService } from '../../../../bi/types/userSession';
import { EmployeesDocument, EmployeeStoreModule } from '../../../../bi/types/employees';
import { ChatService } from '../../../../bi/types/chat';
import { NotificationService } from '../../../../bi/types/notification';
import { ListDocument, OrderItem } from '../../../../bi/types/order';

import styles from './styles/index.module.css';

const LABELS = {
  MESSAGE_CHANGE_TICKET: getText('components:changeAirTrip.changeDocument.changeTicketToPersonalData'),
  NEW_DATA: getText('components:changeAirTrip.changeDocument.newData'),
  NAME: getText('components:changeAirTrip.changeDocument.name'),
  SURNAME: getText('components:changeAirTrip.changeDocument.surname'),
  PATRONYMIC: getText('components:changeAirTrip.changeDocument.patronymic'),
  NUMBER: getText('components:changeAirTrip.changeDocument.number'),
  NUMBER_RU: getText('components:changeAirTrip.changeDocument.numberRu'),
  NUMBER_CERTIFICATE: getText('settings:employees.employee.steps.documents.birthCertificate.number'),
  VALIDITY: getText('components:changeAirTrip.changeDocument.validity'),
  SEND_REQUEST: getText('components:changeAirTrip.sendRequest'),
  BACK: getText('components:changeAirTrip.back'),
  DEMO_TOOLTIP: getText('components:changeAirTrip.demo'),
  WARNING_EDIT_TOOLTIP: getText('components:changeAirTrip.changeDocument.warningEdit'),
  NOTIFICATIONS_SAVE_DOC: getText('components:changeAirTrip.changeDocument.notificationSaveDoc'),
  NOTIFICATIONS_ERROR_SAVE_DOC: getText('components:changeAirTrip.changeDocument.notificationErrorSaveDoc'),
};

const {
  changeTrip: {
    air: {
      byPassport: {
        sendRequest,
        back: backTest,
        notifications: notificationsTest,
      },
    },
  },
} = QA_ATTRIBUTES;

interface IFormPassportInterface {
  userSessionService: UserSessionService;
  employeeService: Employee;
  chatService: ChatService;
  notificationService: NotificationService;
  isDemo: boolean;
  history: RouteComponentProps['history'];
  selectedDocument: ListDocument | null;
  tripId: string;
  ticketId: string;
  tripItem: OrderItem | null;
  stateEmploy: EmployeeStoreModule;
  setCurrentDocument: Dispatch<SetStateAction<boolean>>;
}

const FormPassport = ({
  employeeService,
  userSessionService,
  chatService,
  notificationService,
  isDemo,
  history,
  tripId,
  ticketId,
  tripItem,
  selectedDocument,
  stateEmploy,
  setCurrentDocument,
}: IFormPassportInterface) => {
  const [typesDocument, setTypesDocument] = useState<Partial<IPassportView> | null>(null);
  const [disabledChange, setDisabledChange] = useState(true);
  const [localLoading, setLocalLoading] = useState(false);
  const [agreement, setAgreement] = useState(false);

  const disabledSend = !stateEmploy.isValid || !disabledChange || isDemo;
  const isAddNewForeign = selectedDocument
    ?.AUXILIARY_TYPE === PASSPORTS_ADDITIONAL_CHANGE.ADD_NEW_FIRST_FOREIGN_PASSPORT.AUXILIARY_TYPE
      || selectedDocument
        ?.AUXILIARY_TYPE === PASSPORTS_ADDITIONAL_CHANGE.ADD_NEW_SECOND_FOREIGN_PASSPORT.AUXILIARY_TYPE;

  useEffect(() => {
    const { enums: { documents } } = userSessionService.get();
    setTypesDocument(documents);

    if (selectedDocument?.AUXILIARY_TYPE === PASSPORTS_ADDITIONAL_CHANGE.ADD_NEW_SECOND_FOREIGN_PASSPORT.AUXILIARY_TYPE) {
      employeeService.addDocument(documents as IPassportView);
    }

    if (isAddNewForeign) {
      setDisabledChange(false);
    }
  }, []);

  const createMessageForForeign = (
    surname: string,
    name: string,
    patronymic: string,
    number: string,
    date: string,
  ) => (
    `
      ${LABELS.MESSAGE_CHANGE_TICKET}
      ---------------
      ${LABELS.NEW_DATA}:
      ${PASSPORTS_ADDITIONAL_CHANGE.FOREIGN_PASSPORT.VALUE}
      ${LABELS.SURNAME}: ${surname}
      ${LABELS.NAME}: ${name}
      ${LABELS.PATRONYMIC}: ${patronymic || '-'}
      ${LABELS.NUMBER}: ${number}
      ${LABELS.VALIDITY}: ${formatDate(date, PATTERN.DATE_WITH_YEAR)}
    `
  );

  const createMessageForLocal = (
    surname: string,
    name: string,
    patronymic: string,
    number: string,
    value?: string,
  ) => {
    const seriesAndNumber = selectedDocument?.TYPE === PASSPORTS.DOMESTIC_PASSPORT.TYPE
      ? LABELS.NUMBER_RU
      : LABELS.NUMBER_CERTIFICATE;

    return (`${LABELS.MESSAGE_CHANGE_TICKET}
      ---------------
      ${LABELS.NEW_DATA}:
      ${value}
      ${LABELS.SURNAME}: ${surname}
      ${LABELS.NAME}: ${name}
      ${LABELS.PATRONYMIC}: ${patronymic}
      ${seriesAndNumber}: ${number}
    `);
  };

  const createMessage = () => {
    const { Surname, Name, Patronymic, Number, DueDate } = stateEmploy.employee.Documents.find((document) => {
      if (isAddNewForeign && document.Type === selectedDocument?.TYPE) {
        return document.Id === 0;
      }

      if (document.Type === PASSPORTS.FOREIGN_PASSPORT.TYPE) {
        return document.Number === selectedDocument?.NUMBER;
      }

      return document.Type === selectedDocument?.TYPE;
    }) as IDocumentUser;

    const message = selectedDocument?.TYPE === PASSPORTS.FOREIGN_PASSPORT.TYPE ?
      createMessageForForeign(Surname, Name, Patronymic, Number, DueDate) :
      createMessageForLocal(Surname, Name, Patronymic, Number, selectedDocument?.VALUE ?? '');

    return message;
  };

  const handleEditTripItem = () => {
    if (tripItem) {
      chatService.requestOnChangeByOrder(tripItem, createMessage(), Number(tripId));
      chatService.sendTypeForAirline(tripItem.Id);
    }
  };

  const handleSendRequest = () => {
    handleEditTripItem();

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.TRIPS.TRIP_CHANGE_CONFIRM_PRESSED(Number(tripId), ANALYTIC_SERVICE_TYPES.AIR),
    );

    history.push(ROUTES.CHANGE_AIR_TRIP.TRIP(tripId));
  };

  const handleDocumentResetErrors = (field: string, index: number) => employeeService.resetDocumentErrors(field, index);

  const handleDocumentValidationInput = (field: string, index: number) => employeeService.validationDocumentInput(
    field,
    index,
    typesDocument as IPassportView,
    stateEmploy.employeePrevious.Documents as IDocumentUser[],
  );

  const handleDocumentInputChange = (value: string, field: string, index: number) => {
    employeeService.changeDocumentInput(value, field, index, typesDocument as IPassportView);
  };

  const handleValidationInput = (field: string) => {
    employeeService.validateRequiredFields();
    employeeService.validationInput(field);
  };

  const handleBlurDocumentInput = (
    value: string,
    field: string,
    index: number,
    cyrillicFeatureFlag: boolean,
  ) => {
    const clearValue = sanitizeText(value);

    employeeService.changeDocumentInput(clearValue, field, index, typesDocument as IPassportView, cyrillicFeatureFlag);
    handleValidationInput(field);
  };

  const handleRuNumberChange = (value: string, index: number) => employeeService.changeRuNumberPassport(
    value,
    index,
    typesDocument as IPassportView,
    stateEmploy.employeePrevious.Documents as IDocumentUser[],
  );

  const handleCertificateNumberChange = (value: string, index: number) => employeeService.changeCertificateNumber(
    value,
    index,
    typesDocument as IPassportView,
    // TODO: должен передаваться travelPolicyFlag, но в компоненте его нет
    // @ts-ignore
    stateEmploy.employeePrevious.Documents,
  );

  const handleIntNumberChange = (value: string, index: number) => employeeService.changeIntNumberPassport(
    value,
    index,
    typesDocument as IPassportView,
    stateEmploy.employeePrevious.Documents as IDocumentUser[],
  );

  const handleDomesticNumberChange = (value: string, index: number) => employeeService.checkDomesticPassportNumber(
    value,
    index,
    stateEmploy.employeePrevious.Documents as IDocumentUser[],
  );

  const handleDocumentDateChange = (field: string, index: number, value: string) => employeeService.changeDocumentInput(
    value,
    field,
    index,
    typesDocument as IPassportView,
  );

  const handleBackPreviousDocument = (employ = stateEmploy.employeePrevious) => employeeService.setEmployeeCurrentDocument(
    employ as IEmployees,
    typesDocument as IPassportView,
  );

  const validation = () => employeeService.validationSuccess();

  const handleSave = async () => {
    const newItem = { ...stateEmploy.employee };

    newItem.Documents = newItem.Documents.filter(({ Type, Number, Surname, Name, LastName, FirstName, DueDate }) => {
      const currentField = Number.trim().length && Surname.trim().length && Name.trim().length;

      if (Type === typesDocument?.DomesticPassport && newItem.Code === COUNTRIES.RU.SHORTNAME) {
        return currentField && LastName.trim().length && FirstName.trim().length;
      }

      if (Type === typesDocument?.ForeignPassport || newItem.Code !== COUNTRIES.RU.SHORTNAME) {
        const isNotRequiredDueDate = checkUaWithDomesticPassport(newItem.Code, Type);

        return currentField && (DueDate || isNotRequiredDueDate);
      }

      if (Type === typesDocument?.BirthCertificate) {
        return currentField;
      }

      return false;
    }) as EmployeesDocument[];

    newItem.Bonuses = newItem.Bonuses.filter(bonus => bonus.Code && bonus.Number);

    newItem.EmployeeNumber = newItem.EmployeeNumber.trim();

    newItem.Companies = (newItem.Companies as ICompanyEasy[])
      .map(({ main, nested = [] }) => (
        { CompanyId: main,
          Departments: nested.map((i: string) => ({ Id: i })),
        }
      )) as unknown as ICompanyEasy[];

    if (validation()) {
      employeeService.updateState(typesDocument as IPassportView);
      setLocalLoading(true);
      const { TpRights, ...dataToBeSaved } = newItem;

      try {
        await employeeService.updateDocumentChangeAirTrip(
          dataToBeSaved,
          stateEmploy.employee as IEmployees,
          typesDocument as IPassportView,
        );
        notificationService.send({
          message: LABELS.NOTIFICATIONS_SAVE_DOC,
          level: NOTIFICATION_LEVEL.SUCCESS,
          qaAttr: notificationsTest.success,
        });
      } catch (error) {
        handleBackPreviousDocument();
        notificationService.send({
          message: LABELS.NOTIFICATIONS_ERROR_SAVE_DOC,
          level: NOTIFICATION_LEVEL.ERROR,
          qaAttr: notificationsTest.error,
        });
      }

      setDisabledChange(true);
      setLocalLoading(false);
      setAgreement(false);
    }
  };

  const handleBack = () => {
    handleBackPreviousDocument();

    if (stateEmploy?.employee.Code !== COUNTRIES.RU.SHORTNAME) {
      return history.push(ROUTES.CHANGE_AIR_TRIP.SELECT_CHANGE(tripId, ticketId));
    }

    return setCurrentDocument(false);
  };

  const renderDocumentForm = () => {
    const {
      employee,
      errors: {
        Documents: errDocuments,
      },
    } = stateEmploy;
    const {
      Documents,
      Birthday,
      employeeHasCyrillicDomesticPassport,
    } = employee;

    let indexForeign = 1;
    let indexDomestic = 1;

    const mayHavePassport = !Birthday || employeeMayHavePassport(Birthday);
    const newDocuments = Documents.map((document) => {
      const newDocument = { ...document };

      if (document.Type === PASSPORTS.FOREIGN_PASSPORT.TYPE) {
        newDocument.index = indexForeign;
        indexForeign++;
      }

      if (document.Type === PASSPORTS.DOMESTIC_PASSPORT.TYPE || document.Type === PASSPORTS.BIRTH_CERTIFICATE.TYPE) {
        newDocument.index = indexDomestic;
        indexDomestic++;
      }

      return newDocument;
    });

    return newDocuments.map((document, index) => {
      if (document.Type === selectedDocument?.TYPE && document.index === selectedDocument?.INDEX) {
        return (
          <DocumentForm
            key={ `${document.Type}__${index}` }
            index={ document.index }
            employee={ employee as IEmployees }
            document={ document as IPassportUser }
            error={ errDocuments[index] as IErrorEmployee }
            documentType={ typesDocument as IPassportView }
            onResetErrors={ field => handleDocumentResetErrors(field, index) }
            onValidationInput={ field => handleDocumentValidationInput(field, index) }
            onInputChange={ (value, field) => handleDocumentInputChange(value, field, index) }
            // TODO: ожидает cyrillicFeatureFlag, но в компоненте его нет
            // @ts-ignore
            onBlurInput={ (value, field) => handleBlurDocumentInput(value, field, index) }
            onRuNumberChange={ e => handleRuNumberChange(e, index) }
            onCertificateNumberChange={ e => handleCertificateNumberChange(e, index) }
            onIntNumberChange={ e => handleIntNumberChange(e, index) }
            onDomesticNumberChange={ e => handleDomesticNumberChange(e, index) }
            onDateChange={ (field, value) => handleDocumentDateChange(field, index, value as string) }
            onDeleteDocument={ () => {} }
            mayHavePassport={ mayHavePassport }
            cyrillicPassport={ employeeHasCyrillicDomesticPassport ?? 0 }
            isAirTripChange
            isValid={ stateEmploy?.isValid }
            disabledAirTripChange={ disabledChange }
            loadingAirTripChange={ localLoading }
            agreementAirTripChange={ agreement }
            isAddNewForeignDocument={ isAddNewForeign }
            setAgreementAirTripChange={ setAgreement }
            setDisabledAirTripChange={ setDisabledChange }
            onBackAirTripChange={ handleBackPreviousDocument }
            onSaveAirTripChange={ handleSave }
            isChangeAirTripPage
          />
        );
      }

      return null;
    });
  };

  const contentTooltip = () => {
    if (isDemo) {
      return LABELS.DEMO_TOOLTIP;
    }

    if (!disabledChange) {
      return LABELS.WARNING_EDIT_TOOLTIP;
    }

    return '';
  };

  const renderContent = () => {
    if (!typesDocument) return null;

    return (
      <div className={ styles.wrapper }>
        { renderDocumentForm() }
        <div className={ styles.action }>
          <Tooltip
            className={ styles.tooltip }
            show={ isDemo || !disabledChange }
            renderContent={ () => (
              <Text
                className={ styles.tooltip_text }
                color='white'
                type='NORMAL_14_130'
              >
                { contentTooltip() }
              </Text>
            ) }
          >
            <Button
              type='secondary'
              disabled={ disabledSend }
              onClick={ handleSendRequest }
              qaAttr={ sendRequest }
            >
              { LABELS.SEND_REQUEST }
            </Button>
          </Tooltip>
          <Button
            className={ styles.back }
            type='textual'
            onClick={ handleBack }
            qaAttr={ backTest }
          >
            { LABELS.BACK }
          </Button>
        </div>
      </div>
    );
  };

  return renderContent();
};

export { FormPassport };
