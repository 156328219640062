import React, { useEffect, useState } from 'react';
import { Button, CircleLoading, NoResults, SidePanel, Text } from 'new-ui';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { getText } from '../../../i18n';
import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import { ChatService } from '../../bi/types/chat';
import Event from '../../bi/services/events';
import FeatureFlags from '../../bi/services/featureFlags';
import PopupsService from '../../bi/services/popups';

import AddEvent from './components/AddEvent/AddEvent';
import { Events } from './components/Events/Events';
import FiltredEvents from './components/FiltredEvents';
import { CalculatorMice } from './components/CalculatorMice';

import Card from '../../../images/mice/Card.svg';
import FrameNew from '../../../images/mice/FrameNew.svg';
import Modal from '../../../images/mice/Modal.webp';

import { MainAnalytic } from '../../bi/utils/analytics';

import ROUTES from '../../bi/constants/routes';
import { AGGREGATORS_ACCOUNT } from '../../bi/constants/accounts';
import { DIRECTION } from './const';
import { POPUP_NAME } from '../../bi/constants/popups';

import { TEventValue } from '../../bi/services/events/type';

import styles from './index.module.css';

const LABELS = {
  TITLE: getText('event:event'),
  INFO_MICE: getText('event:infoMice'),
  APPLICATION_TITLE: getText('event:applicationTitle'),
  ADD_LABEL: getText('event:addLabel'),
  PLACEHOLDER_SELECT: getText('event:placeholderSelect'),
  NO_RESULT_LABEL: getText('event:noResultLabel'),
  ERROR_LOADING: getText('event:errorLoading'),
  TITLE_CALCULATOR: getText('event:calculator.title'),
};

interface IEventPage {
  eventService: Event,
  chatService: ChatService,
  featureFlagsService: FeatureFlags,
  popupsService: PopupsService,
  aggregationId: number | null,
}

const EventPage = observer(({
  eventService,
  chatService,
  featureFlagsService: {
    getShowCalculatorMice,
  },
  popupsService: {
    closePopupState,
    store: {
      popupsState: {
        mice_chapter,
        mice_calculator_button,
      },
    },
  },
  aggregationId,
  eventService: {
    getEvents,
    createEvent,
    setStatusesFiltred,
    resetCalculatorRes,
    resetCalculatorResMini,
    calculatorCancelSend,
  },
}: IEventPage) => {
  const { eventStore } = useStores([MOBX_STORES.EVENT_STORE]);

  const isPath = useLocation().pathname.includes(ROUTES.EVENT_CONTEXT);

  const [showPanel, setShowPanel] = useState<boolean>(false);
  const [showApp, setShowApp] = useState<boolean>(isPath);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showCalculator, setShowCalculator] = useState<boolean>(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);

  const frameWrapper = clsx(styles.frame_image, {
    [styles.frame_image_ppr]: aggregationId === AGGREGATORS_ACCOUNT.PPR,
  });

  useEffect(() => {
    getEvents();

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.EVENT.OPEN);

    if (!mice_chapter && getShowCalculatorMice()) {
      closePopupState(POPUP_NAME.MICE_CHAPTER);
    }

    if (!mice_calculator_button && getShowCalculatorMice()) {
      document.body.classList.add(styles.disable_scroll);
    }

    return () => {
      setStatusesFiltred([]);
    };
  }, []);

  useEffect(() => {
    if (!eventStore.loadingEvents) {
      setIsLoading(false);
    }
  }, [eventStore.loadingEvents]);

  const handleAddEvent = (value: TEventValue) => {
    createEvent(value as TEventValue);

    setShowApp(!showApp);

    chatService.show();
  };

  const handleShowPanel = (value: boolean) => {
    if (aggregationId !== AGGREGATORS_ACCOUNT.PPR) {
      setShowPanel(!value);

      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.EVENT.INFO);
    }
  };

  const handleAppPanel = (value: boolean) => {
    setShowApp(!value);

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.EVENT.NEW);
  };

  const handleChangeFilter = (value: any) => {
    setStatusesFiltred(value);

    getEvents();

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.EVENT.FILTER);
  };

  const onOpenAddEvent = (value: boolean) => {
    setShowApp(value);

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.EVENT.CANCEL);
  };

  const addEventForCalculator = (value: boolean) => {
    setShowCalculator(!value);
    onOpenAddEvent(value);

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.CALCULATOR.CREATE_NEW_EVENT);
  };

  const renderModalAddApplication = () => {
    if (!showApp) {
      return null;
    }

    return (
      <AddEvent
        show={ showApp }
        // @ts-ignore
        onAddEvent={ handleAddEvent }
        onClose={ () => onOpenAddEvent(false) }
        resetCalculatorRes={ resetCalculatorRes }
        resetCalculatorResMini={ resetCalculatorResMini }
        calculatorCancelSend={ calculatorCancelSend }
      />
    );
  };

  const renderEvents = () => {
    const { events, errorEvents } = eventStore;

    if (!events.length) {
      return (
        <NoResults
          label={ LABELS.NO_RESULT_LABEL }
          className={ styles.wrapper_no_result }
        />
      );
    }

    if (errorEvents) {
      return (
        <NoResults
          label={ LABELS.ERROR_LOADING }
        />
      );
    }

    return (
      <Events
        events={ events }
        eventService={ eventService }
      />
    );
  };

  const renderPanelMice = () => (
    <SidePanel
      show={ showPanel }
      onClose={ () => setShowPanel(!showPanel) }
    >
      <div className={ styles.wrapper_side }>
        <Text type='bold_24'>
          { LABELS.INFO_MICE }
        </Text>
        <img
          className={ styles.modal_img }
          src={ Modal }
          alt='modal_info'
        />
      </div>
    </SidePanel>
  );

  const renderCalculatorMice = () => {
    if (!showCalculator) {
      return null;
    }

    const onCloseCalculator = async () => {
      setShowCalculator(prev => !prev);

      if (eventStore.calculatorRes.EventEstimate !== 0) {
        calculatorCancelSend(eventStore.calculatorRes);
        resetCalculatorRes();

        MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.CALCULATOR.CLOSE_PRESSED);

        return;
      }

      if (eventStore.calculatorResMini.IsValue) {
        calculatorCancelSend({
          ParticipantsCount: eventStore.calculatorResMini.ParticipantsCount,
          Direction: DIRECTION.FOREIGN_CITY,
          DaysCount: eventStore.calculatorResMini.DaysCount as number,
          Comment: eventStore.calculatorResMini.Comment as string,
        });

        MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.CALCULATOR.CLOSE_PRESSED);
      }
    };

    const onCloseOutside = () => isComplete && onCloseCalculator();

    return (
      <SidePanel
        show={ showCalculator }
        onClose={ onCloseCalculator }
        type='big_60'
        onCloseOutside={ () => onCloseOutside() }
      >
        <CalculatorMice
          isComplete={ isComplete }
          setIsComplete={ setIsComplete }
          eventService={ eventService }
          setShowCalculator={ onCloseCalculator }
          onOpenAddEvent={ addEventForCalculator }
        />
      </SidePanel>
    );
  };

  const renderFiltred = () => {
    const { filtered: { Statuses } } = eventStore;

    return (
      <FiltredEvents
        values={ Statuses }
        onChange={ handleChangeFilter }
        placeholder={ LABELS.PLACEHOLDER_SELECT }
        className={ styles.wrapper_filter }
      />
    );
  };

  const renderCalculator = () => {
    if (!getShowCalculatorMice()) {
      return null;
    }

    const onOpenCalculator = () => {
      if (!mice_calculator_button && getShowCalculatorMice()) {
        closePopupState(POPUP_NAME.MICE_CALCULATOR_BUTTON);
      }

      setShowCalculator((prev) => !prev);

      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.CALCULATOR.OPEN_PRESSED);
    };

    const classname = clsx(styles.wrapper_first_button, {
      [styles.wrapper_first_button_z]: !mice_calculator_button && getShowCalculatorMice(),
    });

    return (
      <Button
        onClick={ onOpenCalculator }
        className={ classname }
      >
        { LABELS.TITLE_CALCULATOR }
      </Button>
    );
  };

  const renderContent = () => {
    const loader = () => (
      <div className={ styles.loader_wrapper }>
        <CircleLoading
          size={ 100 }
        />
      </div>
    );

    return isLoading ? loader() : renderEvents();
  };

  const disabledPanel = () => {
    if (mice_calculator_button || !getShowCalculatorMice()) {
      return null;
    }

    return (
      <div className={ styles.disable_scroll } />
    );
  };

  return (
    <>
      { disabledPanel() }
      <div className={ styles.wrapper }>
        { renderPanelMice() }
        { renderCalculatorMice() }
        { renderModalAddApplication() }
        <div className={ styles.title }>
          <Text type='bold_32'>
            { LABELS.TITLE }
          </Text>
        </div>
        <div className={ styles.image_wrapper }>
          <img
            className={ frameWrapper }
            src={ FrameNew }
            alt='frame'
            onClick={ () => handleShowPanel(showPanel) }
          />
          <img
            className={ styles.card_image }
            src={ Card }
            alt='card'
          />
        </div>
        <div className={ styles.wrapper_list }>
          <Text type='bold_24'>
            { LABELS.APPLICATION_TITLE }
          </Text>
          <div className={ styles.wrapper_select }>
            { renderFiltred() }
            <div className={ styles.wrapper_buttons }>
              { renderCalculator() }
              <Button
                onClick={ () => handleAppPanel(showApp) }
                className={ styles.wrapper_button }
                type='secondary'
              >
                { LABELS.ADD_LABEL }
              </Button>
            </div>
          </div>
          { renderContent() }
        </div>
      </div>
    </>
  );
});

export { EventPage };
