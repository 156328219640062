import { CREATE_EXPENSE_REPORT } from '../../constants/rights';
import {
  STATUS_PAGE,
  LOADINGS_FIELD,
  ApproveStatus,
} from '../../constants/expenseReport';

import { ServiceReturnType } from '../../context/services';
import { ExpenseItem } from '../../services/expenseReports/types';

class ExpenseReports {
  services: ServiceReturnType;

  constructor(services: ServiceReturnType) {
    this.services = services;
  }

  setExpenseReportStatusPage = (data: ExpenseItem | null) => {
    const { UserId } = this.services.Workspace.rights;
    const Status = data?.Status;
    const Approves = data?.Approves;

    const isApprover = Status === ApproveStatus.WAITING_APPROVE &&
      ((!!UserId && !!Approves?.find(aprrove => aprrove.UserId === UserId)) || this.services.Workspace.isAdmin);

    if (isApprover) {
      return this.services.ExpenseReports.setExpenseReportStatusPage(
        STATUS_PAGE.APPROVER,
      );
    }

    if (status !== undefined && status !== null) {
      return this.services.ExpenseReports.setExpenseReportStatusPage(this.services.ExpenseReports.getStatusFromBackend(Status as number));
    }

    return null;
  };

  loadExpenseReport = async (id: number) => {
    this.services.ExpenseReports.setLoadingReport(true, LOADINGS_FIELD.LOADING);

    const createExpenseReports = this.services.Workspace.canCreateExpenseReports;
    const userEmployee = this.services.Workspace.dataUserEmployee;
    const { employeeId } = userEmployee;

    try {
      const res = await this.services.ExpenseReports.getExpenseReport(id);
      const { Items, Status, EmployeeId, DepartmentIds } = res;

      this.services.ExpenseReports.setDepId(DepartmentIds);

      const statusNotApprove = Status === 6 || Status === 8;

      if (statusNotApprove && createExpenseReports === CREATE_EXPENSE_REPORT.ApprovalScheme) {
        await this.services.ExpenseReports.getApproversSteps(EmployeeId, Items);
      }

      if (statusNotApprove && createExpenseReports === CREATE_EXPENSE_REPORT.OnlyAfterApprove) {
        await this.services.ExpenseReports.getApprovers(employeeId);
      }

      this.setExpenseReportStatusPage(res);
      this.services.ExpenseReports.openReport(res);

      return this.services.ExpenseReports.setLoadingReport(
        false,
        LOADINGS_FIELD.LOADING,
      );
    } catch (e) {
      this.setExpenseReportStatusPage(null);
      this.services.ExpenseReports.setLoadingReport(
        false,
        LOADINGS_FIELD.LOADING,
      );

      return Promise.reject();
    }
  };
}

export { ExpenseReports };
