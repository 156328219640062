import React, { Component, JSX } from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown, Icon, Text } from 'new-ui';
import { observer } from 'mobx-react';
import MediaQuery from 'react-responsive';
import clsx from 'clsx';
import { IconColor, IconType } from 'new-ui/src/components/Icon/types';

import { withStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';
import { IUiSettingsStore } from '../../../../bi/services/uiSettings/store';

import ANIMATION from '../../../../bi/constants/animation';
import { DEFAULTMENUTYPE, DEFAULTMENUTYPEE } from '../../../../bi/constants/app';

import { isSmartAgent } from '../../../../bi/utils/env';

import { IExtendedMenuItem, IMenuItems } from '../../../../bi/types/app';

import styles from './styles/index.module.css';

type TIcon = IconType | DEFAULTMENUTYPEE;

type TItemType = Omit<IItemWrapProps, 'getIcon' | 'getIconColor' | 'onClick' | 'getShowElementForSmartagent'>;

interface IItem extends TItemType {
  typeItem?: string,
}

interface IItemWrapProps {
  showBadge?: boolean,
  count?: number,
  href: string,
  label?: JSX.Element | string,
  getIcon(type?: TIcon): TIcon,
  getIconColor(): IconColor,
  items?: IMenuItems[] | IExtendedMenuItem [],
  type?: TIcon,
  mainMenu?: boolean,
  subItem?: boolean,
  className?: string,
  classNameWrapper?: string,
  qaAttr?: string,
  qaAttrCounter?: string,
  qaAttrIcon?: string,
  onClick(value?: string): void,
  getShowElementForSmartagent: boolean,
  stores?: {
    uiSettingsStore?: IUiSettingsStore;
  }
}

interface IItemWrapState {
  animationClass: string,
}

@withStores([MOBX_STORES.UI_SETTINGS])
@observer
class ItemWrap extends Component<IItemWrapProps, IItemWrapState> {
  static defaultProps = {
    subItem: false,
    items: [],
    string: '',
    count: 0,
    getIconColor: () => {},
    className: '',
    classNameWrapper: '',
    qaAttr: '',
    qaAttrCounter: '',
    qaAttrIcon: '',
    getShowElementForSmartagent: false,
  };

  state = {
    animationClass: '',
  };

  componentDidUpdate(prevProps: IItemWrapProps) {
    if (prevProps.count !== this.props.count) {
      this.setState({
        animationClass: ANIMATION.BOUNCEIN,
      });
    }
  }

  handleAnimatedEnd = () => {
    this.setState({
      animationClass: '',
    });
  };

  renderLinkContent = (item: IItem, isNested?: boolean) => {
    const { animationClass } = this.state;
    const { getIcon, getIconColor, qaAttrCounter, qaAttrIcon, getShowElementForSmartagent } = this.props;
    const { label, count, showBadge = false, type, mainMenu } = item;

    const nextClassNames = !isNested ? styles.text : '';
    const textType = mainMenu || (isSmartAgent && getShowElementForSmartagent) ? 'NORMAL_16_140' : 'NORMAL_14';

    const labelResponsive = (
      <MediaQuery minWidth={ 920 }>
        { label }
      </MediaQuery>
    );

    return (
      <>
        <div className={ animationClass } onAnimationEnd={ this.handleAnimatedEnd }>
          <Icon
            qaAttr={ qaAttrIcon }
            qaAttrCounter={ qaAttrCounter }
            showBadge={ showBadge }
            badgeCount={ count }
            className={ styles.icon }
            type={ getIcon(type) as IconType }
            color={ getIconColor() }
          />
        </div>
        <Text className={ nextClassNames } type={ textType }>{ labelResponsive }</Text>
      </>
    );
  };

  renderLinkWrap = (item: IItem, isNested?: boolean) => {
    const { stores, getShowElementForSmartagent } = this.props;
    const reportRedirectUrl = stores?.uiSettingsStore?.settings?.reportRedirectUrl;
    const { href, type, mainMenu, subItem, className } = item;

    const wrapClassNames = clsx(styles.link, {
      [styles.item]: isNested,
      [className as string]: className,
    });

    const activeClassNames = clsx({
      [styles.active]: subItem || mainMenu,
      [styles.subitem]: subItem && !getShowElementForSmartagent && !isSmartAgent,
      [styles.subitem_sa]: subItem && getShowElementForSmartagent && isSmartAgent,
    });

    const activeSmartdesk = clsx(activeClassNames, styles.active_first);

    const content = this.renderLinkContent(item, isNested);

    if (type === DEFAULTMENUTYPE.REPORT && reportRedirectUrl) {
      return (
        <a href={ reportRedirectUrl } className={ wrapClassNames } rel='noreferrer noopener'>
          { content }
        </a>
      );
    }

    if (type === DEFAULTMENUTYPE.SEARCH || type === DEFAULTMENUTYPE.ACCOUNTING || type === DEFAULTMENUTYPE.APPLICATION) {
      return (
        <div className={ wrapClassNames }>
          { content }
        </div>
      );
    }

    if (type === DEFAULTMENUTYPE.SMARTDESC) {
      return (
        <NavLink
          to={ href }
          className={ wrapClassNames }
          activeClassName={ activeSmartdesk }
        >
          { content }
        </NavLink>
      );
    }

    return (
      <NavLink
        to={ href }
        className={ wrapClassNames }
        activeClassName={ activeClassNames }
      >
        { content }
      </NavLink>
    );
  };

  renderItem = (item: IItem, isNested?: boolean) => {
    const { onClick, qaAttr, classNameWrapper } = this.props;
    const { items, typeItem, qaAttr: qaAttrDropDown } = item;

    const itemsContent = () => (
      <Dropdown
        onClick={ () => {} }
        theme={ 'tertiary' }
        renderLabel={ () => this.renderItem({ ...item, items: [] }) }
      >
        <div>
          { items?.map((i) => (
            <div key={ i.type }>
              { this.renderItem({ ...i, href: i.url, label: i.title, items: [], mainMenu: false, typeItem: i.type }, true) }
            </div>),
          ) }
        </div>
      </Dropdown>
    );

    const content = items?.length ? itemsContent() : this.renderLinkWrap(item, isNested);

    return (
      <div
        className={ `${styles.wrapper} ${items?.length ? styles.search : ''} ${classNameWrapper}` }
        onClick={ () => onClick(typeItem) }
        data-qa={ !items?.length ? (qaAttrDropDown || qaAttr) : '' }
      >
        { content }
      </div>
    );
  };

  render() {
    // just to shut eslint
    const { href, label, items, type, mainMenu, subItem, className, showBadge } = this.props;

    return this.renderItem({ ...this.props, href, label, items, type, mainMenu, subItem, className, showBadge });
  }
}

export default ItemWrap;
