import React, { Component, useState, MouseEvent } from 'react';
import { Button, Tooltip, Dialog, Checkbox, Text, IconButton } from 'new-ui';
import { getText } from '../../../../../i18n';

import { sendStatOnDeleteApproveCart } from '../../../../bi/services/cart/approveAnalytic';

import { BookChildDialog } from '../../../../components/BookChildDialog';

import { isBeforeDate } from '../../../../bi/utils/formatDate';
import { isCartHasAirChildren } from '../../../../bi/utils/cart';
import {
  parsedAllItems,
  getFlightRoute,
  getDatesTrip,
  getEmployeeList,
} from '../../../../bi/utils/airline';

import { CART_STATUS } from '../../../../bi/constants/cart';
import { APPROVE_ANALYTIC_ACTION_BY_CART_STATUS, CONSISTENTCARTMODES } from '../../../../bi/constants/approve';
import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import { CartsType, ECartStatus, ICartItemsUpdate, ICartWP } from '../../../../bi/types/cart';
import { CartItemType } from '../../../SpecificCart/types';
import { ISourcesEmployees } from '../../../../bi/services/checkout/types';
import { AirRoute } from '../../../../bi/types/airline';
import { IPrepareNoteItem } from '../../../../bi/services/notepad/types';

import styles from './styles/index.module.css';

interface IActualListItem {
  id: number;
  PNR: string,
  routes: AirRoute[],
  allowedEmployees: ISourcesEmployees[],
  isReserved?: false
}

interface ConsistentCartDeleteButtonProps {
  item: ICartWP<IPrepareNoteItem> | ICartWP | CartsType;
  onDelete(item: ICartWP<IPrepareNoteItem> | ICartWP | CartsType, airToNote: boolean): void;
  mode: string | null;
  type: ECartStatus | keyof typeof APPROVE_ANALYTIC_ACTION_BY_CART_STATUS;
  isDemo?: boolean;
}

interface ConsistentCartDeleteButtonState {
  airToNote: boolean;
  loading: boolean;
  showDialog: boolean;
}

const LABELS = {
  BACK_TO_NOTE_RESERVED_TICKETS: getText('cart:approveStatuses.backToNoteReservedTickets'),
  CONFIRM_ACTION: getText('cart:approveStatuses.confirmAction'),
  DEMO_TOOLTIP: getText('cart:approveStatuses.demoTooltip'),
  SENT_TO_APPROVE: (date: string) => getText('cart:approveStatuses.sentToApprove', { date }),
  SENT_TO_APPROVE_OFFLINE: (date: string) => getText('cart:approveStatuses.sentToApproveOffline', { date }),
  APPROVE_REQUEST_FROM: getText('cart:approveStatuses.approveRequestFrom'),
  SENT: getText('cart:approveStatuses.sent'),
  TRIP_NOT_APPROVED: getText('cart:approveStatuses.tripNotApproved'),
  PAYMENT_TRIP_AFTER_APPROVAL: getText('cart:approveStatuses.paymentTripAfterApproval'),
  APPROVE_STATUS_TEXT: {
    APPROVED: getText('cart:approveStatuses.statuses.approved'),
    DECLINED: getText('cart:approveStatuses.statuses.declined'),
    WAITING_APPROVE: getText('cart:approveStatuses.statuses.waitingApprove'),
    FOUL: getText('cart:approveStatuses.statuses.foul'),
  },
  NAVIGATE_TO_CHECKOUT_LABEL: getText('cart:approveStatuses.navigateToCheckout'),
  SEND_TO_CHAT: getText('cart:approveStatuses.sendToChat'),
  SEND_REQUEST: getText('cart:approveStatuses.sendRequest'),
  UNDERAGE_TOOLTIP: getText('cart:approveStatuses.underageTooltip'),
  MESSAGE_WAS_SENT: getText('cart:approveStatuses.messageWasSentTooltip'),
  TRIP_INFO: (
    route: string,
    dates: string,
    name: string,
    pnr: string,
  ) => getText('note:dialog.tripInfo', { route, dates, name, pnr }),
  REMOVE_INFO: getText('note:dialog.removeInfo'),
  ITEMS_UPDATE: getText('cart:tooltipTexts.itemsUpdate'),
  BUTTON_DATE_CHANGE: getText('cart:normalCart.changes.buttonDateChange'),
};

const ApprovedCartStatusText = () => <Text color='green' type='NORMAL_14'>{LABELS.APPROVE_STATUS_TEXT.APPROVED}</Text>;
const DeclinedCartStatusText = () => <Text color='red' type='NORMAL_14'>{LABELS.APPROVE_STATUS_TEXT.DECLINED}</Text>;
const WaitingApproveCartStatusText = () => (
  <Text color='gray' type='NORMAL_14'>{LABELS.APPROVE_STATUS_TEXT.WAITING_APPROVE}</Text>
);

const ApprovedCartPurchaseBtn = ({
  onClick,
  isOffline,
  alreadySentIds,
  id,
  itemsToUpdate,
  cartHasUpdates,
}: {
  isOffline: boolean,
  alreadySentIds?: number[],
  id: number,
  onClick(e: MouseEvent): void,
  itemsToUpdate?: ICartItemsUpdate[],
  cartHasUpdates: boolean,
}) => {
  let label = LABELS.NAVIGATE_TO_CHECKOUT_LABEL;

  if (isOffline) {
    label = LABELS.SEND_TO_CHAT;
  }

  if (cartHasUpdates) {
    label = LABELS.BUTTON_DATE_CHANGE;
  }

  const isAlreadySent = isOffline && alreadySentIds?.includes(id);

  let tooltipText = LABELS.MESSAGE_WAS_SENT;
  const includesChangedItems = itemsToUpdate && itemsToUpdate.some(({ Changes }) => !!Changes.length);

  if (includesChangedItems) {
    tooltipText = `${tooltipText} ${LABELS.ITEMS_UPDATE}`;
  }

  return (
    <Tooltip
      show={ isAlreadySent }
      renderContent={ () => (
        <div className={ styles['tooltip-content'] }>
          <Text color='white' type='NORMAL_14_130'>{ tooltipText }</Text>
        </div>
      ) }
    >
      <Button
        type='secondary'
        onClick={ onClick }
        qaAttr={ QA_ATTRIBUTES.cart.approval.purchaseButton }
        disabled={ isAlreadySent || includesChangedItems }
      >
        { label }
      </Button>
    </Tooltip>
  );
};

const WaitingApproveCartPurchaseBtn = ({
  isOffline,
  itemsToUpdate,
}: { isOffline: boolean, itemsToUpdate?: ICartItemsUpdate[] }) => {
  if (isOffline) return null;

  let tooltipText = LABELS.PAYMENT_TRIP_AFTER_APPROVAL;

  if (itemsToUpdate && itemsToUpdate.some(({ Changes }) => !!Changes.length)) {
    tooltipText = `${tooltipText} ${LABELS.ITEMS_UPDATE}`;
  }

  return (
    <div className='sw-tooltip-wrapper'>
      <Tooltip renderContent={ () => (
        <div className={ styles['tooltip-content'] }>
          <Text type='NORMAL_14_130' color='white'>{ tooltipText }</Text>
        </div>
      ) }
      >
        <Button
          disabled
          onClick={ () => ({ }) }
        >
          { LABELS.NAVIGATE_TO_CHECKOUT_LABEL }
        </Button>
      </Tooltip>
    </div>
  );
};

const SendCartRequestBtn = ({ onClick }: { onClick(e: MouseEvent): void }) => (
  <Tooltip renderContent={ () => (
    <div className={ styles['tooltip-content'] }>
      <Text type='NORMAL_14_130' color='white'>{ LABELS.UNDERAGE_TOOLTIP }</Text>
    </div>
  ) }
  >
    <Button
      type='secondary'
      onClick={ onClick }
    >
      { LABELS.SEND_REQUEST }
    </Button>
  </Tooltip>
);

const DeclinedCartPurchaseBtn = () => (
  <Text type='NORMAL_14' color='red'>{ LABELS.TRIP_NOT_APPROVED }</Text>
);

export const returnApproveStatusText = (status: typeof CART_STATUS[keyof typeof CART_STATUS]) => {
  const approveStatusComponents = {
    [CART_STATUS.IN_PROGRESS]: WaitingApproveCartStatusText,
    [CART_STATUS.AUTHORISED]: ApprovedCartStatusText,
    [CART_STATUS.REJECTED]: DeclinedCartStatusText,
  };

  const ApproveStatusText = approveStatusComponents[status as keyof typeof approveStatusComponents];

  return (<ApproveStatusText />);
};

export const ReturnPurchaseButton = ({
  type,
  item,
  onPurchase,
  onClick,
  // haveFouls,
  hasUnderage,
  alreadySentIds,
  itemsToUpdate,
}: {
  type: ECartStatus,
  item: CartsType | ICartWP | ICartWP<CartItemType>,
  onPurchase(i: CartsType | ICartWP, isUnderAge?: boolean, val?: boolean): void,
  onClick?(item: CartsType | ICartWP | ICartWP<CartItemType>): void,
  // haveFouls: boolean,
  hasUnderage: boolean,
  alreadySentIds?: number[],
  itemsToUpdate?: ICartItemsUpdate[],
}) => {
  const purchaseBtnComponents = {
    [CART_STATUS.IN_PROGRESS]: WaitingApproveCartPurchaseBtn,
    [CART_STATUS.AUTHORISED]: ApprovedCartPurchaseBtn,
    [CART_STATUS.REJECTED]: DeclinedCartPurchaseBtn,
  };

  const [show, setShow] = useState(false);

  const isUnderage = hasUnderage && type === CART_STATUS.AUTHORISED;
  const cartHasUpdates = item.sources && item.sources.some(({ HasChanges }) => !!HasChanges);

  const handleConfirm = (e: MouseEvent) => {
    e.stopPropagation();
    const hasChildren = isCartHasAirChildren(item.sources);

    if (hasChildren) {
      setShow(true);

      return;
    }

    if (cartHasUpdates && type === ECartStatus.Approved) {
      onClick?.(item);

      return;
    }

    onPurchase(item as CartsType | ICartWP, isUnderage, true);
  };

  const handleContinueConfirm = (e: MouseEvent) => {
    e.stopPropagation();
    setShow(false);
    onPurchase(item as CartsType | ICartWP, isUnderage, true);
  };

  const bookChildDialog = (
    <BookChildDialog
      show={ show }
      onClose={ () => setShow(false) }
      onContinue={ handleContinueConfirm }
    />
  );

  const PurchaseButton = isUnderage
    ? SendCartRequestBtn
    : purchaseBtnComponents[type as keyof typeof purchaseBtnComponents];

  return (
    <>
      <PurchaseButton
        onClick={ handleConfirm }
        // haveFouls={ haveFouls }
        isOffline={ item.isOffline }
        alreadySentIds={ alreadySentIds }
        id={ item.id }
        itemsToUpdate={ itemsToUpdate }
        cartHasUpdates={ cartHasUpdates }
      />
      {bookChildDialog}
    </>
  );
};

const deleteButtonLabels = {
  Approved: getText('cart:approveStatuses.deleteButtonLabels.approved'),
  Declined: getText('cart:approveStatuses.deleteButtonLabels.declined'),
  WaitingApprove: getText('cart:approveStatuses.deleteButtonLabels.waitingApprove'),
  ApproverMode: getText('cart:approveStatuses.deleteButtonLabels.approvedMode'),
};

export class ConsistentCartDeleteButton extends Component<ConsistentCartDeleteButtonProps, ConsistentCartDeleteButtonState> {
  static defaultProps = {
    mode: CONSISTENTCARTMODES.VIEWER,
    isDemo: false,
  };

  state = {
    showDialog: false,
    airToNote: true,
    loading: false,
  };

  setModal = (value: boolean) => {
    this.setState({ showDialog: value });
  };

  handleDelete = (e: MouseEvent) => {
    const { item, onDelete, mode, type } = this.props;
    const { airToNote } = this.state;

    this.setState({ loading: true });

    e.stopPropagation();
    sendStatOnDeleteApproveCart(mode as string, type as keyof typeof APPROVE_ANALYTIC_ACTION_BY_CART_STATUS);

    onDelete(item as ICartWP, airToNote);
  };

  handleChangeAirToNote = (value: boolean) => this.setState({ airToNote: value });

  renderItems = (list: IActualListItem[] | null) => {
    const { airToNote } = this.state;

    if (airToNote || !list) return null;

    const listItemsHtml = list.map((source) => {
      const { id, isReserved, routes, allowedEmployees, PNR } = source;

      if (!isReserved) return null;

      return (
        <div key={ id }>
          <Text className={ styles.text }>
            { LABELS.TRIP_INFO(
              getFlightRoute(routes),
              getDatesTrip(routes),
              getEmployeeList(allowedEmployees),
              PNR,
            )}
          </Text>
        </div>
      );
    });

    return (
      <>
        { listItemsHtml }
        <Text className={ styles.text }>
          { LABELS.REMOVE_INFO }
        </Text>
      </>
    );
  };

  render() {
    const { type, mode, isDemo, item: { Items, sources, ServerTime, serverTime } } = this.props;
    const { showDialog, airToNote, loading } = this.state;

    const buttonLabel = mode === CONSISTENTCARTMODES.VIEWER
      ? deleteButtonLabels[type as keyof typeof deleteButtonLabels]
      : deleteButtonLabels.ApproverMode;

    const list = Items || sources;
    const sTime = ServerTime || serverTime;

    const actualListItem = parsedAllItems(list as IPrepareNoteItem[], true, false);

    const moveAirToNoteContent = list.some(({ ServiceType, IsReserved, BookDeadline }) =>
      ServiceType === SERVICETYPE.AIR && IsReserved && isBeforeDate(sTime, BookDeadline)) && (
      <div className={ styles['air-to-note'] }>
        <Checkbox onChange={ this.handleChangeAirToNote } value={ airToNote }>
          { LABELS.BACK_TO_NOTE_RESERVED_TICKETS }
        </Checkbox>
        <div className={ styles['item-info'] }>
          { this.renderItems(actualListItem) }
        </div>
      </div>
    );

    return (
      <>
        <Tooltip
          show={ isDemo }
          renderContent={ () => (
            <div className={ styles['tooltip-content'] }>
              <Text color='white' type='NORMAL_14_130'>{ LABELS.DEMO_TOOLTIP }</Text>
            </div>
          ) }
        >
          <IconButton
            disabled={ isDemo }
            iconType='trashcan'
            iconColor='blue'
            className={ styles.delete }
            onClick={ () => this.setModal(true) }
            qaAttr={ QA_ATTRIBUTES.cart.approval.cancel.button }
          >
            <Text type='NORMAL_14'>
              { buttonLabel }
            </Text>
          </IconButton>
        </Tooltip>
        <Dialog
          show={ showDialog }
          onChange={ this.setModal }
          showClosing
        >
          <div className={ styles['dialog-content'] }>
            <Text
              type='bold_20'
              className={ styles.header }
              qaAttr={ QA_ATTRIBUTES.cart.approval.cancel.dialog.header }
            >
              { LABELS.CONFIRM_ACTION }
            </Text>
            { moveAirToNoteContent }
            <div className={ styles.actions }>
              <Button
                onClick={ this.handleDelete }
                loading={ loading }
                qaAttr={ QA_ATTRIBUTES.cart.approval.cancel.dialog.button }
              >
                { buttonLabel }
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}

export const ApproveStatusListItem = (
  {
    email,
    name,
    date,
    resolution,
    showApproversCKR,
    isOffline,
  }: {
    email: string,
    name: string | null,
    date: string,
    resolution: typeof CART_STATUS[keyof typeof CART_STATUS];
    showApproversCKR: boolean,
    isOffline: boolean,
  },
) => {
  const preparedEmail = () => {
    if (showApproversCKR) {
      return <Text type='NORMAL_14' color='accent' className={ styles.name }>{ email }</Text>;
    }

    if (email) {
      return (
        <Tooltip renderContent={ () => (
          <div className={ styles['tooltip-content'] }>
            <Text color='white' type='NORMAL_14_130'>{ email }</Text>
          </div>
        ) }
        >
          <Text type='NORMAL_14' color='accent' className={ styles.name }>{ name }</Text>
        </Tooltip>
      );
    }

    return (
      <Text type='NORMAL_14' color='accent' className={ styles.name }>{ name }</Text>
    );
  };
  const labelSentToApprove = !isOffline ?
    LABELS.SENT_TO_APPROVE(date) :
    LABELS.SENT_TO_APPROVE_OFFLINE(date);

  return (
    <Text className={ styles['approve-list-item'] } type='NORMAL_14'>
      { labelSentToApprove }&nbsp;
      { preparedEmail() }
      &nbsp;
      —
      &nbsp;
      { returnApproveStatusText(resolution) }
    </Text>
  );
};
