import { CARD_FORM_ERRORS } from '../utils/paymentMethods';

type PaymentMethodTypes = 'CA' | 'CP' | 'PA';

enum CommissionType {
  sbp = 'sbp',
  card = 'bankcard',
}

const PAYMENT_METHODS: Record<string, PaymentMethodTypes> = {
  ORGANIZATION_ACCOUNT: 'CA',
  EMPLOYEE_CARD: 'CP',
  EMPLOYEE_PERSONAL_ACCOUNT: 'PA',
};

export enum PaymentMethodType {
  organizationAccount = 'CA',
  employeeCard = 'CP',
  employeePersonalAccount = 'PA',
}

interface ReceiptItem {
  label: string,
  price: number,
  quantity: number,
  vat: string,
  amount: number,
}

interface CustomerReceipt {
  Items: ReceiptItem[],
  Amounts: { Electronic: number },
}

interface CloudPayments {
  CustomerReceipt: CustomerReceipt,
}

interface PAYMENT_INFO {
  accountId: string,
  amount: number,
  data: {
    type: string,
    CloudPayments?: CloudPayments,
    commission?: number,
    email?: string,
  },
  email?: string,
  disableEmail?: boolean,
  description: string,
  invoiceId?: null | string,
  onFail?: () => void,
  onSuccess?: () => void,
}

type ERRORS_CARD_FORM = Record<string, keyof typeof CARD_FORM_ERRORS>;

export { PAYMENT_METHODS, CommissionType };
export type {
  PaymentMethodTypes,
  ERRORS_CARD_FORM,
  PAYMENT_INFO,
  CustomerReceipt,
  ReceiptItem,
};
