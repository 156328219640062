import Api from '../../api';

import { CloudPaymentsStore } from './store';

import { openCloudPaymentsForm } from '../../utils/cloudPayments';
import { formatDate } from '../../utils/formatDate';

const MAINDATEFORMAT = 'YYYY-MM-DDTHH:mm:ss';

const STATUSWIDGETCLOSEDBYUSER = 'User has cancelled';

interface IFailMD {
  data: {
    companyId: number,
    cloudPayments: {
      customerReceipt: {
        Items: Record<string, any> & {
          price: number;
        },
      },
    },
  },
}

class Payment {
  store = CloudPaymentsStore;
  api: Api['payment'];

  constructor(api: Api) {
    this.api = api.payment;
  }

  startCloudPayments = (
    amount: number,
    transactionPercent: number,
    amountOnAccount: number,
    isChargeTransactionFee: boolean,
    email: string,
    customerInfo: string | undefined,
    customerInn: string | undefined,
    companyId: number | string | undefined,
  ) => {
    this.store.setCantLeave(true);

    return openCloudPaymentsForm(
      amount,
      transactionPercent,
      amountOnAccount,
      isChargeTransactionFee,
      email,
      customerInfo as string,
      customerInn as string,
      companyId as string,
      this.onSuccessCloudPayments,
      this.onFailCloudPayments,
    );
  };

  onSuccessCloudPayments = () => {
    this.store.setCantLeave(false);
  };

  onFailCloudPayments = (reason: string, amount: number, {
    data: {
      companyId,
      cloudPayments: {
        customerReceipt: {
          Items,
        },
      },
    },
  }: IFailMD) => {
    const { price } = Items[1] || { price: 0 };

    if (reason === STATUSWIDGETCLOSEDBYUSER) {
      this.store.setCantLeave(false);

      return;
    }

    const params = {
      Amount: amount,
      TransactionFee: price,
      CreatedDate: formatDate(Date.now(), MAINDATEFORMAT),
      Status: {
        Code: false,
        Message: reason,
      },
    };

    this.api.sendResult(companyId, params).then(() => this.store.setCantLeave(false));
  };
}

export default Payment;
