// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { Text, PROPS, Select } from 'new-ui';
import { getText } from '../../../i18n';

import { getAirlineType } from '../../bi/utils/airline';
import parseJsonString from '../../bi/utils/parseJsonString';
import { SERVICETYPE } from '../../bi/constants/serviceType';
import { AIRLINENAMES } from '../../bi/constants/airline';
import { COMPANY_DEPARTMENT_DIVIDER } from '../../bi/constants/cart';
import PASSPORTS from '../../bi/constants/passport';
import COUNTRIES from '../../bi/constants/countries';
import { getEmployeeFullName, isSameNames } from '../../bi/utils/employees';
import { formatDate } from '../../bi/utils/formatDate';

import EmployeeBonusCard from '../EmployeeBonusCard';

import styles from './styles.module.css';

const LABELS = {
  BIRTHDAY: getText('components:employeeSimpleList.birthday'),
  PASSPORT_TYPE_UNDEFINED: getText('components:employeeSimpleList.passportTypeUndefined'),
  COST_CENTER: getText('components:employeeSimpleList.costCenter'),
};

const {
  TEXT: {
    COLORS: {
      GRAY,
    },
    TYPES: {
      NORMAL_14,
      SEMIBOLD_16,
    },
  },
} = PROPS;

const handleCheckAirlineName = (name, itemData) => {
  const dataSource = parseJsonString(itemData);

  if (!dataSource && !dataSource.Routes[0] && !dataSource.Routes[0].Segments[0]) return false;

  const { Routes } = dataSource;
  const airlineType = getAirlineType(Routes[0].Segments[0]);

  return Routes[0].Segments[0][airlineType].Name === name;
};

const renderEmployeeAge = (item, birthday, cartEmployeeBirthday) => {
  if ((item.IsReserved && item.ServiceType === SERVICETYPE.AIR && birthday && handleCheckAirlineName(AIRLINENAMES.POBEDA, item.Data)) || cartEmployeeBirthday) {
    return (
      <Text className={ styles['employee-age'] } type={ NORMAL_14 } color={ GRAY }>
        { LABELS.BIRTHDAY } { birthday }
      </Text>
    );
  }

  return null;
};

class EmployeeSimpleList extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    allowedEmployees: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
    documentType: PropTypes.object.isRequired,
    isPersonalPaymentMethod: PropTypes.bool,
    isMulti: PropTypes.bool.isRequired,
    cartEmployeeBirthday: PropTypes.bool,
    isPersonalBonusCards: PropTypes.bool.isRequired,
    onAddCompany: PropTypes.func,
    onSelectCompany: PropTypes.func,
    readOnlyCompany: PropTypes.bool,
    index: PropTypes.oneOfType([
      PropTypes.oneOf([null]),
      PropTypes.number,
    ]),
    additionalInfo: PropTypes.node,
  };

  static defaultProps = {
    cartEmployeeBirthday: false,
    readOnlyCompany: false,
    onAddCompany: () => {},
    onSelectCompany: () => {},
    index: null,
    additionalInfo: null,
  };

  prepareDocumentType = (type, code) => {
    const { documentType } = this.props;

    switch (type) {
      case documentType.DomesticPassport: {
        return code === COUNTRIES.RU.SHORTNAME ? PASSPORTS.RU_PASSPORT.VALUE : PASSPORTS.FOREIGNER_DOMESTIC_PASSPORT.VALUE;
      }
      case documentType.ForeignPassport: {
        return PASSPORTS.FOREIGN_PASSPORT.VALUE;
      }
      case documentType.BirthCertificate: {
        return PASSPORTS.BIRTH_CERTIFICATE.VALUE;
      }
      default: {
        return LABELS.PASSPORT_TYPE_UNDEFINED;
      }
    }
  };

  prepareDocumentNumber = (docNumber, isRuPassport) => {
    if (isRuPassport) {
      return `${docNumber.substring(0, 2)} ${docNumber.substring(2, 4)} ${docNumber.substring(4)}`;
    }

    return docNumber;
  };

  renderDocument = (Employee, DocumentId) => {
    const { item: { ServiceType }, readOnlyCompany } = this.props;
    const { Documents, Code } = Employee;

    const showDocument = ServiceType === SERVICETYPE.AIR
      || ServiceType === SERVICETYPE.TRAIN
      || ServiceType === SERVICETYPE.AEROEXPRESS;

    if (!showDocument) {
      return null;
    }

    const currentDocument = Documents.find(document => document.Id === DocumentId);

    if (!currentDocument) {
      return null;
    }

    const { Type, Number } = currentDocument;

    const type = this.prepareDocumentType(Type, Code);

    const isRuPassport = type === PASSPORTS.RU_PASSPORT.VALUE;
    const showFullName = isRuPassport && !isSameNames(currentDocument, Employee);

    const name = showFullName ? ` ${getEmployeeFullName(currentDocument)}` : '';

    const documentNumber = this.prepareDocumentNumber(Number, isRuPassport);

    const passport = `${type}${name}, \n ${documentNumber}`;

    const passportClassNames = [];

    if (readOnlyCompany) {
      passportClassNames.push(styles.passport);
    }

    return (
      <div className={ passportClassNames.join(' ') }>
        <Text color={ GRAY } className={ styles.item }>{ passport }</Text>
      </div>
    );
  };

  renderProject = (projectId) => {
    const { projects, isPersonalPaymentMethod } = this.props;
    const project = projects.find(({ Id }) => Id === projectId);

    if (isPersonalPaymentMethod || !project) {
      return null;
    }

    return (
      <Text
        className={ styles.item }
        color={ GRAY }
      >
        { LABELS.COST_CENTER } — { project.Name }
      </Text>
    );
  };

  handleChangeCompany = (value) => {
    const { onAddCompany, onSelectCompany, item } = this.props;

    onAddCompany(item.Employees[0].Employee.Id, value);
    onSelectCompany();
  };

  renderCompany = ({ Companies }, companyId, DepartmentId) => {
    const { isPersonalPaymentMethod, isMulti, readOnlyCompany } = this.props;

    if (isPersonalPaymentMethod) {
      return null;
    }

    const list = Companies.reduce((r, { CompanyId, Departments = [], ShortCompanyName, CompanyName }) => {
      if (Departments.length) {
        return [...r, ...Departments.map(({ Id: departmentId, Name }) =>
          ({ value: `${CompanyId}${COMPANY_DEPARTMENT_DIVIDER}${departmentId}`, label: `${ShortCompanyName || CompanyName} (${Name})` }),
        )];
      }

      return [...r, { value: CompanyId, label: ShortCompanyName || CompanyName }];
    }, []);

    if (!isMulti && !DepartmentId) {
      return null;
    }

    const currentCompany = Companies.find(company => company.CompanyId === companyId);

    if (!currentCompany) {
      return null;
    }

    const { CompanyName, ShortCompanyName, Departments } = currentCompany;

    const department = DepartmentId ? Departments.find(({ Id }) => Id === DepartmentId) : null;

    const text = department ? `${ShortCompanyName || CompanyName} (${department.Name})` : `${ShortCompanyName || CompanyName}`;
    const valueCompany = DepartmentId ? `${companyId}--${DepartmentId}` : companyId;

    if (list.length < 2 || readOnlyCompany) {
      return (
        <Text color={ GRAY } className={ styles.item }>
          { text }
        </Text>
      );
    }

    return (
      <div className={ styles.company }>
        <Select
          placeholder={ LABELS.SELECT_DEPARTMENT }
          excludeValue
          value={ valueCompany }
          items={ list }
          onChange={ this.handleChangeCompany }
          className={ styles.select }
        />
      </div>
    );
  };

  renderBonusCard = (bonuses) => {
    const {
      isPersonalBonusCards,
      item: { Data, ServiceType },
    } = this.props;

    if (!isPersonalBonusCards) {
      return null;
    }

    const itemData = parseJsonString(Data);

    return (
      <EmployeeBonusCard
        routes={ itemData.Routes }
        Bonuses={ bonuses?.Employee?.Bonuses }
        ServiceType={ ServiceType }
      />
    );
  };

  render() {
    const {
      item,
      allowedEmployees,
      cartEmployeeBirthday,
      index,
      additionalInfo,
    } = this.props;

    return item.EmployeeCartItems.map(({ EmployeeId, DocumentId, CompanyId, ProjectId, DepartmentId }, ind) => {
      const { Employee } = allowedEmployees.find(({ Employee: { Id } }) => Id === EmployeeId);
      const { Birthday, Companies } = Employee;

      const birthday = formatDate(Birthday);
      const employeeAgeHtml = renderEmployeeAge(item, birthday, cartEmployeeBirthday);
      const employeeName = getEmployeeFullName(Employee);

      const employeeNumber = index || ind + 1;

      return (
        <div key={ `allowed-employees__${ind}` } className={ styles.wrapper }>
          <div className={ `${styles.name} ${styles.item}` }>
            <Text className={ styles.number }>{ employeeNumber }.</Text>
            <div>
              <Text type={ SEMIBOLD_16 }>{ employeeName }{ employeeAgeHtml }</Text>
              {additionalInfo}
              { this.renderBonusCard(item.Employees[ind]) }
            </div>
          </div>
          { this.renderDocument(Employee, DocumentId) }
          { this.renderCompany({ Companies }, CompanyId, DepartmentId) }
          { this.renderProject(ProjectId) }
        </div>
      );
    });
  }
}

export default EmployeeSimpleList;
