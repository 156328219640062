import { Ref } from 'react';

import { Moment } from 'moment';

import {
  ITravelPolicyListItem,
  ITravelPolicy,
  AccountTravelPolicy,
} from './travelPolicy';
import { Rights, WorkspaceService } from './workspace';
import { TripTag } from '../utils/airlineMultisearch';

import AppService from '../services/app';
import FeatureFlags from '../services/featureFlags';
import { IItemNumbers } from '../services/airline/types';
import { IAirItemRoute } from '../utils/changeAirTrip/types';
import { Filter } from '../../page/AirlineResult/components/types';

interface TransferAirportsRoutes {
  Segments: [
    {
      DepartureAirport: {
        ID: string,
        Name: string
      },
      ArrivalAirport: {
        ID: string,
        Name: string
      },
      ArrivalCity: string,
      ChangeDuration: number
    },
  ]
}

interface TransferAirports {
  Id: number,
  Routes: TransferAirportsRoutes[],
}

interface AirlineService {
  setSearchValue(field: string, value: any): void;
  searchAirTripChange(airlineCode: string, settings: AirSearchSettings, fareName: string | undefined): string;
  getSearch(): AirSearchStore;
  searchByGuid(guid: string, settings: AirSearchSettings): void;
  getResult(): AirlineResultData;
  subscribeResult(cb: any): void;
  setResultValue(field: string, value: any): void;
  setDefaultSortValue(): void;
  setSkeletonSearch(value: boolean): void,
  subscribeSearch: any;
  setNewSearch(): void;
  addSearchRoute(): void;
}

interface AirSearchSettings {
  rightsBuyTrip: Rights,
  travelPolicyAllList: ITravelPolicyListItem[],
  accountTravelPolicy: AccountTravelPolicy,
}

interface AirSearchStore {
  textTemplateWarning: string,
  isValid: boolean,
  isComplex: boolean,
  isDirect: boolean,
  travellers: number,
  flightClass: string,
  routes: AirSearchRoute[],
  isTravelApproval: boolean,
  schemeLoading: boolean,
}

interface ISearchAirItem {
  Class: string,
  Routes: AirRoute[],
  IsDirect: boolean,
}

interface IResultSearch {
  date_from: string,
  t_count: number,
  t_class: string,
  direct: boolean,
  sp_code: string,
  sp_name: string,
  ep_code: string,
  ep_name: string,
  airline_from: string,
  date_to?: string,
  sp_code_back?: string,
  sp_name_back?: string,
  ep_code_back?: string,
  ep_name_back?: string,
  airline_to?: string,
  f_number?: string,
}

interface AirlineResultData {
  favorite: IFavorite | IFavoriteInfo,
  filters: AirFilters | Filter,
  cacheItems: AirlineCacheItem[],
  isAnyFavorite: boolean,
  items: AirlineSource[] | AirlineSourceWithOriginalPrice[],
  loading: boolean,
  paging: AirPaging,
  routeInfo: AirRouteInfo[],
  sortBy: string,
  sourceId: null | string,
  sources: AirlineSource[],
  tags: AirTag[],
  travelPolicyAllList: ITravelPolicyListItem[],
  numberOf: number,
  unavailableTravelPolicy: boolean,
}

interface AirTag {
  filter: string,
  key: string,
  name: string | undefined,
  readOnly?: boolean,
  qaAttr?: string,
}

interface IFavorite {
  Hash: string,
  Id: string,
  Item: {
    Class: string,
    Duration: number,
    Id: number,
    IsDirect: boolean,
    LastTripDate: string,
    Routes: AirRoute[],
  },
  Type: string,
}

interface IFavoriteInfo {
  numbers: string[];
  dates: {
    from: Moment;
    to: Moment;
  };
  airline: {
    from: Moment;
    to: Moment;
  };
}

interface AirRouteInfo {
  arrivalAirport: {
    ID: string,
    Name: string,
  },
  arrivalTime: number,
  arrivalTimeSource: Moment,
  departureAirport: {
    ID: string,
    Name: string,
  },
  departureTime: number,
  departureTimeSource: Moment,
  from: string,
  to: string,
}

export interface IRoutes {
  Routes: AirRoute[],
}

interface AirlineCacheItem {
  AFlag: boolean,
  AvailablCustomFares: boolean,
  ChangeDuration: number,
  Duration: number,
  Fares: AirlineFare[],
  FavoriteId: string | null,
  FlightsNumbers: IItemNumbers,
  Id: number,
  LastTripDate: string,
  OriginalTrips: AirlineSource[],
  Price: AirPrice,
  Routes: AirRoute[],
  TravelPolicy: ITravelPolicy,
}

interface AirlineSource extends AirlineCacheItem {
  Metadata: Metadata,
  SearchType: number,
  ProviderName: string,
  ProviderOfficeId?: string,
  Tags: TripTag[],
}

interface AirlineSourceWithOriginalPrice extends AirlineSource {
  OriginalPrice: AirPriceWithAgentFee;
}

interface Metadata {
  Class: string,
  Fare: AirlineFare,
  TravellersCount: string,
  TicketsExtended: AirTicket[],
}

interface AirlineCacheItemWithRouteInterface extends Omit<AirlineCacheItem, 'Routes' | 'Fares'> {
  Routes: IAirItemRoute[];
  Tags: TripTag[];
  Fares: AirlineFareWithNewPrice[]
}

interface AirlineTravelPolicyInterface {
  TravelPolicy: ITravelPolicy,
}

interface AirPaging {
  count: number,
  total: number,
  current: number,
}

export interface ICountAndComplex {
  count: number,
  isComplex: boolean,
}

interface IRefundable {
  [key: string]: boolean;
}

interface AirFilters {
  airlines: AirlinesFilters,
  airports: AirportsFilter[],
  airlinesDictionary?: { [key: string]: string },
  baggage: IBagageFilter,
  directCount: Record<string, boolean | undefined>,
  directTime: AirTime,
  favoriteId?: boolean,
  fewAirlineCompanies?: boolean,
  flightNumber: string[],
  flightsNumbers: string,
  onlyAirline: boolean,
  price: AirTime,
  refundable: IRefundable,
  routesBorderTime: RoutesBorderTime[],
  routesSelected: IRouteSelected,
  routesTravelTime: RoutesTravelTime[],
  selectedTravelPolicy: string,
  transferAirports: Record<string, FilterName>,
  travelPolicyList: string[],
}

interface IRouteSelected {
  airline: string[],
  arrival: number[],
  flight: string[],
  departure: number[],
}

interface IBagageFilter {
  [key: string]: boolean,
}

interface RoutesBorderTime {
  arrival: RouteTime,
  departure: RouteTime
}

interface RoutesTravelTime {
  duration: RouteTime,
}

interface RouteTime {
  border: {
    from: number,
    to: number,
  },
  from: number,
  to: number,
}

interface AirportsFilter {
  from: Record<string, FilterName>,
  to: Record<string, FilterName>
}

interface PartialAirportsFilter extends Partial<AirportsFilter> {}

interface FilterName {
  name: string,
  selected: boolean,
}

interface GenericAirlinesFilters<T> {
  name: T,
  selected: boolean,
}

type AirFilterType = keyof AirFilters;

type AirlinesFilterKeys = '5N' | 'DP' | 'FV' | 'N4' | 'S7' | 'SU' | 'TK' | 'U6' | 'UT';

type AirlinesFilterNames = 'Smartavia' | 'Победа' | 'ГТК Россия' | 'Nordwind Airlines' | 'S7' | 'Аэрофлот' | 'Turkish Airlines' | 'Уральские авиалинии' | 'ЮТэйр';

type AirlinesFilters = Record<AirlinesFilterKeys, GenericAirlinesFilters<AirlinesFilterNames>>;

interface AirTime {
  border: {
    from: number,
    to: number,
  },
  from: number,
  roundBy: number,
  to: number,
}

interface IAction {
  type: string;
  payload: {
    filter: AirFilterType;
    [key: string]: any;
  };
  filters: AirFilters,
  favorite: IFavorite,
}

interface AirlineJsonData {
  AdditionalServices: string[],
  CheckinDate: string,
  CheckoutDate: string,
  Class: string,
  Fare: null | AirlineFare,
  FlightCertificate: boolean,
  PartnerOrderId: string,
  PriceDetails: AirPriceDetails,
  ProviderName: string,
  ReservationDate: string,
  Routes: AirRoute[],
  Tickets: string[],
  TicketsExtended: AirTicket[]
  Travellers: any,
  VatDetails: AirVatDetails[],
  VoucherTravellers: AirVoucherTravellers[],
}

interface AirPrice {
  Base: number,
  Commission: number,
  Fee: number,
  Tax: number,
  Taxes: number,
  TotalPrice: number,
  VAT: number,
}

interface AirPriceWithAgentFee extends AirPrice {
  AgentFee?: number | string;
}

interface AirPriceDetails extends AirPrice {
  BasePrice: number,
  EarlyCheckIn: number,
  Total: number,
  HasVAT: boolean,
  LateCheckOut: number,
}

export interface AirTicket {
  Num: string,
  PNR: string,
  PNRLocator: string,
  PNRProvider: string,
  PriceDetails: AirPrice,
  Segments: string,
  Status: any | null,
  Travellers: string,
  VoidTimeLimitUTC: number,
  VoidUntilEndOfDay: boolean,
}

interface AirVatDetails {
  Amount: number,
  BaseVat: number,
  Id: number,
  OperationDetailsId: number,
  Rate: number,
}

interface AirVoucherTravellers {
  FirstName: string,
  LastName: string,
  MobilePhone: string,
  Name: string,
  Patronymic: string,
  Surname: string,
  ID: any | null,
}

interface AirlineType {
  Code: string,
  Name: string,
  ID: string,
}

interface OperatingAirlineType {
  ID: string;
  Code: string;
  Name: string;
}

interface MarketingAirlineType {
  ID: string;
  Code: string;
  Name: string;
}

interface AirportType extends AirlineType {
  CityCode: string,
  City: string;
  Country: string;
  ID: string;
}

interface IProviderInfo {
  ProviderOfferId: string;
  ProviderOfferItemId: string;
}

interface IServiceAdditionalInfo {
  Number: string,
  Price: number,
  Comission: number,
  Vat10: number,
  Description?: string,
  ProviderDescription?: string,
  ProviderInfo?: IProviderInfo,
}

interface AdditionaService {
  EmdNumber: string | null;
  Id: number;
  ProviderName: string | null;
  SegmentId: number;
  Service: IServiceAdditionalInfo;
  TravellerId: number;
  Type: number;
}

interface ISelectedBaggageItem {
  ticketId: number,
  employeeId: number,
  routeId: string,
  price: number,
  routes: AirRoute[],
  providerDescription: string,
  isInitial?: boolean,
}

interface ITechnicalStop {
  Details: IStopInfo[];
}

interface IAirAdditionalServicesSegment {
  Airline: AirlineType;
  ArrivalAirport: AirportType;
  ArrivalDate: string;
  DepartureAirport: AirportType;
  DepartureDate: string;
  Id: number;
}

interface AirSegment {
  AdditionalServices: AdditionaService[];
  AircraftName: string;
  Airline: AirlineType;
  ArrivalAirport: AirportType;
  DepartureAirport: AirportType;
  DepartureCityCode?: string;
  ArrivalCity: string;
  ArrivalCountry: string;
  ArrivalDate: string;
  ArrivalTime_DateTime: string;
  ArrivalTerminal: string;
  Baggage: string;
  Class: string;
  ChangeDuration: number;
  DepartureCity: string;
  DepartureCountry: string;
  DepartureDate: string;
  DepartureTime_DateTime: string;
  DepartureTerminal: string;
  Direction: string;
  FlightNumber: string;
  ID: number;
  MarketingAirline: MarketingAirlineType;
  OperatingAirline: OperatingAirlineType;
  SubClassExtended: string;
  TechnicalStop: ITechnicalStop[] | null;
  ServiceStops?: string[];
  DepartureTime: number | string;
  ArrivalTime: number | string;
  ArrivalCityCode?: string,
  ArrivalCountryCode?: string,
  AvailableSeats?: number,
  DepartureCountryCode?: string,
}

interface AirSegmentWithRouteId extends AirSegment {
  RouteId: number | string;
}

interface IStopInfo {
  DateQualifier: string,
  Date: string,
  FirstTime: string,
  EquipmentTime: string,
  LocationId: string,
}

interface AirRoute {
  ID: string | number;
  Duration: number,
  Date: string,
  DirectCount: number,
  Selected: boolean,
  Arrival: {
    Code: string,
    Name: string,
  },
  Departure: {
    Code: string,
    Name: string,
  },
  Segments: AirSegment[],
}

interface AirTerminal {
  terminal: string;
  different: boolean;
}

interface AirSearchRouteFromTo {
  label: string,
  loading?: boolean;
  selected: IAirSearchRouteItem | IAirSearchRouteItemMin | null,
  suggestions: IAirSearchRouteItem[],
}

interface AirSearchRoute {
  date: Moment,
  dateBack: Moment | string,
  from: AirSearchRouteFromTo,
  minDate: Moment,
  to: AirSearchRouteFromTo,
}

type TicketConditionKey = 'Included' | 'NotOffered' | 'Charge';

export interface Conditions {
  [key: string]: TicketConditionKey;
}

interface AirlineFare {
  AdditionalServices: Record<string, string>,
  AirlineBonusInformation: string,
  BaggageIncludedWithCondition: Conditions,
  BaggagePlaces: string,
  Bonus: number,
  CanBuyBaggage: string,
  CanRegistrationSeat: string,
  CanUpgradeRate: string,
  CarryonPlaces: string,
  ChangeTicketWithCondition: Conditions,
  Class: string,
  FlightToken: string,
  HaveInternetAccess: string,
  Id: string,
  InternalId: string,
  IsBaggageIncluded: string,
  IsBusinessHallIncluded: string,
  IsCarryonIncluded: string,
  IsDefault: boolean,
  IsPriorityBaggageReception: string,
  IsPriorityRegistration: string,
  IsTicketChangeable: string,
  IsTicketChangeableAfterDeparture: string,
  IsTicketRefundable: string,
  IsTicketRefundableAfterDeparture: string,
  Name: string,
  Note: string,
  RemarkToken: string,
  SeatDescription: string,
  RefundTicketWithCondition: Conditions,
  Tags: string[],
  Price: AirPriceWithAgentFee,
  TravelPolicy: ITravelPolicy,
  AgentFee?: number,
}

export interface AirlineFareWithNewPrice extends Omit<AirlineFare, 'Price'> {
  Price: AirPriceWithAgentFee;
}

export interface AirlineMatchParams {
  tripId: string,
  ticketId: string,
  guid: string,
  personId: string,
}

interface AirlineMatch {
  params: AirlineMatchParams,
  path: string,
}

interface IAirSearchRouteItem {
  ChildLocation: IAirSearchRouteItem[] | null,
  City: string,
  Code: string,
  Country: string,
  Latitude: number,
  LocationID: number,
  Longitude: number,
  Name: string,
  ParentLocationID: number,
  items: IAirSearchRouteItem[],
  value: number,
  label: string,
}

interface IAirSearchRouteItemMin {
  Code: string,
  City: string
}

interface IMapRoute {
  StartDate: Moment,
  EndDate: Moment,
  Destinations: IAirSearchRouteItemMin | null,
  minDate: Moment,
}

type TAirSearchFocusItem = (focusItem: IAirSearchRouteItem) => void;

type TSearchRouteOptsWithoutClear = Omit<IAirSearchRouteOptsField, 'onClear'>;

interface IAirSearchRouteOptsField {
  items: IAirSearchRouteItem[],
  loading: boolean,
  onChange: (value: string) => void,
  onClear: () => void,
  onSelect: TAirSearchFocusItem,
  placeholder: string,
  ref: Ref<HTMLInputElement>,
  value: string,
}

interface IAirSearchRouteOpts {
  from: IAirSearchRouteOptsField | TSearchRouteOptsWithoutClear,
  to: IAirSearchRouteOptsField | TSearchRouteOptsWithoutClear,
}

interface RoutesHistory {
  ArrivalCode: string,
  ArrivalName: string,
  ArrivalPlace: string,
  Date: string,
  DepartureCode: string,
  DepartureName: string,
  DeparturePlace: string,
}

interface IDetail {
  Class: string,
  Routes: RoutesHistory[],
  IsDirect: boolean,
  TravellersCount: number,
}

interface AirlineHistoryItem {
  dates: Moment[],
  details: IDetail,
  label: string,
}

interface IMiddleStartEnd {
  Index: number;
  Price: number;
}

interface ITemperatureScale {
  Count: number;
  FareType: string;
  MiddleEnd: IMiddleStartEnd;
  MiddleStart: IMiddleStartEnd;
}

interface IAirlineSearchItem {
  index: number;
  item: AirlineSourceWithOriginalPrice;
  workspaceService: WorkspaceService;
  featureFlagsService: FeatureFlags;
  appService: AppService;
  selectedTravelPolicy: string;
  travelPolicyList: ITravelPolicyListItem[];
  isComplex?: boolean;
  preventDuplication: number[];
  hideBookmarks?: boolean;
  onAddToCart: (
    item: AirlineSourceWithOriginalPrice,
    currentFareId: string,
    finalPrice?: number,
  ) => void;
  onAddToNotepad: (
    item: AirlineSourceWithOriginalPrice,
    currentFareId: string,
  ) => void;
  onAddToFavorite: (
    item: AirlineSourceWithOriginalPrice,
    action: string,
  ) => void;
  onUpdateCheckbox: (value: boolean, route: AirRoute) => void;
  onGetLink: (item: AirlineSourceWithOriginalPrice) => string;
  onCopyLink: () => void;
  travellers: number;
  onShowDetails?: () => void;
  onClickToLinkButton?: () => void;
  onChangeFare?: (
    newFare: AirlineFareWithNewPrice,
    fares: AirlineFareWithNewPrice[]
  ) => void;
  qaAttrItemWrapper?: string;
  qaAttrButton?: string;
  qaAttrFavorite?: string;
  qaAttrItem?: (null | { [key: string]: string });
  isChangeAirTrip?: boolean;
  priceFilter?: number;
}

interface IAirlineSearchItemState {
  animationClass: string;
  currentFareId: string;
  minPriceFare: number;
  currentBonus: number | null;
  currentPrice: number;
  showDetails: boolean;
  showTravelPolicyDialog: boolean;
  applyTPFromFare: boolean;
  fareWithTp: AirlineFare;
  sameAirlineCompany: string | null;
  infoDialog: boolean;
  infoDialogForNote: boolean;
  disableToCartIfTPApply: boolean;
  accountTravelPolicy: AccountTravelPolicy;
  showHiddenFares: boolean;
  isShowOnlyInfoComplexDialog: boolean;
}
export interface IChildData {
  adult: number,
  children: number,
  infant: number,
}

export interface IAirSearchValue {
  key?: number;
  value?: string | Moment | null | IAirSearchRouteItem;
  field?: string;
}

export enum AnalyticSortAir {
  recommendations = 'recommendations',
  price = 'price',
  flight_duration = 'flight_duration',
  departure_time = 'departure_time',
  transplant_duration = 'transplant_duration',
}

export interface IItemEntireCart {
  ItemId: number,
  Routes: AirRoute[],
  Class: number,
  ProviderName: string,
  PNR: string,
  RemarkToken: string,
  FlightToken: string,
}

export type {
  TransferAirports,
  AirlineJsonData,
  AirlineService,
  AirlineMatch,
  AirlineResultData,
  AirlineFare,
  AirPrice,
  AirRoute,
  AirTerminal,
  AirSearchRoute,
  AirSegment,
  AirSegmentWithRouteId,
  AirlineSource,
  AirlineSourceWithOriginalPrice,
  AirRouteInfo,
  AirSearchStore,
  AdditionaService,
  OperatingAirlineType,
  MarketingAirlineType,
  IAirSearchRouteOpts,
  IAirSearchRouteItem,
  ITechnicalStop,
  AirlineHistoryItem,
  IDetail,
  AirSearchRouteFromTo,
  RoutesHistory,
  IMapRoute,
  AirPaging,
  AirFilters,
  IRefundable,
  IBagageFilter,
  AirlinesFilters,
  IAction,
  AirlinesFilterKeys,
  AirportsFilter,
  FilterName,
  PartialAirportsFilter,
  RoutesBorderTime,
  IFavorite,
  IFavoriteInfo,
  AirTag,
  RoutesTravelTime,
  IRouteSelected,
  AirlinesFilterNames,
  AirTime,
  ITemperatureScale,
  IAirlineSearchItem,
  IAirlineSearchItemState,
  AirlineCacheItemWithRouteInterface,
  AirlineTravelPolicyInterface,
  AirlineCacheItem,
  IAirAdditionalServicesSegment,
  IAirSearchRouteItemMin,
  AirSearchSettings,
  ISearchAirItem,
  IResultSearch,
  IServiceAdditionalInfo,
  ISelectedBaggageItem,
  Metadata,
};
