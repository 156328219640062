import React from 'react';
import { IDocumentTablePreset } from 'new-ui/src/components/UniversalTable/types';

import { Price } from 'new-ui';
import { Transaction } from '../../../../bi/services/employeePayment/types';
import { TransactionBaseTableCell } from './components/TransactionBaseTableCell';
import { formatDate, getLocaleTimeFromUtc } from '../../../../bi/utils/formatDate';
import { PATTERN } from '../../../../bi/constants/dateFormats';
import { LABELS, SortTransactionsTypeEnum } from './constants';
import ROUTES from '../../../../bi/constants/routes';

interface ITableSettingsArgs {
  selectable: boolean;
}

export const tableSettings = ({
  selectable,
}: ITableSettingsArgs): IDocumentTablePreset<Transaction> => ({
  rowKey: 'id',
  selectable,
  sort: {
    defaultColumn: 'eventDate',
    asc: SortTransactionsTypeEnum.Asc,
    desc: SortTransactionsTypeEnum.Desc,
  },
  fields: {
    description: {
      fieldName: LABELS.TABLE_HEADERS.SERVICE,
      sortSupport: false,
      sizePct: 30,
      Component: ({ fieldValue, row }) => (
        <TransactionBaseTableCell
          value={ fieldValue }
          link={ row.tripId ? ROUTES.ADDITIONAL_SERVICES.TRIP(row.tripId) : null }
        />
      ),
    },
    type: {
      fieldName: LABELS.TABLE_HEADERS.TRANSACTION_TYPE,
      sortSupport: false,
      sizePct: 17.5,
      Component: ({ fieldValue }) => (
        <TransactionBaseTableCell
          value={ fieldValue === 'incoming'
            ? LABELS.INCOMING_TRANSACTION
            : LABELS.OUTGOING_TRANSACTION
          }
        />
      ),
    },
    amount: {
      fieldName: LABELS.TABLE_HEADERS.AMOUNT,
      sortSupport: true,
      sizePct: 17.5,
      Component: ({ fieldValue, row }) => {
        const transactionAmountColor = row.type === 'incoming' ? 'green' : 'red';

        return (
          <Price value={ fieldValue } type='NORMAL_14' marginSmall color={ transactionAmountColor } />
        );
      },
    },
    fee: {
      fieldName: LABELS.TABLE_HEADERS.FEE,
      sortSupport: false,
      sizePct: 17.5,
      Component: ({ fieldValue }) => (
        <Price value={ fieldValue } type='NORMAL_14' marginSmall />
      ),
    },
    eventDate: {
      fieldName: LABELS.TABLE_HEADERS.DATE,
      sortSupport: true,
      sizePct: 17.5,
      Component: ({ fieldValue }) => {
        const formattedDate = formatDate(fieldValue, PATTERN.SHORT_FULL_YEAR);
        const formattedTime = getLocaleTimeFromUtc(fieldValue);

        return (
          <TransactionBaseTableCell value={ `${formattedTime}, ${formattedDate}` } />
        );
      },
    },
  },
});
