import React from 'react';
import { Text } from 'new-ui';
import { RouteComponentProps } from 'react-router-dom';

import { getText } from '../../../../../../i18n';

import CartService from '../../../../../bi/services/cart';

import { ConsistentCartList } from '../ConsistentCartList';

import { getCartByStatus } from '../../../../../bi/utils/cart';

import { CART_STATUS } from '../../../../../bi/constants/cart';
import ROUTES from '../../../../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../../../../bi/constants/attributesForTests';

import { CartsType, ECartStatus, IItem } from '../../../../../bi/types/cart';

import styles from './style.module.css';

const LABELS = {
  ON_APPROVE: getText('cart:onApprove'),
};

interface IConsistentCartListsProps {
  carts: CartsType[];
  cartService: CartService;
  history: RouteComponentProps['history'];
  showApproversCKR: boolean;
  handlePurchaseCart: (
    cart: CartsType,
    isUnderage: boolean,
    fromApprove?: boolean,
  ) => void;
}

const ConsistentCartLists = ({
  carts,
  cartService,
  history,
  handlePurchaseCart,
  showApproversCKR,
}: IConsistentCartListsProps) => {
  const preparedGetCartByStatus = getCartByStatus(carts);
  const declinedList = preparedGetCartByStatus(CART_STATUS.REJECTED, true) as CartsType[];
  const approvedList = preparedGetCartByStatus(CART_STATUS.AUTHORISED, true) as CartsType[];
  const waitingApproveList = preparedGetCartByStatus(CART_STATUS.IN_PROGRESS, true) as CartsType[];
  const { alreadySentIds } = cartService.get();

  const handleTimeIsUp = (item: IItem) => cartService.updateTimeIsUp(item);

  const handleRemoveCart = ({ id }: { id: number }, airToNote: boolean) => cartService.removeCart(id, airToNote);

  const handleConsistentCartClick = ({ id }: { id: number }) => history.push(`${ROUTES.CART.SPECIFIC}${id}`);

  const renderConsistentCart = ({ list, type, onDelete, onPurchase, onClick, checkUnderage = false }: {
    list: CartsType[],
    type: ECartStatus,
    onDelete: (i: CartsType, airToNote: boolean) => void;
    onPurchase: (i: CartsType, isUnderAge: boolean, val: boolean) => void;
    onClick: (i: CartsType) => void;
    checkUnderage?: boolean;
  }) =>
    !!list.length && (
      <ConsistentCartList
        list={ list }
        type={ type }
        onDelete={ onDelete }
        onPurchase={ onPurchase }
        onClick={ onClick }
        onTimeIsUp={ handleTimeIsUp }
        checkUnderage={ checkUnderage }
        qaAttr={ QA_ATTRIBUTES.cart.approval.item }
        showApproversCKR={ showApproversCKR }
        alreadySentIds={ alreadySentIds }
      />
    );

  const declinedListHtml = renderConsistentCart({
    checkUnderage: false,
    list: declinedList as CartsType[],
    type: ECartStatus.Declined,
    onDelete: handleRemoveCart,
    onPurchase: () => {},
    onClick: handleConsistentCartClick,
  });

  const approvedListHtml = renderConsistentCart({
    list: approvedList as CartsType[],
    type: ECartStatus.Approved,
    onDelete: handleRemoveCart,
    onPurchase: handlePurchaseCart,
    onClick: handleConsistentCartClick,
    checkUnderage: true,
  });

  const waitingApproveListHtml = renderConsistentCart({
    checkUnderage: false,
    list: waitingApproveList as CartsType[],
    type: ECartStatus.WaitingApprove,
    onDelete: handleRemoveCart,
    onPurchase: () => {},
    onClick: handleConsistentCartClick,
  });

  const condition = !!declinedList?.length || !!approvedList?.length || !!waitingApproveList?.length;

  return condition ? (
    <div className={ styles['approve-carts-list'] }>
      <Text
        type='bold_32'
        className={ styles.header }
        qaAttr={ QA_ATTRIBUTES.cart.approval.header }
      >
        { LABELS.ON_APPROVE }
      </Text>
      { declinedListHtml }
      { approvedListHtml }
      { waitingApproveListHtml }
    </div>
  ) : null;
};

export { ConsistentCartLists };
