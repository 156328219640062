import { action, computed, makeObservable, observable } from 'mobx';
import { Schema, ValidationError } from 'yup';

import { getText, getTextArray } from '../../../../i18n';

import { yup } from '../../../../utils/yup';
import { checkMaxDecimals } from '../../utils/validation';

import { PAYMENT_METHODS, PaymentMethodTypes, ERRORS_CARD_FORM } from '../../types/paymentMethod';

const LABELS = {
  AMOUNT: getTextArray('payment:topUpPersonalAccount.validate.amount') as Record<string, string>,
  EMAIL: getText('payment:topUpPersonalAccount.validate.email'),
};

const amountSchema = yup.number()
  .typeError(LABELS.AMOUNT.wrongType)
  .positive(LABELS.AMOUNT.positive)
  .max(Number.MAX_SAFE_INTEGER, LABELS.AMOUNT.max)
  .test(
    'maxDecimals',
    LABELS.AMOUNT.maxDecimals,
    (value) => checkMaxDecimals(value),
  );

const emailSchema = yup.string()
  .email(LABELS.EMAIL);

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable accountId = '';
  @observable paymentMethod: PaymentMethodTypes = PAYMENT_METHODS.ORGANIZATION_ACCOUNT;

  @observable cardFormErrors: ERRORS_CARD_FORM;
  @observable amountOfDeposit = '';
  @observable errorsForm: Record<string, string> = {};
  @observable commission: number | null = null;
  @observable isCommissionError: boolean = false;
  @observable email = '';
  @observable canPayWithPersonalFunds = false;
  @observable loading = false;

  @computed
  get disableSubmit() {
    return {
      withEmail: Object.keys(this.errorsForm).length > 0,
      withoutEmail: Object.keys(this.errorsForm).filter(s => s !== 'email').length > 0,
    };
  }

  @computed
  get getCommission() {
    const comission = Number(this.commission);

    return comission > 0 ? comission : 0;
  }

  @computed
  get getAmount() {
    return Number(this.amountOfDeposit) || 0;
  }

  @computed
  get getComputedAmount() {
    return this.getAmount + this.getCommission;
  }

  @action
  setAccountId = (accountId: string) => {
    this.accountId = accountId;
  };

  @action
  setCanPayWithPersonalFunds = (value: boolean) => {
    this.canPayWithPersonalFunds = value;
  };

  @action
  setPaymentMethod = (paymentMethod: PaymentMethodTypes) => {
    this.paymentMethod = paymentMethod;
  };

  @action
  setCardFormErrors = (value: ERRORS_CARD_FORM) => {
    this.cardFormErrors = value;
  };

  @action
  validate = (
    schema: Schema,
    value: any,
    field: string,
  ) => {
    try {
      schema.validateSync(value);
      delete this.errorsForm[field];
    } catch (e) {
      if (e instanceof ValidationError) {
        this.errorsForm[field] = e.message;
      }
    }
  };

  @action
  setAmountOfDeposit = (value: string) => {
    this.amountOfDeposit = value;
    this.validate(amountSchema, value, 'amount');
  };

  @action
  setCommission = (value: number | null) => {
    this.commission = value;
  };

  @action
  setCommissionError = (value: boolean) => {
    this.isCommissionError = value;
  };

  @action
  setEmail = (value: string) => {
    this.email = value;
    this.validate(emailSchema, value, 'email');
  };

  @action
  setIsLoading = (value: boolean) => {
    this.loading = value;
  };

  @action
  clearStore = () => {
    this.email = '';
    this.amountOfDeposit = '';
    this.cardFormErrors = '' as unknown as ERRORS_CARD_FORM;
  };
}
const PaymentMethodsStore = new Store();
export { PaymentMethodsStore, Store as CloudPaymentStoreType };
