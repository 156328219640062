import { IconType } from 'new-ui';

import { SERVICETYPE } from '../constants/serviceType';
import { ICON_TYPES_SMARTDESK } from '../constants/trips';

import { EmployeesFull } from '../types/employees';

const getIconType = (service: string): IconType | null => {
  let iconType: IconType | null = null;

  switch (service) {
    case SERVICETYPE.AIR:
    case SERVICETYPE.HOTEL:
    case SERVICETYPE.TRANSFER:
    case SERVICETYPE.TRAIN:
    case SERVICETYPE.VIP_HALL:
    case SERVICETYPE.AEROEXPRESS:
    case SERVICETYPE.BUS:
    case SERVICETYPE.EVENT: {
      const key = service.toUpperCase();

      iconType = ICON_TYPES_SMARTDESK[key as keyof typeof ICON_TYPES_SMARTDESK];
      break;
    }
    case SERVICETYPE.FLIGHT_CERTIFICATE:
      iconType = ICON_TYPES_SMARTDESK.OTHER;
      break;
    case SERVICETYPE.TAXI:
    case SERVICETYPE.TAXI_VOUCHER:
      iconType = ICON_TYPES_SMARTDESK.TAXI;
      break;
  }

  return iconType;
};

const getUniqueEmployeesList = (employees: EmployeesFull[]) => employees
  .reduce((list: EmployeesFull[], employee): EmployeesFull[] => {
    if (!list.some(({ EmployeeId }) => EmployeeId === employee.EmployeeId)) {
      list.push(employee);
    }

    return list;
  }, []);

export {
  getIconType,
  getUniqueEmployeesList,
};
