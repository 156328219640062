import { getText } from '../../../i18n';

import { ERROR_CODE } from './app';

import { IFeeConstruction } from '../services/settings/types';
import { ItemMenuLeaf } from '../../page/Settings/types';

export const KEYS = {
  APPROVAL_SCHEMES: 'approval-schemes',
  APPROVAL_SCHEMES_EXPENSE_REPORTS: 'approval-schemes-expense-reports',
  APPLICATION_SCHEME: 'application-scheme',
  TRAVEL_POLICIES: 'travel-policies',
  ANALYTICS: 'analytics',
  EMPLOYEES: 'employees',
  EMPLOYEE_PERSONAL_ACCOUNT: 'employee-personal-account',
  DEPARTMENTS: 'departments',
  COMPANIES: 'companies',
  PROFILE: 'profile',
  INTEGRATION: 'integration',
  MY_DATA: 'my-data',
  S7: 's7',
  EXPENSE_REPORT: 'expense-reports',
  VOUCHERS: 'vouchers',
  FEE_CONSTRUCTOR: 'fee-constructor',
  DOCUMENTS_FOR_SIGNATURE: 'documents-for-signature',
  DOCUMENTS_GOS_SIGN: 'documents-gos-sign',
  REPLISHMENT_BALANCE: 'replishment-balance',
};

export type SettingsKeysType = typeof KEYS;

const TITLES = {
  EMPLOYEES: getText('constants:settings.employees'),
  DEPARTMENTS: getText('constants:settings.departments'),
  ANALYTICS: getText('constants:settings.analytics'),
  TRAVEL_POLICIES: getText('constants:settings.travelPolicies'),
  APPROVAL_SCHEMES: getText('constants:settings.approvalSchemes'),
  APPLICATION_SCHEME: getText('constants:settings.applicationScheme'),
  APPROVAL_SCHEMES_TRIPS: getText('constants:settings.approvalSchemesTrips'),
  APPROVAL_SCHEMES_EXPENSE_REPORTS: getText('constants:settings.approvalSchemesER'),
  COMPANIES: getText('constants:settings.companies'),
  MY_DATA: getText('constants:settings.myData'),
  EMPLOYEE_PERSONAL_ACCOUNT: getText('constants:settings.employeePersonalAccount'),
  S7: getText('constants:settings.s7'),
  PROFILE: getText('constants:settings.profile'),
  INTEGRATION: getText('constants:settings.integration'),
  EXPENSE_REPORT: getText('constants:settings.expenseReport'),
  USERS: getText('constants:settings.users'),
  VOUCHERS: getText('constants:settings.vouchers'),
  FEE_CONSTRUCTOR: getText('constants:settings.feeConstructor'),
  REPLISHMENTS_BALANCE: getText('constants:settings.replenishmentBalance'),
};

const VALIDATION_DOC_DOWNLOAD = {
  [ERROR_CODE.BAD_REQUEST]: getText('settings:vouchers:validationDownloadDocument:errors:badRequest'),
  [ERROR_CODE.REQUEST_ENTITY_TOO_LARGE]: getText('settings:vouchers:validationDownloadDocument:errors:tooLarge'),
  [ERROR_CODE.INTERNAL_SERVER_ERROR]: getText('settings:vouchers:validationDownloadDocument:errors:serverError'),
};

const SETTINGS = (
  showExpenseReportsApprove: boolean,
  isSmartAgent: boolean,
  profile: ItemMenuLeaf[],
) => {
  const approves = [
    {
      title: TITLES.APPROVAL_SCHEMES_TRIPS,
      id: KEYS.APPROVAL_SCHEMES,
    },
  ];

  if (showExpenseReportsApprove) {
    approves.push({
      title: TITLES.APPROVAL_SCHEMES_EXPENSE_REPORTS,
      id: KEYS.APPROVAL_SCHEMES_EXPENSE_REPORTS,
    });
  }

  const titleEmployees = isSmartAgent ? TITLES.USERS : TITLES.EMPLOYEES;

  return [
    {
      title: titleEmployees,
      id: KEYS.EMPLOYEES,
      items: [],
    },
    {
      title: TITLES.DEPARTMENTS,
      id: KEYS.DEPARTMENTS,
      items: [],
    },
    {
      title: TITLES.ANALYTICS,
      id: KEYS.ANALYTICS,
      items: [],
    },
    {
      title: TITLES.TRAVEL_POLICIES,
      id: KEYS.TRAVEL_POLICIES,
      items: [],
    },
    {
      title: TITLES.APPROVAL_SCHEMES,
      id: KEYS.APPROVAL_SCHEMES,
      items: approves,
    },
    {
      title: TITLES.APPLICATION_SCHEME,
      id: KEYS.APPLICATION_SCHEME,
      items: [],
    },
    {
      title: TITLES.COMPANIES,
      id: KEYS.COMPANIES,
      items: [],
    },
    {
      title: TITLES.S7,
      id: KEYS.S7,
      items: [],
    },
    {
      title: TITLES.PROFILE,
      id: KEYS.MY_DATA,
      items: profile,
    },
    {
      title: TITLES.INTEGRATION,
      id: KEYS.INTEGRATION,
      hidden: false,
      items: [],
    },
    {
      title: TITLES.EXPENSE_REPORT,
      id: KEYS.EXPENSE_REPORT,
      items: [],
    },
    {
      title: TITLES.VOUCHERS,
      id: KEYS.VOUCHERS,
      items: [],
    },
    {
      title: TITLES.FEE_CONSTRUCTOR,
      id: KEYS.FEE_CONSTRUCTOR,
      items: [],
    },
    {
      title: TITLES.REPLISHMENTS_BALANCE,
      id: KEYS.REPLISHMENT_BALANCE,
      items: [],
    },
  ];
};

const DEFAULT_FEE_CONSTRUCTION: IFeeConstruction[] = [
  { Value: '', ServiceType: 'Air', PricingType: '', FeeNote: '' },
  { Value: '', ServiceType: 'Train', PricingType: '', FeeNote: null },
  { Value: '', ServiceType: 'Hotel', PricingType: '', FeeNote: null },
];

export {
  SETTINGS,
  VALIDATION_DOC_DOWNLOAD,
  DEFAULT_FEE_CONSTRUCTION,
};
