import moment, { Moment } from 'moment';

import { isSmartAgent } from './env';
import parseJsonString from './parseJsonString';
import { diffYears, isAfterDate, isValidMomentObject } from './formatDate';
import parseUnix from './parseDateTime';

import { AIRLINE_PROVIDERS } from '../constants/airline';
import { SERVICETYPE } from '../constants/serviceType';
import { CARTACTIONTYPES } from '../constants/cart';
import { IPrepareNoteItem } from '../services/notepad/types';
import { ITripTag } from '../services/tripTags/types';
import { ItemDataType } from '../../page/Cart/types';
import { CartsType } from '../types/cart';
import { TravelApprovalType } from '../services/travelApproval/consts';

const AGE_OF_NOT_BABY_ANYMORE = 2;
const AGE_OF_NOT_CHILD_ANYMORE = 12;
const AGE_OF_ADULT_PERSON = 18;

interface Employee {
  Id: number;
  Birthday: string | Date;
  MobilePhone: string;
  Bonuses: { Code: string }[];
  Departments?: { DepartmentId: number }[];
  Companies?: { CompanyId: number; CompanySettings?: { MandatoryProject: boolean } }[];
}

interface EmployeeError {
  selectedForeignPassportFoul?: boolean;
}

interface EmployeeWithErrors {
  Employee: Employee;
  errors: EmployeeError;
}

interface EmployeeCartItem {
  DepartmentId: number;
  CompanyId: number;
  ProjectId: number;
  EmployeeId: number;
  DocumentId: number;
}

interface CartItem {
  ServiceType: string;
  Employees: EmployeeWithErrors[] ;
  EmployeeCartItems: EmployeeCartItem[];
  Price: number;
  Data: string;
  Metadata: { TravellersCount: number };
}

interface Cart {
  status: string;
}

interface Labels {
  ADD_TO_TRIP_TOOLTIP: string;
  ADD_TO_TRIP_INSURED: string;
}

interface Company {
  CompanyId: number;
  CompanySettings?: {
    MandatoryProject: boolean;
  };
}

export const isDepartmentsSelect = (
  { EmployeeCartItems, Employees }: CartItem | any,
  isService = false,
) => EmployeeCartItems.every(
  ({ DepartmentId, EmployeeId }: { DepartmentId: number, EmployeeId: number }) => {
    const foundEmployee = Employees.find(({ Employee: currentEmployee }: { Employee: Employee }) => currentEmployee && currentEmployee.Id === EmployeeId);

    const { Departments, Companies } = foundEmployee ? foundEmployee.Employee : { Departments: [], Companies: [] };

    const findDepartment = Companies.find(({ CompanyId }: Company) => CompanyId === EmployeeCartItems[0].CompanyId);
    const departmentsLength = findDepartment && findDepartment.Departments ? findDepartment.Departments.length : null;
    const checkDepartment = departmentsLength ? EmployeeCartItems[0].DepartmentId : 1;
    const lastServiceCheck = isService && checkDepartment > 0;
    const departmentIdForCartItems = Employees[0].Employee.Departments && Employees[0].Employee.Departments.length > 0 ? EmployeeCartItems[0].DepartmentId : 1;

    if (isService && departmentIdForCartItems > 0) {
      return true;
    }

    if (!Departments?.length) {
      return true;
    }

    const isSingleCompany = Companies.length === 1;

    return !isSingleCompany || Boolean(DepartmentId) || lastServiceCheck;
  });

const checkForeignPassportsFoulsCart = (item: CartItem) => item.Employees.some(employeeItem =>
  !!employeeItem.errors && !!employeeItem.errors.selectedForeignPassportFoul);

const getMinMaxDate = ({ checkin, checkout, minDate, maxDate, isOnlyCheckinDates }: {
  checkin: Moment;
  checkout: Moment;
  minDate: Moment | null;
  maxDate: Moment;
  isOnlyCheckinDates: boolean;
}) => {
  const min = isValidMomentObject(checkin) ? checkin : maxDate;
  const newMinDate = !minDate ? min : minDate;

  const maxCondition = isOnlyCheckinDates
    ? isAfterDate(checkin, maxDate)
    : isAfterDate(checkout, maxDate);

  const max = isOnlyCheckinDates ? checkin : checkout;

  return {
    min: (checkin.isBefore(newMinDate)) ? checkin : newMinDate,
    max: maxCondition ? max : maxDate,
  };
};

const isAirlineEmployeeListValid = (item: CartItem, data: any) => {
  const employeesCount = item.EmployeeCartItems.length;
  const isCompaniesSelected = item.EmployeeCartItems.every(({ CompanyId }) => CompanyId);
  const isDepartmentsSelected = isDepartmentsSelect(item);
  const isDocument = item.EmployeeCartItems.filter(itemEmployee => itemEmployee.DocumentId).length === item.EmployeeCartItems.length;

  return isDocument && employeesCount === data.Metadata.TravellersCount && isCompaniesSelected && isDepartmentsSelected && !checkForeignPassportsFoulsCart(item);
};

const isCorrectMobile = ({ Employees }: CartItem) =>
  (Employees
    ? Employees.every(
      ({ Employee: { MobilePhone } }) =>
        (MobilePhone.charAt(1) === '7'
          ? MobilePhone.charAt(2) === '7' || MobilePhone.charAt(2) === '9'
          : true),
    )
    : true);

const getDataForCart = (cartItems: any, isOnlyCheckinDates = false) => {
  let minDate: Moment | null = null;
  let maxDate = moment();
  let unlock = true;
  let price = 0;
  const unlockProject: boolean[] = [];

  cartItems.forEach((item: any) => {
    const employeesCount = item.EmployeeCartItems.length;

    price += item.Price;
    unlockProject.push(
      employeesCount > 0 ? item.EmployeeCartItems.every(
        ({
          ProjectId,
          CompanyId,
          EmployeeId,
        }: {
          ProjectId: number | undefined;
          CompanyId: number;
          EmployeeId: number;
        }) => {
          if (ProjectId || !CompanyId) return true;

          const employee = item.Employees && Array.isArray(item.Employees)
            ? item.Employees.find(({ Employee }: any) => Employee && Employee.Id === EmployeeId)
            : null;

          const Companies = employee ? employee.Employee.Companies : null;

          const company = Array.isArray(Companies)
            ? Companies.find(Company => Company.CompanyId === CompanyId)
            : null;

          return !(company?.CompanySettings ? company.CompanySettings.MandatoryProject : false);
        }) : false);

    switch (item.ServiceType) {
      case SERVICETYPE.AIR: {
        const data = parseJsonString(item.Data);
        unlock = unlock && isAirlineEmployeeListValid(item, data) && isCorrectMobile(item);

        const firstRoute = data.Routes[0];
        const lastRoute = data.Routes[data.Routes.length - 1];

        const firstSegment = firstRoute.Segments[0];
        const lastSegment = lastRoute.Segments[lastRoute.Segments.length - 1];

        const checkin = parseUnix(firstSegment.DepartureTime_DateTime);
        const checkout = parseUnix(lastSegment.ArrivalTime_DateTime);

        const { min, max } = getMinMaxDate({ checkin, checkout, minDate, maxDate, isOnlyCheckinDates });

        minDate = min;
        maxDate = max;

        break;
      }
      case SERVICETYPE.HOTEL: {
        const meta = parseJsonString(item.Data);

        unlock = unlock && employeesCount >= 1 && item.EmployeeCartItems[0].CompanyId && isDepartmentsSelect(item, true);

        const checkin = moment(meta.checkin);
        const checkout = moment(meta.checkout);

        const { min, max } = getMinMaxDate({ checkin, checkout, minDate, maxDate, isOnlyCheckinDates });

        minDate = min;
        maxDate = max;
        break;
      }
      case SERVICETYPE.TRANSFER: {
        const meta = parseJsonString(item.Data);

        unlock = unlock && employeesCount >= 1 && item.EmployeeCartItems[0].CompanyId && isDepartmentsSelect(item, true);

        const checkin = moment(meta.DateArrival);

        const { min, max } = getMinMaxDate({ checkin, checkout: checkin, minDate, maxDate, isOnlyCheckinDates });

        minDate = min;
        maxDate = max;
        break;
      }
      case SERVICETYPE.TRAIN: {
        const { Data, EmployeeCartItems } = item;
        const data = parseJsonString(Data);
        const { DateDepartureLocal, DateArrivalLocal, Travellers } = data;
        const isCompaniesSelected = EmployeeCartItems.every(({ CompanyId }: { CompanyId: number }) => CompanyId);
        const isDepartmentsSelected = isDepartmentsSelect(item);
        const isDocument = EmployeeCartItems.filter((itemEmployee: EmployeeCartItem) => itemEmployee.DocumentId).length === EmployeeCartItems.length;

        unlock = unlock && isDocument && employeesCount === Travellers && isCompaniesSelected && isDepartmentsSelected && !checkForeignPassportsFoulsCart(item);

        const checkin = moment(DateDepartureLocal);
        const checkout = moment(DateArrivalLocal);

        const { min, max } = getMinMaxDate({ checkin, checkout, minDate, maxDate, isOnlyCheckinDates });

        minDate = min;
        maxDate = max;
        break;
      }
      case SERVICETYPE.AEROEXPRESS: {
        const { Trip: { departure_date, backward_date: backDate, count } } = parseJsonString(item.Data);
        const isCompaniesSelected = item.EmployeeCartItems.every(({ CompanyId }: { CompanyId: number }) => CompanyId);
        const isDepartmentsSelected = isDepartmentsSelect(item);
        const isDocument = item.EmployeeCartItems.filter((itemEmployee: EmployeeCartItem) =>
          itemEmployee.DocumentId).length === item.EmployeeCartItems.length;

        unlock = unlock && isDocument && employeesCount === count && isCompaniesSelected && isDepartmentsSelected && !checkForeignPassportsFoulsCart(item);

        const checkin = moment(departure_date);
        const checkout = moment(backDate);

        const { min, max } = getMinMaxDate({
          checkin,
          checkout: backDate ? checkout : checkin,
          minDate,
          maxDate,
          isOnlyCheckinDates,
        });

        minDate = min;
        maxDate = max;

        break;
      }
      case SERVICETYPE.TAXI_VOUCHER: {
        const { Data, EmployeeCartItems } = item;
        const data = parseJsonString(Data);
        const { StartDate, EndDate } = data;

        unlock = unlock && employeesCount >= 1 && EmployeeCartItems[0].CompanyId && isDepartmentsSelect(item, true);

        const checkin = parseUnix(StartDate);
        const checkout = parseUnix(EndDate);

        const { min, max } = getMinMaxDate({ checkin, checkout, minDate, maxDate, isOnlyCheckinDates });

        minDate = min;
        maxDate = max;

        break;
      }
      case SERVICETYPE.INSURANCE: {
        const { Data, EmployeeCartItems } = item;
        const data = parseJsonString(Data);
        const { CheckinDate, CheckoutDate } = data;

        unlock = unlock && employeesCount >= 1 && EmployeeCartItems[0].CompanyId && isDepartmentsSelect(item);

        const checkin = moment(CheckinDate);
        const checkout = moment(CheckoutDate);
        const { min, max } = getMinMaxDate({
          checkin,
          checkout,
          minDate,
          maxDate,
          isOnlyCheckinDates,
        });

        minDate = min;
        maxDate = max;

        break;
      }
    }
  });

  const project = unlockProject.every(pr => pr);

  return { minDate, maxDate, unlock, price, project };
};

const validateCartItemsForPersonalPayment = (cartItems: CartItem[], isOnlyCheckinDates = false) => {
  let minDate: null | Moment = null;
  let maxDate = moment();
  let unlock = true;
  let includesTaxi = false;

  cartItems.forEach((item: CartItem) => {
    const employeesCount = item.EmployeeCartItems.length;

    switch (item.ServiceType) {
      case SERVICETYPE.AIR: {
        const data = parseJsonString(item.Data);
        unlock = unlock && isAirlineEmployeeListValid(item, data) && isCorrectMobile(item);

        const firstRoute = data.Routes[0];
        const lastRoute = data.Routes[data.Routes.length - 1];

        const firstSegment = firstRoute.Segments[0];
        const lastSegment = lastRoute.Segments[lastRoute.Segments.length - 1];

        const checkin = parseUnix(firstSegment.DepartureTime_DateTime);
        const checkout = parseUnix(lastSegment.ArrivalTime_DateTime);

        const { min, max } = getMinMaxDate({ checkin, checkout, minDate, maxDate, isOnlyCheckinDates });

        minDate = min;
        maxDate = max;

        break;
      }
      case SERVICETYPE.HOTEL: {
        const meta = parseJsonString(item.Data);

        unlock = unlock && employeesCount >= 1 && item.EmployeeCartItems[0].CompanyId && isDepartmentsSelect(item, true);

        const checkin = moment(meta.checkin);
        const checkout = moment(meta.checkout);

        const { min, max } = getMinMaxDate({ checkin, checkout, minDate, maxDate, isOnlyCheckinDates });

        minDate = min;
        maxDate = max;
        break;
      }
      case SERVICETYPE.TRANSFER: {
        const meta = parseJsonString(item.Data);

        unlock = unlock && employeesCount >= 1 && item.EmployeeCartItems[0].CompanyId && isDepartmentsSelect(item, true);

        const checkin = moment(meta.DateArrival);

        const { min, max } = getMinMaxDate({ checkin, checkout: checkin, minDate, maxDate, isOnlyCheckinDates });

        minDate = min;
        maxDate = max;
        break;
      }
      case SERVICETYPE.TRAIN: {
        const { Data, EmployeeCartItems } = item;
        const data = parseJsonString(Data);
        const { DateDepartureLocal, DateArrivalLocal, Travellers } = data;
        const isCompaniesSelected = EmployeeCartItems.every(({ CompanyId }: { CompanyId: number }) => CompanyId);
        const isDepartmentsSelected = isDepartmentsSelect(item);
        const isDocument = EmployeeCartItems.filter((itemEmployee: EmployeeCartItem) => itemEmployee.DocumentId).length === EmployeeCartItems.length;

        unlock = unlock && isDocument && employeesCount === Travellers && isCompaniesSelected && isDepartmentsSelected && !checkForeignPassportsFoulsCart(item);

        const checkin = moment(DateDepartureLocal);
        const checkout = moment(DateArrivalLocal);

        const { min, max } = getMinMaxDate({ checkin, checkout, minDate, maxDate, isOnlyCheckinDates });

        minDate = min;
        maxDate = max;
        break;
      }
      case SERVICETYPE.AEROEXPRESS: {
        const { Trip: { departure_date, backward_date: backDate, count } } = parseJsonString(item.Data);
        const isDocument = item.EmployeeCartItems.filter((itemEmployee: EmployeeCartItem) => itemEmployee.DocumentId).length === item.EmployeeCartItems.length;
        const isCompaniesSelected = item.EmployeeCartItems.every(({ CompanyId }: { CompanyId: number }) => CompanyId);
        const isDepartmentsSelected = isDepartmentsSelect(item);

        unlock = unlock && isDocument && employeesCount === count && isCompaniesSelected && isDepartmentsSelected && !checkForeignPassportsFoulsCart(item);

        const checkin = moment(departure_date);
        const checkout = moment(backDate);

        const { min, max } = getMinMaxDate({
          checkin,
          checkout: backDate ? checkout : checkin,
          minDate,
          maxDate,
          isOnlyCheckinDates,
        });

        minDate = min;
        maxDate = max;

        break;
      }
      case SERVICETYPE.TAXI_VOUCHER: {
        const { Data } = item;
        const data = parseJsonString(Data);
        const { StartDate, EndDate } = data;

        unlock = false;
        includesTaxi = true;

        const checkin = parseUnix(StartDate);
        const checkout = parseUnix(EndDate);

        const { min, max } = getMinMaxDate({ checkin, checkout, minDate, maxDate, isOnlyCheckinDates });

        minDate = min;
        maxDate = max;

        break;
      }
      case SERVICETYPE.INSURANCE: {
        const { Data, EmployeeCartItems } = item;
        const data = parseJsonString(Data);
        const { CheckinDate, CheckoutDate } = data;

        unlock = unlock && employeesCount >= 1 && EmployeeCartItems[0].CompanyId && isDepartmentsSelect(item);

        const checkin = moment(CheckinDate);
        const checkout = moment(CheckoutDate);
        const { min, max } = getMinMaxDate({
          checkin,
          checkout,
          minDate,
          maxDate,
          isOnlyCheckinDates,
        });

        minDate = min;
        maxDate = max;

        break;
      }
    }
  });

  return { unlock, includesTaxi };
};

const isCartHasAirChildren = (
  items: any,
) => {
  const onlyAviaItems = items.filter(({ ServiceType }: { ServiceType: string }) => ServiceType === SERVICETYPE.AIR);

  if (!onlyAviaItems.length) {
    return false;
  }

  const departureDates = onlyAviaItems.reduce((acc: any, { Id, Data }: { Id: number, Data: string }) => {
    const jsonData = parseJsonString(Data);
    const departureDate = jsonData.Routes[0].Segments[0].DepartureTime_DateTime;

    return { ...acc, [Id]: departureDate };
  }, {});

  const allEmployees = onlyAviaItems.flatMap(
    ({ Employees, Id: itemId }: { Employees: Employee[], Id: number }) =>
      Employees.map(empl => ({ ...empl, itemId })));

  const hasAdultEmployee = allEmployees.some(
    ({ Employee: { Birthday }, itemId }: { Employee: { Birthday: Date }; itemId: number }) =>
      diffYears(Birthday, departureDates[itemId]) >= AGE_OF_ADULT_PERSON);

  if (hasAdultEmployee) {
    return false;
  }

  return allEmployees.some(
    ({ Employee: { Birthday }, itemId }: { Employee: { Birthday: Date }; itemId: number }) => {
      const diff = diffYears(Birthday, departureDates[itemId]);

      return diff < AGE_OF_NOT_CHILD_ANYMORE;
    });
};

const s7BonusCardWarning = (cartItems: IPrepareNoteItem[]) => {
  const isS7BonusCardExist = cartItems.filter((item) => {
    const { Data, Employees } = item;
    const isS7Provider = JSON.parse(Data)?.ProviderName === AIRLINE_PROVIDERS.S7;
    const isEmployeeHaveS7BonusCard = Employees.flatMap(({ Employee }) => {
      const { Bonuses } = Employee;

      return Bonuses.filter(({ Code }: { Code: string }) => Code === AIRLINE_PROVIDERS.S7);
    });

    return isS7Provider && isEmployeeHaveS7BonusCard.length;
  });

  return !!isS7BonusCardExist.length;
};

const cartHasAirUnderageByProviders = (items: any) => items.some(({ ServiceType, Employees, Data }: CartItem) => {
  if (ServiceType !== SERVICETYPE.AIR) {
    return false;
  }

  const { ProviderName, Routes } = parseJsonString(Data);
  const allowingProvider = ProviderName === AIRLINE_PROVIDERS.AMADEUS
    || ProviderName === AIRLINE_PROVIDERS.SIRENA
    || ProviderName === AIRLINE_PROVIDERS.AEROFLOT
    || ProviderName === AIRLINE_PROVIDERS.MIXVEL;
  const departureDate = Routes[0].Segments[0].DepartureTime_DateTime;

  return Employees.some(({ Employee: { Birthday } }) => {
    const diff = diffYears(Birthday, departureDate);

    const allowingLimit = allowingProvider && diff >= AGE_OF_NOT_BABY_ANYMORE;

    if (allowingLimit) {
      return false;
    }

    return diff < AGE_OF_NOT_CHILD_ANYMORE;
  });
});

const getCountOfTravellers = (type: string, item: ItemDataType<Moment>, total = 1) => ({
  [SERVICETYPE.AIR]: () => item.Metadata.TravellersCount,
  [SERVICETYPE.TRAIN]: () => item.Travellers,
  [SERVICETYPE.HOTEL]: () => item.GuestsCount,
  [SERVICETYPE.TRANSFER]: () => item.Capacity,
  [SERVICETYPE.AEROEXPRESS]: () => item.Trip.count,
  [SERVICETYPE.TAXI]: () => item.GuestsCount,
  [SERVICETYPE.TAXI_VOUCHER]: () => item.GuestsCount,
  [SERVICETYPE.INSURANCE]: () => total,
}[type]());

const isOptionalEmployeeItem = (type: string, maxEmployees: number) => {
  const typeMayHaveOptionalEmployee = type === SERVICETYPE.HOTEL || type === SERVICETYPE.TRANSFER;

  return typeMayHaveOptionalEmployee && maxEmployees > 1;
};

const cartItemIsNotTotallyFilled = (item: any, mandatoryProject: boolean) => {
  const { Data, Employees, ServiceType, EmployeeCartItems } = item;

  const itemData = parseJsonString(Data);

  const linkedEmplsCount = Employees.length;
  const maxEmplsForItem = getCountOfTravellers(ServiceType, itemData) ?? 0;
  const linkedEmplsLessThanMax = linkedEmplsCount < maxEmplsForItem;

  const allEmplsHaveCompanyId = EmployeeCartItems.some(({ CompanyId }: { CompanyId: number }) => !!CompanyId);
  const allEmplsHaveProjectId = EmployeeCartItems.some(({ ProjectId }: { ProjectId: number }) => !!ProjectId);

  const itemHasOptionalEmployee = isOptionalEmployeeItem(ServiceType, maxEmplsForItem as number);

  const linkedEmplsIsNotOk = itemHasOptionalEmployee
    ? !(itemHasOptionalEmployee && !!linkedEmplsCount)
    : linkedEmplsLessThanMax;

  return linkedEmplsIsNotOk || !allEmplsHaveCompanyId || (!allEmplsHaveProjectId && mandatoryProject);
};

const getFieldWithTA = (
  isTravelApproval: boolean,
  field: string,
  approvedRequest: boolean,
  usersNotValid: boolean,
) => {
  if (isTravelApproval && !approvedRequest) {
    return CARTACTIONTYPES.TRAVEL_APPROVAL_SCHEME;
  }

  if (isTravelApproval && usersNotValid) {
    return CARTACTIONTYPES.CHOOSE_VALID_USER;
  }

  return field;
};

const getUnlockTravelApproval = (
  isTravelApproval: boolean,
  isApprovedRequest: boolean,
  unlockValue: boolean,
  usersNotValid: boolean,
) => {
  if (isTravelApproval) {
    return isApprovedRequest && unlockValue && !usersNotValid;
  }

  return unlockValue;
};

const getUsersNotValid = (
  isTravelApproval: boolean,
  sources: CartItem[],
  chosenApprovedRequest: TravelApprovalType,
) => {
  const getCartSelectedEmployeesIds = () => {
    const cartSelectedEmployeesIds = sources.reduce<number[]>((acc, source) => {
      const employeeIds = source.EmployeeCartItems.map(({ EmployeeId }) => EmployeeId);

      return [...acc, ...employeeIds];
    }, []);

    return [...new Set(cartSelectedEmployeesIds)];
  };

  const getUsersValid = (cartSelectedEmployeesIds: number[]) => {
    const chosenApprovedRequestEmployeesIds = chosenApprovedRequest ? chosenApprovedRequest.Employees.map(({ Id }) => Id) : [];

    return chosenApprovedRequestEmployeesIds.some(id => cartSelectedEmployeesIds.includes(id as number));
  };

  const cartSelectedEmployeesIds = getCartSelectedEmployeesIds();
  const usersValid = getUsersValid(cartSelectedEmployeesIds);

  return isTravelApproval && !usersValid;
};

const addSelectedTripTags = (selectedTripTags: ITripTag[], newTags: any[]) => {
  const tripTagsIds = newTags?.map(({ Id }) => Id) || null;
  const mergedTags = [...selectedTripTags, ...tripTagsIds];
  const tagsSet = new Set(mergedTags);

  return Array.from(tagsSet);
};

const getCartByStatus = (cartsParam: Cart[] | CartsType[]) => (status: string, findAll = false) => {
  if (findAll) {
    return cartsParam.filter(cart => cart.status === status);
  }

  return cartsParam.find(cart => cart.status === status);
};

const prepareDepartmentsAndProjectHeads = (cart: { sources: { EmployeeCartItems: EmployeeCartItem[] }[] }) => {
  const departmentsSet = new Set();
  const projectsSet = new Set();

  cart.sources.forEach(({ EmployeeCartItems }) => {
    EmployeeCartItems.forEach(({ DepartmentId, ProjectId }) => {
      if (DepartmentId) {
        departmentsSet.add(DepartmentId);
      }

      if (ProjectId) {
        projectsSet.add(ProjectId);
      }
    });
  });

  return { departments: Array.from(departmentsSet), projects: Array.from(projectsSet) };
};

const defineTextOfLabelOfTooltip = (
  TripId: number | null,
  isBlockEditEmployees: boolean,
  LABELS: Labels,
) => {
  if (TripId) return LABELS.ADD_TO_TRIP_TOOLTIP;

  if (isBlockEditEmployees) return LABELS.ADD_TO_TRIP_INSURED;

  return '';
};

const getUpdateTextByServiceType = (serviceType: string, itemData: any) => {
  switch (serviceType) {
    case SERVICETYPE.HOTEL: {
      return itemData.hotel.Name;
    }

    default: return '';
  }
};

const getColorByServiceType = (serviceType: string) => {
  const iconColor = isSmartAgent ? 'second_green' : 'blue';

  return serviceType !== SERVICETYPE.EVENT ? iconColor : 'default';
};

export {
  cartHasAirUnderageByProviders,
  isCartHasAirChildren,
  isAirlineEmployeeListValid,
  getDataForCart,
  getCountOfTravellers,
  isOptionalEmployeeItem,
  cartItemIsNotTotallyFilled,
  getFieldWithTA,
  getUnlockTravelApproval,
  getUsersNotValid,
  addSelectedTripTags,
  getCartByStatus,
  prepareDepartmentsAndProjectHeads,
  s7BonusCardWarning,
  getDataForCart as default,
  defineTextOfLabelOfTooltip,
  validateCartItemsForPersonalPayment,
  getUpdateTextByServiceType,
  getColorByServiceType,
};
