import { getText } from '../../../../../i18n';

import ROUTES from '../../../../bi/constants/routes';

import { CustomBannerContentElement } from './types';

export const CUSTOM_BANNER_BUR_SERVICE: CustomBannerContentElement[] = [
  {
    text: getText('smartdesk:customBannerBurService.start'),
  },
  {
    text: getText('smartdesk:customBannerBurService.link'),
    link: ROUTES.INTRODUCTORY_BRIEFING_BURSERVICE,
  },
  {
    text: getText('smartdesk:customBannerBurService.end'),
  },
];

export const CUSTOM_BANNER_VMZ_OMK: CustomBannerContentElement[] = [
  {
    text: getText('smartdesk:customBannerStart'),
  },
  {
    text: getText('smartdesk:customBannerEnd'),
    link: ROUTES.CUSTOM_BUSSINES_TRIPS_VMZ_OMK,
  },
];

export const CustomBannerAccountIds = {
  BUR_SERVICE: 34358,
  VMZ_OMK: 1393,
};
