import { Moment } from 'moment';
import { SEARCH_MENU_TYPES, VIEW_MODE_TYPES } from './constants/searchMenuTypes';
import { TravelPolicyItem } from '../userSession/types';
import { IAccountTravelPolicy } from '../workspace/types';
import { SEARCH_TYPES } from '../../constants/hotel';

interface IHotelsStore {
  sources: Variant[],
  cache: PreparedVariant[],
  items: PreparedVariant[],
  itemsMap: PreparedVariant[],
  tags: ITag[],
  regionLocation: RegionLocation,
  coordsPoint: number[],
  abortSearchByRadius: boolean,
  viewMode: VIEW_MODE_TYPES,
  travelPolicyAllList: TravelPolicyItem[],
  lastHotelsSearch: SEARCH_TYPES,
  isRefund: boolean,

  menuType: SEARCH_MENU_TYPES,
  loadComplete: boolean,
  loading: boolean,
  loadingRegion: boolean,
  setCacheOnMapToggler: boolean,

  progress: number,
  searchStartTime: number,

  paging: {
    total: number,
    current: number,
    count: number,
  },
  rightsBuyTrip: RightsBuyTrip | object,
  accountTravelPolicy: IAccountTravelPolicy | object,
  sortBy: string,
  changeSortBy: boolean,
  hasOneSmart: boolean,
  hasOneGuaranteed: boolean,
  isWhiteLabel: boolean,
  isAnyFavorite: boolean,
  unavailableTravelPolicy: boolean,
  favorite: null,
  filters: Filters,
  initDoubleRadiusSearch: boolean,
  initRadiusSearchHotels: boolean,
  hotelsFilters: IHotelsFilters,
  displayFilters: IDisplayFilters | null,
  radiusCustom: boolean,
}

interface IHotelStore {
  hotel: IHotel | null,
  cacheRoomGroups: PrepareRoomGroup[],
  staticRoomGroups: RoomGroup[] | null,
  loadingRate: boolean,
  loadingHotel: boolean,
  filterVat: {
    check: boolean,
    hasVat: boolean,
  },
  filters: HotelFilters,
  showFormHotelSearchDialog: boolean,
  isMessageSend: boolean,
  loadingRequest: boolean,
  radiusSearchHotelResult: boolean, // чтобы signaler попадал в нужную функцию
  radiusSearchParams: RadiusSearchParams | object,
  hotelsRecommended: HotelsRecommended[],
  selectOfflineRoom: OfflineRoomGroup | null,
  showIsPriceGuaranteeChange: boolean,
  errorHotelResearch: boolean,
  countReserved: number,
}

interface ISearchStore {
  guid: string,
  region: Region,
  travellersCount: number,
  adult: number,
  bedType: number,
  checkin: Moment | null,
  checkout: Moment | null,
  checkinMinDate: Moment,
  checkoutMinDate: Moment,
  customCheckin: Moment | null
  customCheckout: Moment | null,
  breakfast: string,
  freeCancellation: string,
  comment: string,
  arrayEmployees: any[],
  isTravelApproval: boolean,
  schemeLoading: boolean,
  redirectSearchId: null,
  redirectSearchType: string,
  optimalRate?: string,
  isTextualOpenCustomCheckin: boolean,
  isTextualOpenCustomCheckout: boolean,
}

interface InitSearchType {
  hotelId: number
  CheckinDate: string,
  CheckoutDate: string,
  GuestsCount: number
  TravellersCount: number,
  RegionId: number
  AddToHistory?: boolean,
  Label?: string,
  FromHistory?: boolean,
  CheckinTime?: string | null,
  CheckoutTime?: string | null,
  RedirectSearchType?: string,
  RedirectSearchId?: string | null,
}

interface PrepareAutocompleteStatsType {
  Request: string,
  Result: string,
  Type: string,
  Position: number,
  Action: string,
  Date: Moment,
}

interface SearchHotelsResponse {
  body: {
    Latitude: number,
    Longitude: number
    Radius: number,
  }
  status: number,
}

interface ISearchHotelBody {
  guid: string,
  data: IHotelsFilters,
}

interface Region {
  label: string,
  selected: object,
  isForeign: boolean,
  suggests: HotelAutocompleteObj[],
  loading?: boolean,
}

interface HotelsRegion {
  SearchComplete: boolean,
  Variants: Variant[],
  RegionLocation: {
    Latitude: number,
    Longitude: number
  },
  CountVariants?: number,
  DisplayFilters?: IDisplayFilters,
  Status?: HOTEL_RESPONSE_STATUS,
}

enum HOTEL_RESPONSE_STATUS {
  ERROR_SEARCH = 'ErrorSearch',
  SEARCH_COMPLETE = 'SearchComplite',
  WAIT_PROVIDERS = 'WaitProviders',
}

interface IDisplayFilters {
  Amenities: IFilterResponse,
  HasFavorite: boolean,
  HasPriceGuaranteed: boolean,
  HasSmartHotel: boolean,
  PriceFrom: number,
  PriceTo: number,
  Rating: IFilterResponse,
  Stars: IFilterResponse,
  TravelPolicyList: any,
  Type: IFilterResponse,
  CountHotels: number,
  Meal: IFilterResponse,
}

type IFilterResponse = Record<string, number>;

interface Favorite {
  name: string,
  dates: {
    from: string,
    to: string
  }
}

interface RegionLocation {
  latitude: number,
  longitude: number,
  radius: number,
}

interface HotelsRegionResponse {
  body: HotelsRegion,
  status: number,
}

interface Variant {
  Rates: RegionRate[],
  Static: HotelRegionStatic,
  IsContract: boolean,
  IsPriceGuaranteed: boolean,
  IsSmartHotel?: boolean,
  IsFavorites?: boolean,
  HotelId: number,
  Rate?: RegionRate,
  DebugInfo?: string,
}

interface PreparedVariant {
  favoriteId: string,
  isContract: boolean,
  isPriceGuaranteed: boolean,
  debugInfo?: string,
  rate: RegionRate,
  static: HotelRegionStatic
}

interface RegionRate {
  Base: number,
  RackRate: number,
  Commission: number,
  Night: number,
  Total: number,
  AgentFee: number,
  Vat: number,
  HasVat: boolean,
  Breakfast: boolean,
  Refundable: boolean,
  Online: boolean,
  TravelPolicy: TravelPolicy,
  IsCustom: boolean,
  RoomInfo: Partial<RecommendRegionRate>,
  NoAvailabilityPeriods: NoAvailabilityPeriods[],
}

interface RecommendRegionRate {
  Name: string,
  Meal: string,
  Bed: string,
  Deadline: string,
  FreeRoom: number,
  BedType: number,
  Basket: string,
  MealCategory: number,
}

interface IOptimalRateQueryParams extends Partial<RecommendRegionRate> {
  Price: number,
}

interface TravelPolicy {
  Apply: boolean,
  Errors: any,
}

interface HotelRegionStatic {
  HotelId: number,
  HotelName: string,
  Address: string,
  Latitude: number,
  Longitude: number,
  DistanceFromCenter: number,
  DistanceFromPoint: number,
  Stars: number,
  Thumbnail: string,
  Amenities: Amenities,
  Type: string,
  IsSmartHotel: boolean,
  Rating: Rating,
  TravelPolicy: TravelPolicy,
  RegionId: number,
  ChainHotelInfo: any,
  FavoriteId: string,
}

interface Rating {
  Value: number,
  Text: string,
}

interface Amenities {
  Internet: boolean,
  Meal: boolean,
  Pool: boolean,
  Fitness: boolean,
  Parking: boolean,
  Spa: boolean,
  Bath?: boolean,
}

interface ChangedFilters {
  price: boolean,
  priceGuaranteed: boolean,
  smartHotel: boolean,
  hotelName: boolean,
  stars: boolean,
  type: boolean,
  amenities: boolean,
  network: boolean,
  hasCancellation: boolean,
  favoriteId: boolean,
  rating: boolean,
  hasVat: boolean,
  online: boolean,
  breakfast: boolean,
  radius: boolean
  mapIsFilter: boolean,
  viewMode: boolean,
}

interface HotelItemModalMap {
  Address?: string
  Latitude: number,
  Longitude: number,
  IsSmartHotel?: boolean,
  Stars?: number,
  Rating?: Rating,
  MainImageUrl?: string,
  Name?: string,
  ClassificatorId?: number,
  rate?: {
    Price: {
      TotalPrice: number,
    },
  }
}

interface RoomGroup {
  Name: string, // Название рейта
  Rates: Rate[], // Список рейтов
  Images: IImage[], // Изображения номера
  FreeRooms: number, // Количество номеров
  Description: string, // Описание
  BedType: number // Тип по количеству кроватей
  RoomType: number, // Тип комнаты
  IsContract: boolean, // Контрактная группа номеров
  GroupedRates: GroupedRate[], // сгруппированные рейты (ТОЛЬКО ДЛЯ ТЕСТА)
  PriceSortNum: number,
}

interface PrepareRoomGroup extends RoomGroup {
  Rates: PrepareRate[],
}

interface OfflineRoomGroup extends PrepareRoomGroup {
  Price: PriceDetail,
  RateId: string,
}

interface IImage {
  OriginUrl: string,
  ThumbnailUrl: string,
}

interface NoAvailabilityPeriods {
  StartDate: string,
  EndDate: string,
}

interface Rate {
  Name: string, // Название рейта
  BookId: string, // BookId для рейта
  RateId: string, // RateId (из mongodb) для рейта
  Price: PriceDetail, // Детализация цен
  Meal: {
    Included: boolean,
    Category: number,
    Name?: string // Имя провайдера если оно есть
  }, // Детализация завтрака
  Offline: boolean, // Доступен онлайн?
  CancellationPolicy: CancellationPolicy, // Детализация отмены
  TravelPolicy: any, // Тревел политики
  FixedRR: boolean, // Флаг что тупо накинули 8% к рекрейту  нужен для фич
  ProviderName: string, // Название провайдера  ???
  FreeRooms: number // Свободные номера
  IsMin: boolean,
  IsCustom: boolean,
  IsOptimalRate: boolean,
  PropertyFees: { Description: string[] }[], // Доп платы в отеле (сейчас только для провайдера Expedia)
  NoAvailabilityPeriods: NoAvailabilityPeriods[], // Периоды действия рейта для 3д, не попадающие в указанные даты
  IsFR: boolean,
}

interface PrepareRate extends Rate {
  ReservedCount: number,
  TripId?: string | null
  Select?: {
    items: {
      label: number,
      value: number,
    }[],
    count: number | null,
  }
  qaAttr?: string,
  CanBeReserved?: boolean,
  ReserveDeadline?: string,
}

interface PriceDetail {
  Base: number,
  Commission: number,
  TotalPrice: number,
  AgentFee: number,
  NightRate: number,
  HasVAT: boolean,
  VAT: number,
  RecRate: number,
  BestPrice: number,
  ProviderName: string,
  EarlyCheckIn: number,
  LateCheckOut: number,
  IsBooking: boolean,
  IsContract: boolean
}

interface GroupedRate {
  Name: string,
  Rates: OlderRates[],
  BedType: string,
  ProviderName: string,
}

interface OlderRates {
  Base: number,
  RecRate: number,
  IsMin: boolean,
  IsBest: boolean,
  Basket: string,
  FixedRR: boolean,
  IsCustom: boolean,
}

interface CancellationPolicy {
  Refundable: boolean, // Отменяемый?
  DeadLine: string | null, // Дата бесплатной отмены
  Penalties: CancellationPenalty[], // Штрафики за отмену
}

interface CancellationPenalty {
  From: any, // Время начала действия штрафика
  Total: number, // Размер штрафика для клиента
  Additional: boolean, // true - штраф как у провайдера(для нас, клиент не видит)
}

interface ParamsMetrics {
  guid: string
  status: number
  countResult: number,
  searchComplete?: boolean,
  error?: any,
}

interface ParamsHotelMetrics extends ParamsMetrics {
  hotelId: number,
}

interface SearchByParams {
  date_from: string,
  date_to: string
  time_from?: string,
  time_to?: string,
  region_id: string,
  placing_type: string
  t_count: string,
  name: string,
  region_name: string,
  is_foreign: string,
  isFiltersHotelsInMicroservice: boolean,
}

interface RadiusSearchObject {
  date_from: string,
  date_to: string
  time_from?: string,
  time_to?: string,
  t_count: number,
  placing_type: string,
  region_id: number,
  region_name: string,
  name: string,
  coords: number[],
  radius: number,
}

interface StatsArray {
  type:string,
  data: {
    rateId: number,
    hotelName: string,
    hotelId: number,
    hasCancellation: boolean,
    hasMeal: boolean,
    price: number,
    bookId: number,
  },
}

interface RightsBuyTrip {
  AccountId: number,
  EmployeeId: number,
  UserId: number | null,
  Login: string,
  CanLogin: boolean,
  ViewTrips: string,
  BuyTripPersonal: string,
  BuyTripAccount: string,
  Limits: any | null,
  Approve: string,
  Finance: string,
  EditRights: string,
  Notifications: string,
  EditEmployee: string,
  EditTravelPolicies: boolean,
  SendDocuments: boolean,
  CanUseIntercom: boolean,
  ViewExpenseReports: string,
  ViewMiceEvents: string,
  ApproveExpenseReports: string,
  CreateExpenseReports: string,
  CanBook: boolean,
}

interface SearchByParamsSettings {
  rightsBuyTrip: RightsBuyTrip,
  accountTravelPolicy: TravelPolicyItem,
  travelPolicyAllList: TravelPolicyItem[],
}

interface HotelAutocompleteItem {
  FullName: string,
  Id: number,
  IsForeign?: boolean,
  IsRegion: boolean
  Name: string
}

interface HotelAutocompleteObj {
  title: string,
  items: HotelAutocompleteItem[]
}

interface HotelSuggestItem extends HotelAutocompleteItem, HotelAutocompleteObj {}

interface ToNoteHotel extends Rate {
  Select: {
    items: {
      label: number,
      value: number,
    }[],
    count: number
  },
  qaAttr: string,
  TripId?: string
}

interface SearchObjectItem {
  RegionId: number,
  RegionName: string,
  Name: string,
  IsForeign: boolean,
}

interface SearchObjectParams {
  dateFrom: Moment,
  dateTo: Moment,
  travellersCount: number
  placingType: any,
  timeFrom: Moment | null,
  timeTo: Moment | null,
}

interface SearchObject {
  item: SearchObjectItem,
  params: SearchObjectParams,
}

interface GetRadiusSearchObject {
  item: {
    RegionId: number,
    RegionName: string,
    Name: string,
  },
  params: {
    dateFrom: Moment,
    dateTo: Moment,
    travellersCount: number
    placingType: any,
    timeFrom: Moment,
    timeTo: Moment,
    coords: number[],
    radius: number,
  }
}

interface FavoriteObject {
  timeFrom: Moment
  timeTo: Moment
  dateFrom: Moment
  dateTo: Moment
  placingType: any
  travellersCount: number,
}

type PrepareCustomTime = { CheckinTime: string, CheckoutTime: string }
| { time_from: string, time_to: string }
| object;

interface Price {
  border: {
    from: number,
    to: number
  },
  roundBy: number,
  from: number,
  to: number
}

interface Radius {
  value: number,
  custom: boolean
}

interface IDispatchRadius {
  value: Radius,
  isFiltersHotelsInMicroservice?: boolean,
}

interface CustomRadius extends Radius {
  custom: boolean,
}

interface IHotelsFilters {
  SortType: string,
  Rate: {
    PriceTo: number,
    PriceFrom: number,
    Breakfast: boolean,
    HasCancellation: boolean,
    HasVat?: boolean,
    Online: boolean,
    Recommended: boolean,
    TravelPolicy: string,
    Meal: number[],
  }
  Hotel: {
    SmartHotel: boolean,
    PriceGuaranteed: boolean,
    Favorite: boolean,
    Proximity: IHotelsFilterRadius,
    Stars: number[] | null,
    Type: string[] | null,
    Amenities: string[] | null,
    Rating: number[] | null,
    Name: string,
  }
}

interface IHotelsFilterRadius {
  Latitude: number,
  Longitude: number,
  Radius: number,
}

interface Filters {
  price: Price,
  smartHotel: boolean,
  priceGuaranteed: boolean,
  hotelName: string,
  hasCancellation: boolean,
  favoriteId: boolean,
  stars: any,
  type: any,
  network: any,
  travelPolicyList: string[],
  hasVat: boolean,
  selectedTravelPolicy: string,
  amenities: Amenities,
  online: boolean,
  breakfast: boolean,
  radius: Radius,
  rating: { [key: string]: boolean },
  mapIsFilter: {
    value: boolean,
    visibleHotels: number[]
  },
  changed: ChangedFilters,
}

interface Paging {
  total: number,
  current: number,
  count: number,
}

interface ITag {
  name: string
  key: string,
  filter: string,
  readOnly?: boolean,
}

// Hotel
// Рейты по отелям
interface HotelRatesResponse {
  body: HotelRates,
  status: number,
}

interface HotelRates {
  ImportantInfo: {
    AccommodationInfo: string,
    TaxInfo: string,
  },
  RoomGroups: RoomGroup[],
  IsAvailableContract: boolean,
  SearchId: string,
}

interface HotelFilters {
  hasCancellation: boolean,
  online: boolean,
  meal: number[],
}

interface HotelImage {
  Url: string,
  OrigUrl: string,
}

interface AmenitiesItem {
  Name: string,
  Values: string[]
}

interface ReviewItem {
  Title: string,
  Name: string,
  Rating: {
    Value: number,
    Text: string
  },
  Cons: string,
  Pros: string,
  Comment: string,
  IsTripAdvisor: boolean,
  Date: string
}

interface HotelStatic {
  Id: number,
  Name: string,
  Stars: number,
  Phone: string,
  Email: string,
  Description: string,
  DistanceFromCenter: number,
  Address: string,
  CheckInTime: string,
  CheckOutTime: string,
  Thumbnail: string,
  Images: HotelImage[],
  Latitude: number,
  Longitude: number,
  ClassificatorId: number,
  RegionId: string,
  RegionName: string,
  City: string,
  IsSmartHotel: boolean,
  Amenities: AmenitiesItem[],
  Type: string,
  FavoriteId: string,
  CountryCode: string,
  Reviews: {
    Rating: {
      Value: number,
      Text: string
    },
    Reviews: ReviewItem[],
  },
  Deleted: false
}

interface IHotel extends HotelStatic {
  cacheRoomGroups: PrepareRoomGroup[],
  loadingHotel: false,
  RoomGroups: PrepareRoomGroup[],
  TaxInfo?: any | null,
  SearchId?: string,
  tripId?: string,
  IsAvailableContract?: boolean,
  ImportantInfo?: {
    AccommodationInfo: string,
    TaxInfo: string,
  }
  IsPriceGuaranteed?: boolean,
}

interface SearchByHistoryItemParams {
  CheckinDate: string,
  CheckoutDate: string,
  RegionId: number,
  GuestsCount: number,
  Guid: string,
  FromHistory: boolean,
  CheckinTime?: string,
  CheckoutTime?: string
}

interface RadiusSearchParams {
  RegionId: number,
  Latitude: number,
  Longitude: number,
  Radius: number,
}

interface HotelsRecommended extends HotelRegionStatic {
  total: number,
  distance: number,
}

interface QueueItem {
  fn(context?: any): Promise<HotelsRegionResponse>,
  resolve(value: HotelsRegionResponse | PromiseLike<HotelsRegionResponse>): void,
  reject(reason?: any): void
}

// History
interface HistoryObj {
  SearchDate: string,
  CheckinDate: string,
  CheckoutDate: string,
  CheckInTime: string,
  CheckOutTime: string,
  Name: string,
  FullName: string,
  RoomCount: number,
  TravellersCount: number,
  Id: number,
  ItemId: number,
  GuestsCount: number,
  IsRegion: boolean,
}

interface WarningTemplate {
  content: { text: string },
  id: number,
  isActive: boolean,
  type: string,
}

interface HistoryItem {
  RegionName: string,
  RegionId: number,
  Name: string,
  CheckinDate?: Moment,
  CheckoutDate?: Moment,
  CheckInTime?: Moment,
  CheckOutTime?: Moment,
  TravellersCount?: number,
  GuestsCount?: number,
  IsRegion?: boolean,
  Id?: number,
}

// Maps
interface Placemarks {
  Address: string,
  Latitude: number,
  Longitude: number,
  IsSmartHotel: boolean,
  Stars: number,
  Rating?: Rating,
  MainImageUrl?: string,
  Name: string,
  ClassificatorId: number,
  rate?: {
    Price: {
      AgentFee: number;
      TotalPrice: number,
    },
  },
  hideMarkerPrice?: boolean,
}

interface QueryParse {
  AddToHistory?: string,
  CheckinDate?: string,
  CheckoutDate?: string,
  GuestsCount?: string,
  RedirectSearchId?: string,
  RedirectSearchType?: string,
  RegionId?: number,
  RegionName?: string,
  RequestItemId?: string,
  RoomCount?: string,
  TravellersCount?: string,
  breakfast?: string,
  hasCancellation?: string,
  online?: string,
}

interface SearchSettings {
  checkin?: Moment,
  checkout?: Moment,
  customCheckin: Moment | null,
  customCheckout: Moment | null,
  daysCount: number,
  isTextualOpenCustomCheckin: boolean,
  isTextualOpenCustomCheckout: boolean,
}

interface IVoucherTravellers {
  ID: null,
  Name: string,
  FirstName: string,
  Surname: string,
  LastName: string,
  Patronymic: string,
  MobilePhone: string,
}

interface IVoucherTravellersWithId extends IVoucherTravellers {
  id: number;
}

interface IMeal {
  Type: number,
  Include: boolean,
  Name: string,
  Category: number,
  Additional: boolean,
  Price: string,
}

interface HotelItemType {
  RateId: string,
  hotel: {
    Stars: number,
    Name: string,
    Address: string,
    Kind: string,
    City: string,
    Phone: string,
    Email: string,
    CheckinTime: string,
    CheckoutTime: string,
    ClassificatorId: number,
    CountryCode: string,
    RegionId: number,
  },
  raw: {
    ProviderName: string,
    Breakfast: boolean,
    BreakfastName: null | string,
    Hotel: {
      Stars: number,
      Name: string,
      Address: string,
      City: string,
      Phone: string,
      Email: null | string,
      CheckinTime: string,
      CheckoutTime: string,
      CountryCode: string,
      ClassificatorId: string,
    },
    Room: {
      Category: string,
      FreeCancellation: null | object,
      Amenities: any[],
      Image: null,
      CancellationPenalties: any[],
      Meal: IMeal,
    },
    ReservationNumber: string,
    HotelOrderId: null | string,
    IsPayedByVcc: boolean,
    GuestCount: number,
    NightsCount: number,
    NightsPrice: number,
    CheckinDate: string,
    CheckoutDate: string,
    CustomCheckInDate: boolean,
    CustomCheckOutDate: boolean,
    City: string,
    ServiceType: string,
    CompanyId: null | string,
    Name: string,
    FileName: string,
    DocumentItemName: string,
    DocumentItemNameEarlyCheckIn: string,
    DocumentItemNameLateCheckOut: string,
    ReservationDate: string,
    RateId: string,
    NewRateId: string,
    IsCustom: boolean,
    AdditionalInfo: null | string,
    VatDetails: any[],
    PriceDetails: {
      Base: number,
      BasePrice:number,
      Fee: number,
      Tax: number,
      Taxes: number,
      HasVAT: boolean,
      VAT: number,
      Commission: number,
      Total: number,
      EarlyCheckIn: number,
      LateCheckOut: number,
    },
    PartnerOrderId: null,
    VoucherTravellers: IVoucherTravellers[],
  }
  room: {
    Name: string,
    Price: {
      Base: number,
      Commission: number,
      TotalPrice: number,
      NightRate: number,
      HasVAT: boolean,
      VAT: number,
      EarlyCheckIn: number,
      LateCheckOut: number,
    },
    Amenities: {
      HasInternet: boolean,
      Internet: string,
      HasMeal: boolean,
      MealType: string,
      MealName: null | string,
      Bedding: boolean,
      BeddingType: string,
      HasCancellation: boolean,
      CancellationInfo: null | string,
      HasBath: boolean,
      BathType: string,
      AdditionalMeals: any[],
      CancellationPenalties:
      {
        From: string,
        Base: number,
        Total: number,
        Additional: boolean,
      }[]
    }
  },
  checkin: string,
  checkout: string,
  GuestsCount: number,
  SearchId: string,
  Rate: {
    Id: string,
    HotelId: string,
    CheckinDate: string,
    CheckoutDate: string,
    GuestsCount: number,
    Price: {
      Base: number,
      Commission: number,
      TotalPrice: number,
      NightRate: number,
      HasVAT: boolean,
      VAT: number,
      EarlyCheckIn: number,
      LateCheckOut: number,
    },
    RoomName: string,
    RoomGroupName: string,
    BookId: string,
    ProviderName: string,
    CancellationInfo: string,
    FreeRooms: number,
    Amenities: {
      HasInternet: boolean,
      Internet: string,
      HasMeal: boolean,
      MealType: string,
      MealName: null | string,
      Bedding: boolean,
      BeddingType: string,
      HasCancellation: boolean,
      CancellationInfo: string | null,
      HasBath: boolean,
      BathType: string,
      AdditionalMeals: any[],
      CancellationPenalties:
      {
        From: string,
        Base: number,
        Total: number,
        Additional: boolean,
      }[],
      Meal: IMeal,
    },
    Images: {
      Url: string,
      OrigUrl: string,
    }[]
    SearchId: string,
    IsOfflineBooking: boolean,
    IsCustom: boolean,
    Description: string,
    BestPrice: number,
    GroupLetter: string,
    TravelPolicy: {
      Apply: boolean,
      Errors: object,
    },
    CustomCheckInDate: boolean,
    CustomCheckOutDate: boolean,
  }
}

interface IRespRegionByAirport {
  AirCode: string,
  RegionId: string,
  RegionName: string,
}

export { HOTEL_RESPONSE_STATUS };

export type {
  InitSearchType,
  PrepareAutocompleteStatsType,
  SearchHotelsResponse,
  HotelsRegionResponse,
  HotelsRegion,
  Variant,
  PreparedVariant,
  ParamsMetrics,
  ParamsHotelMetrics,
  HotelRatesResponse,
  HotelRates,
  StatsArray,
  SearchByParams,
  RadiusSearchObject,
  HotelAutocompleteItem,
  ToNoteHotel,
  SearchObject,
  GetRadiusSearchObject,
  FavoriteObject,
  SearchByParamsSettings,
  HistoryObj,
  PrepareCustomTime,
  Filters,
  Paging,
  ITag,
  Amenities,
  RegionRate,
  HotelRegionStatic,
  Price,
  ChangedFilters,
  RoomGroup,
  Rate,
  PrepareRate,
  PrepareRoomGroup,
  HotelFilters,
  HotelStatic,
  SearchByHistoryItemParams,
  RadiusSearchParams,
  HotelsRecommended,
  QueueItem,
  HotelItemModalMap,
  HotelAutocompleteObj,
  OfflineRoomGroup,
  RightsBuyTrip,
  Region,
  Favorite,
  RegionLocation,
  IHotelsStore,
  IHotelStore,
  CustomRadius,
  IHotel,
  IImage,
  ISearchStore,
  HistoryItem,
  HotelSuggestItem,
  SearchObjectItem,
  SearchObjectParams,
  HotelImage,
  ReviewItem,
  Placemarks,
  QueryParse,
  SearchSettings,
  HotelItemType,
  ISearchHotelBody,
  IDisplayFilters,
  IHotelsFilters,
  RecommendRegionRate,
  IDispatchRadius,
  IOptimalRateQueryParams,
  IVoucherTravellers,
  IVoucherTravellersWithId,
  WarningTemplate,
  NoAvailabilityPeriods,
  IRespRegionByAirport,
};
