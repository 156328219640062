import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Text, Datepicker, Input, Select, IconButton } from 'new-ui';

import { getText } from '../../../../i18n';

import { useStores } from '../../../bi/context';
import { MOBX_STORES } from '../../../bi/context/stores';

import { defaultPattern, momentObject } from '../../../bi/utils/formatDate';
import { findMatchingElement } from '../../../bi/utils/travelApproval';
import { getEmployeeCompaniesWithDepartments } from '../../../bi/utils/approval';

import { FIELD_APPROVAL_REQUEST } from '../../../bi/constants/approve';

import { NumberInput } from '../../../components/NumberInput';
import { FieldLabel } from '../../../components/FieldLabel';
import { TripTags } from '../../../components/TripTags';
import AnalyticsSelect from '../../../components/AnalyticsSelect';

import { ApprovalHistoryPopUp } from './ApprovalHistoryPopUp';
import { SelectCity } from './SelectCity';
import SelectEmployee from './SelectEmployee';

import {
  EmployeeProjectsType, PurposeType,
  TravelApprovalEmployeeType,
  TravelApprovalType,
} from '../../../bi/services/travelApproval/consts';
import CustomAnalyticsService from '../../../bi/services/customAnalytics';
import Hotels from '../../../bi/services/hotels';
import FeatureFlagsService from '../../../bi/services/featureFlags';
import UserSession from '../../../bi/services/userSession';
import Workspace from '../../../bi/services/workspace';
import TravelApproval from '../../../bi/services/travelApproval';
import ITripTags from '../../../bi/services/tripTags';
import { AnalyticsValueType } from '../../../bi/services/customAnalytics/types';
import { DEFAULT_PURPOSE } from '../../../bi/constants/travelApproval';
import { RequestInterface } from '../../../bi/types/applicationScheme';
import { IApprovalPopUp, PurposeInterface } from '../../../bi/types/travelApproval';
import { EmployeeType } from '../../../bi/services/employee/consts';

import styles from '../styles/index.module.css';

const ADD_ITEM = {
  label: getText('components:travelApproval.other'),
  value: null,
};

const LABELS = {
  ADD_EMPLOYEE: getText('components:travelApproval.addEmployee'),
  ADD_INTERMEDIATE_CITY: getText('components:travelApproval.addIntermediateCity'),
};

const FIELDS_KEYS = {
  DestinationCity: 'IsRequiredDestinationCity',
  Dates: 'IsRequiredDates',
  Budget: 'IsRequiredBudget',
  Purpose: 'IsRequiredPurpose',
  Comment: 'IsRequiredComment',
};

interface GeneralBlockProps {
  region: object,
  travelApproval: TravelApprovalType,
  approvalPopUp: IApprovalPopUp | null,
  applicationSchemeTemplate: RequestInterface,
  purposes: typeof DEFAULT_PURPOSE,
  employeesWithRights: EmployeeProjectsType[],
  readOnly: boolean,
  isMulti: boolean,
  isNew: boolean,
  disabledField: boolean,
  disabledToUpdateField: boolean,
  employeeId: number,
  getEmployees(value: string, flag?: boolean): Promise<EmployeeType[]>;
  onAddEmployee(item: TravelApprovalEmployeeType, index: number): Promise<void>,
  onRemoveEmployee(item: TravelApprovalEmployeeType, index: number): void,
  onAddEmployeeCompany: any,
  userSessionService: UserSession,
  customAnalyticsService: CustomAnalyticsService,
  hotelsService: Hotels,
  workspaceService: Workspace,
  tripTagsService: ITripTags,
  featureFlagsService: FeatureFlagsService,
  travelApprovalService: TravelApproval,
  onAddCustomAnalytic: (id: number, value: string) => void,
  onAddProject: (projectId: number | null, employeeId: number) => void,
  qaAttr?: {
    date: {
      from: string,
      to: string,
    },
    city: {
      input: string,
      suggestFirst: string,
    },
    budget: string,
    purposeSelect: string,
    comment: string
  },
}

const GeneralBlock = observer(({
  region,
  travelApproval,
  approvalPopUp,
  applicationSchemeTemplate,
  purposes,
  employeesWithRights,
  readOnly,
  isMulti,
  isNew,
  disabledField,
  disabledToUpdateField,
  employeeId,
  getEmployees,
  onAddEmployee,
  onRemoveEmployee = () => {},
  onAddEmployeeCompany = () => {},
  userSessionService,
  customAnalyticsService,
  hotelsService,
  workspaceService,
  tripTagsService,
  featureFlagsService,
  travelApprovalService,
  onAddCustomAnalytic,
  onAddProject,
  qaAttr,
}: GeneralBlockProps) => {
  const [isShowSelect, setIsShowSelect] = useState<boolean>(true);
  const [projects, setProjects] = useState([]);
  const inputRef = useRef();

  const { travelApprovalStore } = useStores([MOBX_STORES.TRAVEL_APPROVAL_STORE]);

  useEffect(() => {
    const { Employees, Purpose } = travelApproval;
    const isInputPurpose = isNew || (typeof Purpose === 'object' && !Purpose?.Name) ? true : !!purposes.find(({ label }) => label === Purpose?.Name);

    setIsShowSelect(isInputPurpose);

    const foundEmployee = employeesWithRights.find(({ Id }) => Id === employeeId) || null;

    const { projects: userProjects = [] } = userSessionService.store.getState();

    setProjects(userProjects);

    if (!Employees.length && foundEmployee && readOnly) {
      onAddEmployee(foundEmployee, 0).then(() => {
        const employeeCompaniesWithDepartments = getEmployeeCompaniesWithDepartments(foundEmployee.Companies || []);

        onAddEmployeeCompany(foundEmployee.Id, employeeCompaniesWithDepartments[0].value);
      });
    }
  }, []);

  const isRequiredField = (fieldLabel: keyof RequestInterface) => applicationSchemeTemplate[fieldLabel];
  // @ts-ignore
  const handleFocus = () => inputRef?.current?.focus();

  const handleChangeBlur = () => {
    const { Purpose } = travelApproval;

    if (!Purpose) {
      setIsShowSelect(true);
    }
  };

  const handleChangeSelect = (purposeValue: number | string) => {
    const { purposeChange } = travelApprovalService;

    if (purposeValue === ADD_ITEM.value) {
      setIsShowSelect(false);
    }

    if (!purposeValue && !isShowSelect) {
      setIsShowSelect(true);
    }

    return purposeChange(purposeValue as unknown as PurposeType);
  };

  const renderTripTags = () => {
    const { Tags: selectedTripTags } = travelApproval;

    return (
      <TripTags
        noeditable={ disabledField }
        tripTagsService={ tripTagsService }
        selectedTags={ selectedTripTags }
        // @ts-ignore
        onChangeTags={ travelApprovalService.setTripTags }
        store={ tripTagsService.store }
        workspaceService={ workspaceService }
      />
    );
  };

  const renderAnalytics = () => {
    const { Analytics } = travelApproval;
    const { setCustomAnalytic, unSetCustomAnalytic } = travelApprovalService;
    const { store: { sortedCustomAnalytics } } = customAnalyticsService;

    const onlyNewAnalytics = featureFlagsService.getOnlyNewAnalytics();
    let alreadyHighlighted = false;

    return sortedCustomAnalytics.map((analytics) => {
      const customAnalyticsValue = analytics?.Values.find(({ Id }) => Analytics.includes(Id));

      const highlighted = !alreadyHighlighted && analytics.Required && !customAnalyticsValue;

      if (highlighted) {
        alreadyHighlighted = true;
      }

      return (
        <div key={ analytics.Id }>
          <AnalyticsSelect
            highlighted={ highlighted }
            onlyNewAnalytics={ onlyNewAnalytics }
            value={ customAnalyticsValue as AnalyticsValueType }
            readonly={ disabledField }
            analytics={ analytics }
            onSet={ setCustomAnalytic }
            onUnset={ unSetCustomAnalytic }
            onAdd={ onAddCustomAnalytic }
            displaySearchIcon
          />
        </div>
      );
    });
  };

  const renderData = () => {
    const { changeStartDate, changeEndDate } = travelApprovalService;
    const { StartDate, EndDate, MinDate } = travelApproval;

    const isRequired = isRequiredField(FIELDS_KEYS.Dates as keyof RequestInterface);
    const normalizedStartDate = StartDate ? momentObject(StartDate) : null;
    const normalizedEndDate = EndDate ? momentObject(EndDate) : null;
    const normalizedMinDate = momentObject(MinDate);
    const normalizedMinEndDate = normalizedStartDate || normalizedMinDate;
    const dateDisabled = disabledField ? styles.disabled_field : '';

    return (
      <div>
        <FieldLabel text={ FIELD_APPROVAL_REQUEST.DATES } mustHave={ !!isRequired } />
        <div className={ `${styles.dates}` }>
          <Datepicker
            type='date'
            disabled={ disabledField }
            inputClassName={ `${styles.input} ${styles.input_start_date}` }
            wrapperClassName={ styles.from }
            value={ normalizedStartDate }
            min={ normalizedMinDate }
            format={ defaultPattern }
            onChange={ changeStartDate }
            isDuration
            durationDates={ [normalizedStartDate, normalizedEndDate] }
            qaAttr={ qaAttr?.date.from || '' }
          />
          <div className={ `${styles.date} ${dateDisabled}` }>—</div>
          <Datepicker
            type='date'
            disabled={ disabledField }
            direction='to'
            inputClassName={ `${styles.input} ${styles.input_end_date}` }
            wrapperClassName={ styles.to }
            value={ normalizedEndDate }
            min={ normalizedMinEndDate }
            format={ defaultPattern }
            onChange={ changeEndDate }
            isDuration
            durationDates={ [normalizedStartDate, normalizedEndDate] }
            qaAttr={ qaAttr?.date.to || '' }
          />
        </div>
      </div>
    );
  };

  const renderCity = () => {
    const {
      setSuggestValue,
      addIntermediateCity,
      deleteIntermediateCity,
      setRemoveSuggestValue,
    } = travelApprovalService;
    const { autocompleteSchemeHotel } = hotelsService;
    const isRequired = isRequiredField(FIELDS_KEYS.DestinationCity as keyof RequestInterface);

    return (
      <SelectCity
        travelApproval={ travelApproval }
        region={ region }
        isRequired={ isRequired }
        onSuggestSelected={ setSuggestValue }
        onDeleteSelected={ setRemoveSuggestValue }
        onGetSuggests={ autocompleteSchemeHotel }
        disabledField={ disabledField }
        onAddIntermediateCity={ addIntermediateCity }
        onDeleteIntermediateCity={ deleteIntermediateCity }
        qaAttrCity={ qaAttr?.city || '' }
      />
    );
  };

  const renderBudget = () => {
    const { Budget } = travelApproval;
    const { inputBudgetChange } = travelApprovalService;

    const isRequired = isRequiredField(FIELDS_KEYS.Budget as keyof RequestInterface);
    const budgetDisabled = disabledField ? styles.disabled_field : '';

    return (
      <div>
        <FieldLabel text={ FIELD_APPROVAL_REQUEST.BUDGET_TRIP } mustHave={ !!isRequired } />
        <NumberInput
          value={ Budget as unknown as string }
          onChange={ inputBudgetChange }
          className={ `${styles.budget_input} ${budgetDisabled}` }
          onRender={ () => {} }
          disabled={ disabledField }
          qaAttr={ qaAttr?.budget || '' }
        />
      </div>
    );
  };

  const renderSelectItem = ({ label, value: v }: { label: string, value: string }) => {
    if (v === ADD_ITEM.value) {
      return (
        <div className={ styles.add }>
          <div className={ styles.add_label }>{ADD_ITEM.label}</div>
        </div>
      );
    }

    return label;
  };

  const renderPurposeField = () => {
    const { Purpose } = travelApproval;

    // @ts-ignore
    const newPurposes = purposes.concat(ADD_ITEM);
    const purposeDisabled = disabledField ? styles.disabled_field : '';
    const currentPurpose = Number.isInteger(Purpose) ? Purpose : findMatchingElement(Purpose as PurposeInterface, purposes);
    const inputPurpose = typeof Purpose === 'object' ? Purpose?.Name : Purpose;

    return isShowSelect && !disabledField ? (
      <Select
        value={ currentPurpose as string }
        theme='default-border'
        items={ newPurposes }
        alwaysIncluded={ [ADD_ITEM.value as unknown as string] }
        renderItem={ renderSelectItem }
        onChange={ handleChangeSelect }
        qaAttr={ qaAttr?.purposeSelect || '' }
      />
    ) : (
      <Input
        autoFocus
        className={ `${styles.budget_input} ${purposeDisabled}` }
        value={ inputPurpose as string }
        // @ts-ignore
        ref={ inputRef }
        disabled={ disabledField }
        onChange={ handleChangeSelect }
        onFocus={ handleFocus }
        onBlur={ handleChangeBlur }
      />
    );
  };

  const renderPurpose = () => {
    const isRequired = isRequiredField(FIELDS_KEYS.Purpose as keyof RequestInterface);

    return (
      <div>
        <FieldLabel text={ FIELD_APPROVAL_REQUEST.PURPOSE } mustHave={ !!isRequired }/>
        { renderPurposeField() }
      </div>
    );
  };

  const renderEmployee = () => {
    const { projectsEmployees, projectEditable, travelApproval: { Status } } = travelApprovalStore;

    const { Employees } = travelApproval;
    const selectEmployeeHtml = (item: TravelApprovalEmployeeType, index: number) => (
      <SelectEmployee
        index={ index }
        number={ index + 1 }
        key={ `employee_${item?.Id}_${index}` }
        items={ Employees }
        item={ Object.keys(item).length !== 0 ? item : null }
        disabledField={ disabledField }
        updateLock={ disabledToUpdateField }
        isMulti={ isMulti }
        getEmployees={ getEmployees }
        onAddCompany={ onAddEmployeeCompany }
        onAddEmployee={ employee => onAddEmployee(employee, index) }
        onRemoveEmployee={ employee => onRemoveEmployee(employee, index) }
        onAddProject={ onAddProject }
        projects={ projects }
        projectEditable={ projectEditable }
        // @ts-ignore
        employees={ projectsEmployees }
        approvalStatus={ Status }
      />
    );

    return Employees.length ? (
      <div className={ styles.employee }>
        { Employees.map(selectEmployeeHtml) }
      </div>
    ) : (
      <div className={ styles.employee }>
        <SelectEmployee
          index={ 0 }
          number={ 1 }
          items={ Employees }
          disabledField={ disabledField }
          isMulti={ isMulti }
          getEmployees={ getEmployees }
          onAddEmployee={ employee => onAddEmployee(employee, 0) }
          onRemoveEmployee={ employee => onRemoveEmployee(employee, 0) }
          onAddCompany={ onAddEmployeeCompany }
          onAddProject={ onAddProject }
          projects={ projects }
          projectEditable={ projectEditable }
          // @ts-ignore
          employees={ projectsEmployees }
          approvalStatus={ Status }
        />
      </div>
    );
  };

  const renderEmployees = () => {
    const { projectEditable, projectsEmployees } = travelApprovalStore;
    const { Employees, Status } = travelApproval;
    const { addMoreEmployee } = travelApprovalService;
    const finderEmployee = employeesWithRights.find(({ Id }) => Id === employeeId) || null;

    const addButton = !!Employees.length && !readOnly && !disabledToUpdateField && (
      <IconButton
        iconType='plusRound'
        className={ styles.button }
        theme='default-red'
        onClick={ addMoreEmployee }
      >
        { LABELS.ADD_EMPLOYEE }
      </IconButton>
    );

    return finderEmployee && readOnly && Employees.length && !disabledToUpdateField ? (
      <div className={ styles.employee }>
        <SelectEmployee
          readOnly
          index={ 0 }
          number={ 1 }
          item={ Employees[0] }
          isMulti={ isMulti }
          onAddCompany={ onAddEmployeeCompany }
          projects={ projects }
          onAddProject={ onAddProject }
          projectEditable={ projectEditable }
          // @ts-ignore
          employees={ projectsEmployees }
          disabledField={ disabledField }
          approvalStatus={ Status }
        />
      </div>
    ) : (
      <div className={ styles.main }>
        { renderEmployee() }
        { addButton }
      </div>
    );
  };

  const renderComment = () => {
    const { commentChange } = travelApprovalService;
    const { Comment } = travelApproval;

    const isRequired = isRequiredField(FIELDS_KEYS.Comment as keyof RequestInterface);
    const commentDisabled = disabledToUpdateField ? styles.disabled_field : '';

    return (
      <div>
        <FieldLabel text={ FIELD_APPROVAL_REQUEST.COMMENT } mustHave={ !!isRequired }/>
        <Input
          value={ Comment }
          onChange={ commentChange }
          disabled={ disabledToUpdateField }
          className={ commentDisabled }
          qaAttr={ qaAttr?.comment || '' }
        />
      </div>
    );
  };

  const { Approves } = travelApproval;

  return (
    <>
      <div className={ styles.general_block_header }>
        <Text type='bold_24'>
          { FIELD_APPROVAL_REQUEST.GENERAL }
        </Text>
        <ApprovalHistoryPopUp travelApproval={ Approves } approvalPopUp={ approvalPopUp }/>
      </div>
      <div className={ styles.general_panel }>
        { renderCity() }
        { renderData() }
        { renderPurpose() }
        { renderBudget() }
        { renderComment() }
        { renderEmployees() }
        { renderAnalytics() }
        { renderTripTags() }
      </div>
    </>
  );
});

export default GeneralBlock;
