import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Dialog, Text, Input, Price, Button, Checkbox, CircleLoading } from 'new-ui';

import { getText } from '../../../i18n';

import { useServices } from '../../bi/context/services';

import { useStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import { PersonalPaymentProtocol } from '../../bi/protocols/personalPayment';

import { FieldLabel } from '../FieldLabel';
import { LoadingFields } from '../../bi/services/cloudPayment';

import styles from './index.module.css';

const LABEL = {
  AMOUNT_OF_DEPOSIT: getText('payment:topUpPersonalAccount.amountDeposit'),
  TOP_UP_PERSONAL_ACCOUNT: getText('payment:topUpPersonalAccount.topUpPersonalAccount'),
  TOP_UP: (amount: string | number) => getText(
    'payment:topUpPersonalAccount.topUp',
    { amount, formatParams: { amount: { currency: 'RUB' } } },
  ),
  CANCEL: getText('payment:topUpPersonalAccount.cancel'),
  CARD_EXP: getText('payment:topUpPersonalAccount.cardExp'),
  CARD_NUMBER: getText('payment:topUpPersonalAccount.cardNumber'),
  SEND_RECEIPT_TO_EMAIL: getText('payment:topUpPersonalAccount.sendReceiptToEmail'),
  COMMISSION: getText('payment:topUpPersonalAccount.commission'),
  COMMISSION_ERROR: getText('payment:topUpPersonalAccount.commissionError'),
  CVC: getText('payment:topUpPersonalAccount.cvc'),
};

interface PaymentWidgetProps {
  onCancel(): void,
  onChangeDialog: (value: boolean) => void,
  personalPaymentProtocol: PersonalPaymentProtocol,
  isOpen: boolean,
}

export const PaymentWidget = observer(({
  onCancel,
  isOpen,
  onChangeDialog,
}: PaymentWidgetProps) => {
  const {
    cloudPaymentService,
    cloudPaymentService: { store, networkStatesStore },
    workspaceService,
  } = useServices(['CloudPayment', 'Workspace']);
  const [sendToEmail, setSendToEmail] = useState(false);
  const {
    paymentMethodStore: {
      amountOfDeposit,
      email,
    },
  } = useStores([MOBX_STORES.PAYMENT_METHOD]);

  const commission = store.getCommission;

  const handleChangeAmountOfDeposit = (value: string) => {
    cloudPaymentService.onChangeAmountOfDeposit(value);
  };

  const handleChangeEmail = (value: string) => {
    cloudPaymentService.onChangeEmail(value);
  };

  const handleSubmit = () => {
    cloudPaymentService.callPaymentFormTopUpPersonal();
    onChangeDialog(false);
  };

  const handleSwitchEmailInput = () => {
    const newValue = !sendToEmail;
    setSendToEmail(newValue);
    handleChangeEmail(newValue ? workspaceService.AccountEmail : '');
  };

  const renderCommissionLoading = () => networkStatesStore.getIsStatus(
    LoadingFields.calculateCommision,
    networkStatesStore.loadingStatuses.LOADING,
  ) && <CircleLoading size={ 14 } />;

  const renderCommission = () => {
    if (store.isCommissionError) {
      return (
        <div className={ styles.flexOneLine }>
          <Text type={ 'NORMAL_14' } className={ styles.commission_error }>
            { LABEL.COMMISSION_ERROR }
          </Text>
          { renderCommissionLoading() }
        </div>
      );
    }

    return (
      <div className={ styles.column_align_left }>
        <FieldLabel text={ LABEL.COMMISSION } />
        <div className={ styles.flexOneLine }>
          <Price
            value={ commission }
            type='NORMAL_14'
            typeCurrency='NORMAL_14'
          />
          { renderCommissionLoading() }
        </div>
      </div>
    );
  };

  const renderEmailInput = () => {
    const emailInputHtml = sendToEmail
      ? <Input value={ email } onChange={ handleChangeEmail } error={ store.errorsForm.email } />
      : null;

    return (
      <div className={ `${styles.column_align_left} ${styles.full_width}` }>
        <div className={ styles.contact_inputs }>
          <Checkbox
            value={ sendToEmail }
            onChange={ handleSwitchEmailInput }
          >
            <Text>{ LABEL.SEND_RECEIPT_TO_EMAIL }</Text>
          </Checkbox>
        </div>
        { emailInputHtml }
      </div>
    );
  };

  const renderTopUpAmount = () => (
    <div className={ `${styles.column_align_left} ${styles.full_width}` }>
      <FieldLabel
        text={ LABEL.AMOUNT_OF_DEPOSIT }
        mustHave
      />
      <Input
        value={ amountOfDeposit }
        onChange={ handleChangeAmountOfDeposit }
        error={ store.errorsForm.amount }
      />
    </div>
  );

  const hasFormErrors = sendToEmail
    ? store.disableSubmit.withEmail
    : store.disableSubmit.withoutEmail;

  const disableSubmit = (store.getCommission <= 0) || (store.isCommissionError) || hasFormErrors;

  const loading = networkStatesStore.getIsStatus(LoadingFields.calculateCommision, cloudPaymentService.networkStatesStore.loadingStatuses.LOADING);
  const submitBtnText = LABEL.TOP_UP((!store.errorsForm.amount && commission > 0) ? store.getComputedAmount : 0);

  return (
    <Dialog
      show={ isOpen }
      showClosing
      onChange={ onChangeDialog }
    >
      <div className={ styles.widget_wrapper }>
        <Text className={ styles.header } type='SEMIBOLD_18'>{ LABEL.TOP_UP_PERSONAL_ACCOUNT }</Text>
        { renderTopUpAmount() }
        { renderCommission() }
        <div className={ styles.contact_inputs }>
          { renderEmailInput() }
        </div>
        <div className={ `${styles.contact_inputs} ${styles.full_width}` }>
          <Button
            disabled={ disableSubmit }
            type='secondary'
            onClick={ handleSubmit }
            loading={ loading }
          >
            { submitBtnText }
          </Button>
          <Button
            type='textual'
            onClick={ onCancel }
            disabled={ loading }
          >
            { LABEL.CANCEL }
          </Button>
        </div>
      </div>
    </Dialog>
  );
});
