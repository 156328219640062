import React from 'react';
import MediaQuery from 'react-responsive';

import { getText } from '../../../../i18n';

import ItemWrap from '../../Header/components/ItemWrap';

import { DEFAULTMENUTYPE, DEFAULTMENUTYPEE, ICON_TYPES_MAP } from '../../../bi/constants/app';
import ROUTES from '../../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../../bi/constants/attributesForTests';
import { MAIN_MENU_WRAPPER } from '../../../bi/constants/elementsIds';

import { isSmartAgent } from '../../../bi/utils/env';

import FeatureFlags from '../../../bi/services/featureFlags';

import { IExtendedMenuItem, IMenuItems } from '../../../bi/types/app';

import styles from './index.module.css';

const getItemStyles = (menuType: string) => {
  switch (menuType) {
    case DEFAULTMENUTYPE.SEARCH: {
      return styles.dropdown_wrapper_search;
    }
    case DEFAULTMENUTYPE.WAITINGAPPROVE: {
      return `${styles.dropdown_wrapper_search} ${styles.dropdown_wrapper_waiting}`;
    }
    case DEFAULTMENUTYPE.ACCOUNTING: {
      return `${styles.dropdown_wrapper_search} ${styles.dropdown_wrapper_accounting}`;
    }
    case DEFAULTMENUTYPE.TRIPS: {
      return `${styles.item} ${styles.wrapper_trips}`;
    }
    case DEFAULTMENUTYPE.APPLICATION: {
      return `${styles.dropdown_wrapper_search} ${styles.dropdown_wrapper_application}`;
    }
    case DEFAULTMENUTYPE.EVENT: {
      return `${styles.dropdown_wrapper_search} ${styles.wrapper_event}`;
    }

    default: {
      if (isSmartAgent) {
        return styles.item_sa;
      }

      return styles.item;
    }
  }
};

const LABELS = {
  qualityControl: getText('components:header.headerItems.qualityControl'),
  NOTE: getText('components:header.headerItems.note'),
  settings: getText('components:header.settings'),
  exit: getText('components:header.exit'),
  favorites: getText('components:header.headerItems.favorites'),
  travelHelper: getText('components:header.headerItems.travelHelper'),
  INTERCOM_WARNING: {
    ONE: getText('components:header.headerItems.intercomWarning.one'),
    TWO: getText('components:header.headerItems.intercomWarning.two'),
    THREE: getText('components:header.headerItems.intercomWarning.three'),
  },
  INTERCOM_WARNING_SA: {
    ONE: getText('components:header.headerItems.intercomWarning.one'),
    TWO: getText('components:header.headerItems.intercomWarning.two'),
    THREE: getText('components:header.headerItems.intercomWarning.three'),
  },
};

interface IMainMenuProps {
  menuItems: IMenuItems[];
  additionalMenuItems?: IExtendedMenuItem[];
  isTripsDropdown?: boolean;
  onClick(item: { count: number, title: string, type: string }): void;
  featureFlagsService: FeatureFlags;
  noteItemsCount: number;
  badgeEvent?: boolean;
}

const MainMenu = ({
  additionalMenuItems = [],
  isTripsDropdown = false,
  menuItems,
  featureFlagsService,
  featureFlagsService: {
    getShowCalculatorMice,
  },
  onClick,
  noteItemsCount,
  badgeEvent = false,
}: IMainMenuProps) => {
  const renderMenuItem = (item: IMenuItems | IExtendedMenuItem) => {
    const { count, type, url, title = '', qaAttr } = item;
    const qaAttrCartCounter = type === DEFAULTMENUTYPE.CART ? QA_ATTRIBUTES.header.cartCounter : '';
    const kindOfType = (type === DEFAULTMENUTYPE.TRIPS && isTripsDropdown) || type === DEFAULTMENUTYPE.CHARTS_ANALYTICS;
    const isShowBadge = ('showBadge' in item && item?.showBadge && kindOfType)
      || (type === DEFAULTMENUTYPE.EVENT && !badgeEvent && getShowCalculatorMice());

    const titleResponsive = () => {
      if (type === DEFAULTMENUTYPE.CART) {
        return (
          <MediaQuery minWidth={ 700 }>
            { title }
          </MediaQuery>
        );
      }

      if (type === DEFAULTMENUTYPE.SEARCH ||
        type === DEFAULTMENUTYPE.WAITINGAPPROVE ||
        (type === DEFAULTMENUTYPE.TRIPS && 'items' in item && item?.items.length)) {
        return (
          <MediaQuery minWidth={ 920 }>
            { title }
          </MediaQuery>
        );
      }

      return (
        <MediaQuery minWidth={ 1230 }>
          { title }
        </MediaQuery>
      );
    };

    const items = 'items' in item ? item.items : [];

    const itemHtml = (
      <ItemWrap
        count={ count }
        showBadge={ isShowBadge }
        onClick={ () => onClick({ count: count as number, title, type }) }
        href={ url }
        label={ titleResponsive() }
        items={ items }
        type={ type }
        getIcon={ (t: DEFAULTMENUTYPEE) => ICON_TYPES_MAP[t] }
        mainMenu
        className={ styles[type] }
        classNameWrapper={ styles.analytics }
        getShowElementForSmartagent={ featureFlagsService.getShowElementForSmartagent() }
        qaAttr={ qaAttr }
        qaAttrCounter={ qaAttrCartCounter }
      />
    );

    const itemStyles = getItemStyles(type);

    return (
      <div className={ itemStyles } key={ type }>
        { itemHtml }
      </div>
    );
  };

  const handleClickNote = (value: string) => onClick({ title: LABELS.NOTE, count: noteItemsCount, type: value });

  const renderNote = () => {
    if (!isSmartAgent || !featureFlagsService.getShowElementForSmartagent()) {
      return null;
    }

    const label = (
      <MediaQuery minWidth={ 750 }>
        { LABELS.NOTE }
      </MediaQuery>
    );

    return (
      <div>
        <ItemWrap
          subItem
          count={ noteItemsCount }
          href={ ROUTES.NOTE.MAIN }
          getIcon={ () => 'menuNote' }
          label={ label }
          onClick={ handleClickNote }
          className={ styles['note-link'] }
          getShowElementForSmartagent={ featureFlagsService.getShowElementForSmartagent() }
          qaAttr={ QA_ATTRIBUTES.header.note }
          qaAttrCounter={ QA_ATTRIBUTES.header.noteCounter }
        />
      </div>
    );
  };

  const menuHtml = menuItems.map(renderMenuItem);
  const additionalMenu = !!additionalMenuItems.length && additionalMenuItems.map(renderMenuItem);

  return (
    <div className={ styles.wrapper } id={ MAIN_MENU_WRAPPER }>
      <div className={ styles.wrap }>
        <div className={ styles.basis }>
          { menuHtml }
        </div>
        <div className={ styles.additional }>
          { renderNote() }
          { additionalMenu }
        </div>
      </div>
    </div>
  );
};

export { MainMenu };
