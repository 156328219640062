import { StyledWrapper, Text } from 'new-ui';
import React from 'react';

import {
  CUSTOM_BANNER_BUR_SERVICE,
  CUSTOM_BANNER_VMZ_OMK,
  CustomBannerAccountIds,
} from './constants';

import styles from './index.module.css';

interface CustomBannerProps {
  isExternalLink: boolean;
  accountId: number | null;
}

export const CustomBanner = ({ isExternalLink, accountId }: CustomBannerProps) => {
  if (!isExternalLink || accountId === null) return null;

  const getBannerContent = () => {
    switch (accountId) {
      case CustomBannerAccountIds.BUR_SERVICE:
        return CUSTOM_BANNER_BUR_SERVICE;

      case CustomBannerAccountIds.VMZ_OMK:
        return CUSTOM_BANNER_VMZ_OMK;

      default:
        return null;
    }
  };

  const bannerContent = getBannerContent();

  if (!bannerContent) return null;

  const renderBannerContent = () => bannerContent.map(({ text, link }) => {
    if (link) {
      return (
        <a href={ link } target='_blank' rel='noreferrer' key={ link }>
          { text }
        </a>
      );
    }

    return text;
  });

  return (
    <StyledWrapper className={ styles.custom_banner }>
      <Text type='NORMAL_16' >
        { renderBannerContent() }
      </Text>
    </StyledWrapper>
  );
};
