/* eslint-disable quote-props */
import { getText } from '../../../i18n';

const LABELS = {
  DOUBLES: getText('constants:trainNames.doubles'),
  PALMIRA_DOUBLES: getText('constants:trainNames.palmiraDoubles'),
  ALLEGRO: getText('constants:trainNames.allegro'),
  ARKTIKA: getText('constants:trainNames.arktika'),
  BARGUZIN: getText('constants:trainNames.barguzin'),
  BASHKORTOSTAN: getText('constants:trainNames.bashkortostan'),
  BELOGORYE: getText('constants:trainNames.belogorye'),
  BELYE_NOCHI: getText('constants:trainNames.belyeNochi'),
  VOLGA: getText('constants:trainNames.volga'),
  VOLGOGRAD: getText('constants:trainNames.volgograd'),
  VORONEZH: getText('constants:trainNames.voronezh'),
  VYATKA: getText('constants:trainNames.vyatka'),
  GILYU: getText('constants:trainNames.gilyu'),
  DEMIDOVKIY: getText('constants:trainNames.demidovkiy'),
  DNEVNOY: getText('constants:trainNames.dnevnoy'),
  ENISEY: getText('constants:trainNames.enisey'),
  JIGULI: getText('constants:trainNames.jiguli'),
  IVAN: getText('constants:trainNames.ivan'),
  INGUSHETIYA: getText('constants:trainNames.ingushetiya'),
  IRTYSH: getText('constants:trainNames.irtysh'),
  ITALMAS: getText('constants:trainNames.italmas'),
  KRASNAYA_STRELA: getText('constants:trainNames.krasnayaStrela'),
  KRASNUY_YAR: getText('constants:trainNames.krasnuyYar'),
  KUZBASS: getText('constants:trainNames.kuzbass'),
  LEV_TOLSTOY: getText('constants:trainNames.levTolstoy'),
  LIPETSK: getText('constants:trainNames.lipetsk'),
  LOTOS: getText('constants:trainNames.lotos'),
  MALAHIT: getText('constants:trainNames.malahit'),
  MARIY_EL: getText('constants:trainNames.mariyEl'),
  MORDOVIYA: getText('constants:trainNames.mordoviya'),
  NIZHEGORODEC: getText('constants:trainNames.nizhegorodec'),
  NOVOKUZNETSK: getText('constants:trainNames.novokuznetsk'),
  OB: getText('constants:trainNames.ob'),
  OCEAN: getText('constants:trainNames.ocean'),
  OMICH: getText('constants:trainNames.omich'),
  ORENBURZHYE: getText('constants:trainNames.orenburzhye'),
  POVOLZHYE: getText('constants:trainNames.povolzhye'),
  POLYARNAY_STRELA: getText('constants:trainNames.polyarnayStrela'),
  POMORYE: getText('constants:trainNames.pomorye'),
  PREMIUM: getText('constants:trainNames.premium'),
  PRISKOLYE: getText('constants:trainNames.priskolye'),
  PSKOV: getText('constants:trainNames.pskov'),
  ROSSIA: getText('constants:trainNames.rossia'),
  SAMARA: getText('constants:trainNames.samara'),
  PAMIRA: getText('constants:trainNames.pamira'),
  SARATOV: getText('constants:trainNames.saratov'),
  SEVERNYI_URAL: getText('constants:trainNames.severnyiUral'),
  SMENA: getText('constants:trainNames.smena'),
  SMOLENSK: getText('constants:trainNames.smolensk'),
  SURA: getText('constants:trainNames.sura'),
  SYKTYVKAR: getText('constants:trainNames.syktyvkar'),
  TAMBOV: getText('constants:trainNames.tambov'),
  TOMICH: getText('constants:trainNames.tomich'),
  TYUMEN: getText('constants:trainNames.tyumen'),
  ULYANOVSK: getText('constants:trainNames.ulyanovsk'),
  URAL: getText('constants:trainNames.ural'),
  CHUVASHYA: getText('constants:trainNames.chuvashya'),
  SHEKSNA: getText('constants:trainNames.sheksna'),
  EXPRESS: getText('constants:trainNames.express'),
  ELBRUS: getText('constants:trainNames.elbrus'),
  UZHNYU_URAL: getText('constants:trainNames.uzhnyuUral'),
  YUNOST: getText('constants:trainNames.yunost'),
  YAMAL: getText('constants:trainNames.yamal'),
  YANTAR: getText('constants:trainNames.yantar'),
};

const TRAINNUMBERSANDNAMES = {
  '008А': LABELS.DOUBLES, // 'Двухэтажный',
  '007А': LABELS.DOUBLES, // 'Двухэтажный',
  '035В': LABELS.PALMIRA_DOUBLES, // 'Северная Пальмира / Двухэтажный',
  '036С': LABELS.PALMIRA_DOUBLES, // 'Северная Пальмира / Двухэтажный',
  '104Ж': LABELS.DOUBLES, // 'Двухэтажный',
  '104В': LABELS.DOUBLES, // 'Двухэтажный',
  '069В': LABELS.DOUBLES, // 'Двухэтажный',
  '070Г': LABELS.DOUBLES, // 'Двухэтажный',
  '046В': LABELS.DOUBLES, // 'Двухэтажный',
  '045В': LABELS.DOUBLES, // 'Двухэтажный',
  '023Г': LABELS.DOUBLES, // 'Двухэтажный',
  '024М': LABELS.DOUBLES, // 'Двухэтажный',
  '005А': LABELS.DOUBLES, // 'Двухэтажный',
  '006А': LABELS.DOUBLES, // 'Двухэтажный',
  '642С': LABELS.DOUBLES, // 'Двухэтажный',
  '642Ж': LABELS.DOUBLES, // 'Двухэтажный',
  '004М': LABELS.DOUBLES, // 'Двухэтажный',
  '738Ж': LABELS.DOUBLES, // 'Двухэтажный',
  '737Ж': LABELS.DOUBLES, // 'Двухэтажный',
  '049Й': LABELS.DOUBLES, // 'Двухэтажный',
  '050М': LABELS.DOUBLES, // 'Двухэтажный',
  '740Ж': LABELS.DOUBLES, // 'Двухэтажный',
  '739Ж': LABELS.DOUBLES, // 'Двухэтажный',
  '741В': LABELS.DOUBLES, // 'Двухэтажный',
  '781М': LABELS.ALLEGRO, // 'Аллегро',
  '783М': LABELS.ALLEGRO, // 'Аллегро',
  '785М': LABELS.ALLEGRO, // 'Аллегро',
  '787М': LABELS.ALLEGRO, // 'Аллегро',
  '782': LABELS.ALLEGRO, // 'Аллегро',
  '784': LABELS.ALLEGRO, // 'Аллегро',
  '786': LABELS.ALLEGRO, // 'Аллегро',
  '788': LABELS.ALLEGRO, // 'Аллегро',
  '015А': LABELS.ARKTIKA, // 'Арктика',
  '016А': LABELS.ARKTIKA, // 'Арктика',
  '321И': LABELS.BARGUZIN, // 'Баргузин',
  '322И': LABELS.BARGUZIN, // 'Баргузин',
  '039Й': LABELS.BASHKORTOSTAN, // 'Башкортостан',
  '040Й': LABELS.BASHKORTOSTAN, // 'Башкортостан',
  '072В': LABELS.BELOGORYE, // 'Белогорье',
  '071В': LABELS.BELOGORYE, // 'Белогорье',
  '617Я': LABELS.BELYE_NOCHI, // 'Белые ночи',
  '618Я': LABELS.BELYE_NOCHI, // 'Белые ночи',
  '059Г': LABELS.VOLGA, // 'Волга',
  '059А': LABELS.VOLGA, // 'Волга',
  '001Ж': LABELS.VOLGOGRAD, // 'Волгоград',
  '001И': LABELS.VOLGOGRAD, // 'Волгоград',
  '025В': LABELS.VORONEZH, // 'Воронеж',
  '025Я': LABELS.VORONEZH, // 'Воронеж',
  '031Г': LABELS.VYATKA, // 'Вятка'
  '032Г': LABELS.VYATKA, // 'Вятка',
  '081Э': LABELS.GILYU, // 'Гилюй',
  '081Ч': LABELS.GILYU, // 'Гилюй',
  '071Е': LABELS.DEMIDOVKIY, // 'Демидовский экспресс',
  '072Е': LABELS.DEMIDOVKIY, // 'Демидовский экспресс',
  '109Й': LABELS.DNEVNOY, // 'Дневной экспресс',
  '110Й': LABELS.DNEVNOY, // 'Дневной экспресс',
  '101Я': LABELS.DNEVNOY, // 'Дневной экспресс',
  '102Я': LABELS.DNEVNOY, // 'Дневной экспресс',
  '103Я': LABELS.DNEVNOY, // 'Дневной экспресс',
  '104Я': LABELS.DNEVNOY, // 'Дневной экспресс',
  '105Я': LABELS.DNEVNOY, // 'Дневной экспресс',
  '106Я': LABELS.DNEVNOY, // 'Дневной экспресс',
  '055Ы': LABELS.ENISEY, // 'Енисей',
  '056Ы': LABELS.ENISEY, // 'Енисей',
  '009Й': LABELS.JIGULI, // 'Жигули',
  '010Й': LABELS.JIGULI, // 'Жигули',
  '100Ч': LABELS.IVAN, // 'Иван Паристый',
  '099Ч': LABELS.IVAN, // 'Иван Паристый',
  '146Э': LABELS.INGUSHETIYA, // 'Ингушетия',
  '145С': LABELS.INGUSHETIYA, // 'Ингушетия',
  '088Н': LABELS.IRTYSH, // 'Иртыш',
  '087Н': LABELS.IRTYSH, // 'Иртыш',
  '025Г': LABELS.ITALMAS, // 'Италмас',
  '026Г': LABELS.ITALMAS, // 'Италмас',
  '001А': LABELS.KRASNAYA_STRELA, // 'Красная Стрела',
  '002А': LABELS.KRASNAYA_STRELA, // 'Красная Стрела',
  '085Ы': LABELS.KRASNUY_YAR, // 'Красный Яр',
  '086Ы': LABELS.KRASNUY_YAR, // 'Красный Яр',
  '029Н': LABELS.KUZBASS, // 'Кузбасс',
  '030Н': LABELS.KUZBASS, // 'Кузбасс',
  '031': LABELS.LEV_TOLSTOY, // 'Лев Толстой',
  '032А': LABELS.LEV_TOLSTOY, // 'Лев Толстой',
  '029В': LABELS.LIPETSK, // 'Липецк',
  '029М': LABELS.LIPETSK, // 'Липецк',
  '005Г': LABELS.LOTOS, // 'Лотос',
  '005Ж': LABELS.LOTOS, // 'Лотос',
  '049Е': LABELS.MALAHIT, // 'Малахит',
  '050Е': LABELS.MALAHIT, // 'Малахит',
  '058Э': LABELS.MARIY_EL, // 'Марий Эл',
  '058Г': LABELS.MARIY_EL, // 'Марий Эл',
  '041Й': LABELS.MORDOVIYA, // 'Мордовия',
  '042Й': LABELS.MORDOVIYA, // 'Мордовия',
  '035Г': LABELS.NIZHEGORODEC, // 'Нижегородец',
  '036Э': LABELS.NIZHEGORODEC, // 'Нижегородец',
  '013Н': LABELS.NOVOKUZNETSK, // 'Новокузнецк',
  '014А': LABELS.NOVOKUZNETSK, // 'Новокузнецк',
  '125Н': LABELS.OB, // 'Обь',
  '125Е': LABELS.OB, // 'Обь',
  '005Э': LABELS.OCEAN, // 'Океан',
  '006Э': LABELS.OCEAN, // 'Океан',
  '115Н': LABELS.OMICH, // 'Омич',
  '115Е': LABELS.OMICH, // 'Омич',
  '031У': LABELS.ORENBURZHYE, // 'Оренбуржье',
  '032У': LABELS.ORENBURZHYE, // 'Оренбуржье',
  '133Г': LABELS.POVOLZHYE, // 'Поволжье',
  '133А': LABELS.POVOLZHYE, // 'Поволжье',
  '022Я': LABELS.POLYARNAY_STRELA, // 'Полярная стрела',
  '021Н': LABELS.POLYARNAY_STRELA, // 'Полярная стрела',
  '315М': LABELS.POMORYE, // 'Поморье',
  '316М': LABELS.POMORYE, // 'Поморье',
  '317М': LABELS.POMORYE, // 'Поморье',
  '318М': LABELS.POMORYE, // 'Поморье',
  '030Й': LABELS.PREMIUM, // 'Премиум',
  '030С': LABELS.PREMIUM, // 'Премиум',
  '001Г': LABELS.PREMIUM, // 'Премиум',
  '002Й': LABELS.PREMIUM, // 'Премиум',
  '102С': LABELS.PREMIUM, // 'Премиум',
  '102М': LABELS.PREMIUM, // 'Премиум',
  '057Ч': LABELS.PRISKOLYE, // 'Приосколье',
  '058В': LABELS.PRISKOLYE, // 'Приосколье',
  '010А': LABELS.PSKOV, // 'Псков',
  '010Ч': LABELS.PSKOV, // 'Псков',
  '002М': LABELS.ROSSIA, // 'Россия',
  '001М': LABELS.ROSSIA, // 'Россия',
  '107Й': LABELS.SAMARA, // 'Самара',
  '107Ж': LABELS.SAMARA, // 'Самара',
  '035А': LABELS.PAMIRA, // 'Северная Пальмира',
  '036А': LABELS.PAMIRA, // 'Северная Пальмира',
  '009Г': LABELS.SAMARA, // 'Самара',
  '009Ж': LABELS.SARATOV, // 'Саратов',
  '084М': LABELS.SEVERNYI_URAL, // 'Северный Урал',
  '084Е': LABELS.SEVERNYI_URAL, // 'Северный Урал',
  '025А': LABELS.SMENA, // 'Смена - А. Бетанкур',
  '026А': LABELS.SMENA, // 'Смена - А. Бетанкур',
  '033Ж': LABELS.SMENA, // 'Смоленск',
  '034Ж': LABELS.SMENA, // 'Смоленск',
  '051Й': LABELS.SURA, // 'Сура',
  '052Й': LABELS.SURA, // 'Сура',
  '034М': LABELS.SYKTYVKAR, // 'Сыктывкар',
  '034Я': LABELS.SYKTYVKAR, // 'Сыктывкар',
  '031Ч': LABELS.TAMBOV, // 'Тамбов',
  '031В': LABELS.TAMBOV, // 'Тамбов',
  '037Н': LABELS.TOMICH, // 'Томич',
  '038Н': LABELS.TOMICH, // 'Томич',
  '059Е': LABELS.TYUMEN, // 'Тюмень',
  '060У': LABELS.TYUMEN, // 'Тюмень',
  '021Й': LABELS.ULYANOVSK, // 'Ульяновск',
  '022Й': LABELS.ULYANOVSK, // 'Ульяновск',
  '307Ж': LABELS.URAL, // 'Урал',
  '307Г': LABELS.URAL, // 'Урал',
  '053Ж': LABELS.CHUVASHYA, // 'Чувашия',
  '054Г': LABELS.CHUVASHYA, // 'Чувашия',
  '126Ч': LABELS.SHEKSNA, // 'Шексна',
  '126Я': LABELS.SHEKSNA, // 'Шексна',
  '003А': LABELS.EXPRESS, // 'Экспресс',
  '004А': LABELS.EXPRESS, // 'Экспресс',
  '061С': LABELS.ELBRUS, // 'Эльбрус',
  '062Ч': LABELS.ELBRUS, // 'Эльбрус',
  '013У': LABELS.UZHNYU_URAL, // 'Южный Урал',
  '014Е': LABELS.UZHNYU_URAL, // 'Южный Урал',
  '667Э': LABELS.YUNOST, // 'Юность',
  '667Ж': LABELS.YUNOST, // 'Юность',
  '011Е': LABELS.YAMAL, // 'Ямал',
  '012Я': LABELS.YAMAL, // 'Ямал',
  '029Ч': LABELS.YANTAR, // 'Янтарь',
  '030Ч': LABELS.YANTAR, // 'Янтарь',
};

export { TRAINNUMBERSANDNAMES as default, TRAINNUMBERSANDNAMES };
