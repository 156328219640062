import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  MultiSelect,
  Dialog,
  Text,
  Button,
  Checkbox,
  MultiSelectValuesNested,
} from 'new-ui';

import { getText } from '../../../../../i18n';

import { MainAnalytic } from '../../../../bi/utils/analytics';

import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import styles from './index.module.css';

const LABELS = {
  HEADER: getText('settings:travelPolicy.policyUnique.employeeDialog.header'),
  SUB_HEADER: getText('settings:travelPolicy.policyUnique.employeeDialog.sub'),
  PLACEHOLDER: getText('settings:travelPolicy.policyUnique.employeeDialog.input'),
  SELECT_ALL: getText('settings:travelPolicy.policyUnique.employeeDialog.selectAll'),
  CANCEL: getText('settings:travelPolicy.policyUnique.employeeDialog.cancel'),
  SAVE: getText('settings:travelPolicy.policyUnique.employeeDialog.save'),
  CONFIRM_ALL: getText('settings:travelPolicy.policyUnique.employeeDialog.confirmAll'),
  CONFIRM: getText('settings:travelPolicy.policyUnique.employeeDialog.confirm'),
};

interface IEmployeeDialog {
  show: boolean,
  isCreate: boolean,
  isSelectAll: boolean,
  isUnique: boolean,
  employees: any,
  formedTP: string,
  setEmployeeAutocompleteList: (values: string) => void,
  setSelectAll: (value: boolean) => void,
  setEmployeesSelected: (value: number[] | string[] | MultiSelectValuesNested[]) => void,
  setShowEmployeeDialog: () => void,
  onSave: () => void,
}

const EmployeeDialog = observer(({
  show,
  isCreate,
  employees,
  isSelectAll,
  isUnique,
  formedTP,
  setEmployeeAutocompleteList,
  setSelectAll,
  setEmployeesSelected,
  setShowEmployeeDialog,
  onSave,
}: IEmployeeDialog) => {
  const [disabledSelect, setDisabledSelect] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [hasClosed, setHasClosed] = useState(false);

  useEffect(() => {
    if (isUnique) {
      MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.TRAVEL_POLICY.CREATED_TP_OPEN, { formedTP });
    }
  }, [formedTP]);

  useEffect(() => {
    if (!show) {
      setHasClosed(false);
    }
  }, [show]);

  const handleChangeSelectAll = (value: boolean) => {
    if (value) {
      setDisabledSelect(true);
      setEmployeesSelected([]);

      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.TRAVEL_POLICY.EMPLOYEES_TP_ALL_CHECKBOX, { formedTP });
    } else {
      setDisabledSelect(false);
    }

    setSelectAll(value);
  };

  const handleSave = () => {
    onSave();

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.TRAVEL_POLICY.EMPLOYEES_TP_SAVE, { formedTP });
  };

  const handleConfirm = () => {
    if (disabledSelect) {
      return setShowConfirm(true);
    }

    return handleSave();
  };

  const handleCancel = () => {
    if (!hasClosed) {
      setHasClosed(true);
      setShowConfirm(false);
      setShowEmployeeDialog();

      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.TRAVEL_POLICY.EMPLOYEES_TP_CANSEL, { formedTP });
    }
  };

  const renderCheckbox = isCreate
    ? (
      <>
        <div className={ styles.checkbox }>
          <Checkbox
            qaAttr={ QA_ATTRIBUTES.settings.travelPolicy.employeeDialog.selectAllEmployees }
            onChange={ handleChangeSelectAll }
            value={ isSelectAll }
          >
            { LABELS.SELECT_ALL }
          </Checkbox>
        </div>
        <div className={ styles.border } />
      </>
    ) : null;

  const renderSelectForm = () => (
    <>
      <div className={ styles.header }>
        <Text type='bold_24' className={ styles.header_text }>
          { LABELS.HEADER }
        </Text>
        <Text type='NORMAL_16'>
          { LABELS.SUB_HEADER }
        </Text>
      </div>
      <MultiSelect
        withLabel
        search
        disabled={ disabledSelect }
        className={ styles.select }
        selectAllLabel={ LABELS.SELECT_ALL }
        placeholder={ LABELS.PLACEHOLDER }
        searchPlaceholder={ LABELS.PLACEHOLDER }
        list={ employees.list }
        values={ employees.selected }
        onSearchFunction={ values => setEmployeeAutocompleteList(values) }
        onChange={ values => setEmployeesSelected(values) }
        qaAttr={ QA_ATTRIBUTES.settings.travelPolicy.employeeDialog.selectEmployees }
      />
      { renderCheckbox }
      <div className={ styles.actions }>
        <Button
          type='textual'
          qaAttr={ QA_ATTRIBUTES.settings.travelPolicy.employeeDialog.cancel }
          className={ styles.edit_button }
          onClick={ handleCancel }
        >
          { LABELS.CANCEL }
        </Button>
        <Button
          qaAttr={ QA_ATTRIBUTES.settings.travelPolicy.employeeDialog.save }
          className={ styles.edit_button }
          onClick={ handleConfirm }
        >
          { LABELS.SAVE }
        </Button>
      </div>
    </>
  );

  const renderConfirmForAll = () => (
    <>
      <div className={ styles.header }>
        <Text type='bold_16'>
          { LABELS.CONFIRM_ALL }
        </Text>
      </div>
      <div className={ styles.actionsConfirm }>
        <Button
          qaAttr={ QA_ATTRIBUTES.settings.travelPolicy.employeeDialog.save }
          className={ styles.edit_button }
          onClick={ handleSave }
        >
          { LABELS.CONFIRM }
        </Button>
        <Button
          type='textual'
          qaAttr={ QA_ATTRIBUTES.settings.travelPolicy.employeeDialog.cancel }
          onClick={ handleCancel }
        >
          { LABELS.CANCEL }
        </Button>
      </div>
    </>
  );

  const dialogClassName = showConfirm ? styles.wrapperConfirm : styles.wrapper;
  const rednerDialogContent = showConfirm ? renderConfirmForAll() : renderSelectForm();

  return (
    <Dialog
      showClosing
      qaAttr={ QA_ATTRIBUTES.settings.travelPolicy.employeeDialog.dialog }
      show={ show }
      onChange={ handleCancel }
      className={ dialogClassName }
    >
      { rednerDialogContent }
    </Dialog>
  );
});

export { EmployeeDialog };
