import { getText } from '../../../../../i18n';

import { PersonalPaymentProtocol } from '../../../../bi/protocols/personalPayment';

import { KEYS } from '../../../../bi/constants/settings';

import { ItemMenuLeaf } from '../../types';

const LABELS = {
  MY_DATA: getText('constants:settings.myData'),
  EMPLOYEE_PERSONAL_ACCOUNT: getText('constants:settings.employeePersonalAccount'),
};

const items = [
  {
    title: LABELS.MY_DATA,
    id: KEYS.MY_DATA,
  },
  {
    title: LABELS.EMPLOYEE_PERSONAL_ACCOUNT,
    id: KEYS.EMPLOYEE_PERSONAL_ACCOUNT,
  },
];

export const configEmployeePersonalAccount = (personalPaymentProtocol: PersonalPaymentProtocol): ItemMenuLeaf[] => {
  if (personalPaymentProtocol.canViewPETransactionsPage) {
    return items;
  }

  return [];
};
