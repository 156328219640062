const CARTACTION = {
  CARTLOAD: 'cart.load',
  UPDATE: 'cart.update',
  UPDATEITEM: 'cart.update.item',
  UPDATE_ITEMS: 'cart.update.items',
  UPDATEFOUL: 'cart.update.foul',
  UPDATENAME: 'cart.update.name',
  UPDATE_TRIP_BY_IDS: 'cart.trip.byIds',
  UPDATEERROR: 'cart.update.error',
  REMOVECARTITEM: 'cart.remove.item',
  REMOVECART: 'cart.remove',
  UPDATERESERVATIONFAILEDITEMS: 'cart.update.reservation.failed.items',
  GETVERIFICATIONCODE: 'cart.get.verificationCode',
  CHECKVERIFICATIONCODE: 'cart.check.verificationCode',
  UPDATEVERIFICATIONCODERROR: 'cart.update.verificationCode.error',
  UPDATEUNDERAGE: 'cart.update.underage',
  RESET: 'cart.reset',
  SET_FIRST_NON_TOTALLY_FILLED_ITEM: 'cart.set.first.non.totally.filled.item',
  RESET_FIRST_NON_TOTALLY_FILLED_ITEM: 'cart.reset.first.non.totally.filled.item',
  UPDATE_ITEM_TP: 'cart.update.itemTp',
  SET_COMPANIES_WITH_COST_CENTER: 'cart.companiesWithCostCenter',
  UPDATE_RULES: 'cart.updateRules',
  SET_TRIPS_TO_ADD_ORDER: 'cart.setTripsToAddOrder',
  SET_PRESELECT_REQUEST_1C: 'cart.setPreselectRequest1c',
  SET_REQUESTS_1C_LIST: 'cart.setRequest1cList',
  SET_AUTOCOMPLETED_ITEMS: 'cart.setAutocompletedItems',
  SET_IS_ALREADY_SENT: 'cart.setIsAlreadySent',
  ITEMS_TO_UPDATE: 'cart.setItemsToUpdate',
  ITEMS_TO_UPDATE_SPECIFIC: 'cart.setItemsToUpdateSpecific',
};

export default CARTACTION;
