import { getText } from '../../../i18n';

const LABELS = {
  TRAIN: {
    AUTO: getText('event:calculator.city.const.train.auto'),
    BUS: getText('event:calculator.city.const.train.bus'),
  },
  HOTEL: {
    THREE: getText('event:calculator.city.const.hotel.three'),
    FOUR: getText('event:calculator.city.const.hotel.four'),
    FIVE: getText('event:calculator.city.const.hotel.five'),
  },
  HALL: {
    SMALL: getText('event:calculator.city.const.hall.small'),
    MEDIUM: getText('event:calculator.city.const.hall.medium'),
    BIG: getText('event:calculator.city.const.hall.big'),
  },
};

type TLabelConst = {
  DIRECTION: Record<string, string>;
  HOTEL: Record<number, string>;
  HALL: Record<string, string>;
  TRANSFER: Record<string, string>;
  OTHER: Record<string, string>;
  EATS: Record<string, string>;
};

export const LABELS_COMMENT: TLabelConst = {
  DIRECTION: {
    RussiaMajor: getText('event:calculator.data.type.large'),
    RussiaMinor: getText('event:calculator.data.type.other'),
    Foreign: getText('event:calculator.data.type.foreign'),
  },
  HOTEL: {
    3: LABELS.HOTEL.THREE,
    4: LABELS.HOTEL.FOUR,
    5: LABELS.HOTEL.FIVE,
  },
  HALL: {
    Small: LABELS.HALL.SMALL,
    Medium: LABELS.HALL.MEDIUM,
    Large: LABELS.HALL.BIG,
  },
  TRANSFER: {
    Bus: LABELS.TRAIN.BUS,
    Car: LABELS.TRAIN.AUTO,
  },
  OTHER: {
    TeamBuilding: getText('event:calculator.city.checkboxOther.team'),
    Dj: getText('event:calculator.city.checkboxOther.dj'),
    Photographer: getText('event:calculator.city.checkboxOther.photo'),
    Host: getText('event:calculator.city.checkboxOther.leadingEvent'),
  },
  EATS: {
    CoffeeBreak: getText('event:calculator.city.checkboxEat.coffee'),
    Lunch: getText('event:calculator.city.checkboxEat.lunch'),
    Dinner: getText('event:calculator.city.checkboxEat.dinner'),
    Buffet: getText('event:calculator.city.checkboxEat.buffet'),
    Banquet: getText('event:calculator.city.checkboxEat.banquet'),
  },
};

export const DEFAULT_ITEMS_MICE = {
  HOTEL: '3',
  HALL: 'Small',
  TRAIN: 'Car',
};

export const HOTELS_MICE = [
  {
    value: LABELS.HOTEL.THREE,
    id: '3',
  },
  {
    value: LABELS.HOTEL.FOUR,
    id: '4',
  },
  {
    value: LABELS.HOTEL.FIVE,
    id: '5',
  },
];

export const HALL_MICE = [
  {
    value: LABELS.HALL.SMALL,
    id: 'Small',
  },
  {
    value: LABELS.HALL.MEDIUM,
    id: 'Medium',
  },
  {
    value: LABELS.HALL.BIG,
    id: 'Large',
  },
];

export const TRANSFER_MICE = [
  {
    value: LABELS.TRAIN.AUTO,
    id: 'Car',
  },
  {
    value: LABELS.TRAIN.BUS,
    id: 'Bus',
  },
];

export const FOOD_MICE = {
  COFEE_BREAK: 'CoffeeBreak',
  LUNCH: 'Lunch',
  DINNER: 'Dinner',
  BUFFET: 'Buffet',
  BANQUET: 'Banquet',
};

export const MICE_SERVICES = {
  HOST: 'Host',
  DJ: 'Dj',
  TEAM_BUILDING: 'TeamBuilding',
  PHOTOGRAPHER: 'Photographer',
};

export const CHECK_MICE = {
  FOOD: 'food',
  SERVICES: 'services',
};

export const fieldCalculator = {
  HotelStars: 'HotelStars',
  Direction: 'Direction',
  Food: 'Food',
  MICEServices: 'MICEServices',
  Transfer: 'Transfer',
  ConferenceHall: 'ConferenceHall',
};

export const FIELD_CALCULATOR = {
  DIRECTION: getText('event:addEvent.fieldCalculator.direction'),
  HOTEL: getText('event:addEvent.fieldCalculator.hotel'),
  CONFERENCE_HALL: getText('event:addEvent.fieldCalculator.conferenceHall'),
  TRANSFER: getText('event:addEvent.fieldCalculator.transfer'),
  FOOD: getText('event:addEvent.fieldCalculator.food'),
  SERVICES: getText('event:addEvent.fieldCalculator.services'),
};

export const DIRECTION = {
  LARGE_CITY: 'RussiaMajor',
  OTHER_CITY: 'RussiaMinor',
  FOREIGN_CITY: 'Foreign',
};
