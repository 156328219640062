import React from 'react';

import { Dialog, Button, Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import styles from './styles.module.css';

const LABELS = {
  TITLE: getText('cart:confirmPaymentUsingPersonalFundsDialog.title'),
  DESCRIPTION: getText('cart:confirmPaymentUsingPersonalFundsDialog.description'),
  CONFIRM: getText('cart:confirmPaymentUsingPersonalFundsDialog.confirm'),
  CANCEL: getText('cart:confirmPaymentUsingPersonalFundsDialog.cancel'),
};

interface IConfirmPaymentUsingPersonalFundsDialogProps {
  show: boolean;
  toggleDialog: (value: boolean) => void;
  onConfirm: () => void
}

export const ConfirmPaymentUsingPersonalFundsDialog = ({
  show,
  toggleDialog,
  onConfirm,
}: IConfirmPaymentUsingPersonalFundsDialogProps) => {
  const onClose = () => {
    toggleDialog(false);
  };

  return (
    <Dialog
      showClosing
      show={ show }
      onChange={ toggleDialog }
      className={ styles.dialog }
    >
      <div className={ styles.content }>
        <Text type={ 'bold_20' }>
          { LABELS.TITLE }
        </Text>

        <Text type={ 'NORMAL_14_130' }>
          { LABELS.DESCRIPTION }
        </Text>

        <div className={ styles.actions }>
          <Button
            type='secondary'
            onClick={ onConfirm }
          >
            { LABELS.CONFIRM }
          </Button>
          <Button
            className={ styles.cancel }
            type='textual'
            onClick={ onClose }
          >
            { LABELS.CANCEL }
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
