import React from 'react';
import { Text, StyledWrapper, TextType, TextColor } from 'new-ui';

import { SorryAirline } from './component/airline';
import { SorryTrainCancelled } from './component/trainCancelled';
import { SorryService } from './component/service';
import { WarningSmartdesk } from './component/smartdesk';
import { WarningLetters } from './component/letters';
import { WarningIntercom } from './component/intercom';
import { WarningIntercomSA } from './component/intercomSA';
import { WarningAeroexpress } from './component/aeroexpress';

import styles from './styles/index.module.css';

interface WarningBlockProps {
  text?: string | JSX.Element,
  airline?: boolean,
  trainCancelled?: boolean,
  service?: boolean,
  smartdesk?: boolean,
  letters?: boolean,
  intercom?: boolean,
  intercomSA?: boolean,
  aeroexpress?: boolean,
  color?: TextColor,
  type?: TextType,
}

const WarningBlock = (props: WarningBlockProps) => {
  const {
    text,
    airline,
    trainCancelled,
    service,
    letters,
    color,
    type,
    smartdesk,
    intercom,
    intercomSA,
    aeroexpress,
  } = props;

  let html = (
    <Text type={ type } color={ color } className={ styles.text }>
      { text }
    </Text>
  );

  if (airline) {
    html = <SorryAirline />;
  }

  if (trainCancelled) {
    html = <SorryTrainCancelled />;
  }

  if (service) {
    html = <SorryService />;
  }

  if (smartdesk) {
    html = <WarningSmartdesk />;
  }

  if (letters) {
    html = <WarningLetters />;
  }

  if (intercom) {
    html = <WarningIntercom />;
  }

  if (intercomSA) {
    html = <WarningIntercomSA />;
  }

  if (aeroexpress) {
    html = <WarningAeroexpress />;
  }

  return (
    <StyledWrapper className={ styles.wrapper }>
      { html }
    </StyledWrapper>
  );
};

export { WarningBlock };
