import React from 'react';
import { Button, Dialog, Text } from 'new-ui';
import { getText } from '../../../../../../i18n';

import styles from './styles.module.css';

interface IDialogRequestErrorProps {
  onShow: (value: boolean) => void,
  onClose: () => void,
  isErrorPaymentDetails: boolean,
}

const LABELS = {
  TITLE: getText('reports:operations.dialogRequestError.title'),
  MESSAGE: getText('reports:operations.dialogRequestError.message'),
  NOT_FOUND: getText('reports:operations.dialogRequestError.notFound'),
  CLOSE: getText('common:close'),
};

const DialogRequestError = ({ onShow, onClose, isErrorPaymentDetails }: IDialogRequestErrorProps) => {
  const message = isErrorPaymentDetails ? LABELS.NOT_FOUND : LABELS.MESSAGE;

  return (
    <Dialog show={ !!onShow } onChange={ onClose } outsideClickClosing>
      <div className={ styles.dialog }>
        <Text type='bold_20' className={ styles.title }>
          { LABELS.TITLE }
        </Text>
        <div className={ styles.error }>
          <Text className={ styles.message } type='NORMAL_14' color='red'>
            { message }
          </Text>
        </div>
        <div className={ styles.actions }>
          <Button onClick={ onClose } type='primary'>
            { LABELS.CLOSE }
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export { DialogRequestError };
