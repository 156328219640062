import { UUID } from 'node:crypto';

import CONFIG from '../../../config';

const ROUTES_V2 = {
  employeePersonalPayment: {
    service: 'personal-transaction-service',
  },
};

const Routes = {
  cluster: {
    cartService: {
      cartWp: {
        cartId: {
          paymentSource: (cartId: number, pt: string) => `${CONFIG.API_CLUSTER}/cart-service/cart-wp/${cartId}/payment-source?ps=${pt}`,
        },
      },
    },
    personalTransactionService: {
      transaction: {
        hasAny: `${CONFIG.API_CLUSTER}/personal-transaction-service/transaction/has-any`,
      },
    },
    travelApproval: {
      approves: {
        factual: {
          waiting: `${CONFIG.API_CLUSTER}/travel-approval/approves/factual/waiting`,
        },
      },
    },
    documentAggregator: {
      documents: {
        registry: {
          download: (params: string) => `${CONFIG.API_CLUSTER}/document-aggregator/documents/registry/download?${params}`,
        },
      },
      v1: {
        documents: `${CONFIG.API_CLUSTER}/document-aggregator/v1/documents`,
        signEnhanced: `${CONFIG.API_CLUSTER}/document-aggregator/v1/documents/sign-enhanced`,
        documentId: {
          download: (documentId: UUID) => `${CONFIG.API_CLUSTER}/document-aggregator/v1/documents/${documentId}/download`,
          preview: (documentId: UUID) => `${CONFIG.API_CLUSTER}/document-aggregator/v1/documents/${documentId}/preview`,
          sendEmail: (documentId: UUID) => `${CONFIG.API_CLUSTER}/document-aggregator/v1/documents/${documentId}/send-email`,
        },
      },
    },
    autocompleteEmployee: {
      employees: `${CONFIG.API_CLUSTER}/autocomplete-employee/employees`,
    },
  },
  monolith: {
    account: {
      employees: {
        autocomplete: `${CONFIG.API_ROOT}/account/employees/autocomplete`,
      },
    },
  },
};

export { ROUTES_V2, Routes };
