import CheckoutStore from './checkoutStore';
import { PaymentMethodsStore } from '../cloudPayment/store';

import CHECKOUT_ACTION from './action';

import { ICheckoutDataItem, ICheckoutLoad, ICheckoutStore, IValidateEmployees } from './types';
import { PaymentMethodType } from '../../types/paymentMethod';
import { TrainItem } from '../../utils/discountSapsan';

class Checkout {
  checkoutStore: any;
  api: any;

  constructor(api: any) {
    this.api = api.cart;
    this.checkoutStore = CheckoutStore();
  }

  get = (): ICheckoutStore => this.checkoutStore.getState();

  clearState = () => this.checkoutStore.dispatch({
    type: CHECKOUT_ACTION.CHECKOUTCLEARSTATE,
  });

  start = () => this.checkoutStore.dispatch({
    type: CHECKOUT_ACTION.CHECKOUTLOAD,
  });

  load = (id: string) => this.api.getCartById(id).then((res: ICheckoutLoad) => {
    this.checkoutStore.dispatch({
      type: CHECKOUT_ACTION.UPDATE,
      payload: res,
    });
    this.checkoutStore.dispatch({
      type: CHECKOUT_ACTION.VALIDATERATE,
      payload: res,
    });
    this.validateEmployees(id);

    const paymentSource = res?.PaymentSource as PaymentMethodType | undefined;

    if (paymentSource) PaymentMethodsStore.setPaymentMethod(paymentSource);
  });

  validateEmployees = (cartId: string) => this.api.validateEmployees(cartId)
    .then((payload: IValidateEmployees) => this.checkoutStore.dispatch({ type: CHECKOUT_ACTION.CHECKOUTVALIDATION, payload }))
    .catch(() => this.checkoutStore.dispatch({ type: CHECKOUT_ACTION.CHECKOUTVALIDATION, payload: null }));

  loadPreCheckout = (id: string) => this.api.preCheckout(id).then((res: ICheckoutDataItem[]) => {
    this.checkoutStore.dispatch({
      type: CHECKOUT_ACTION.PREUPDATE,
      payload: res,
    });
  });

  loadDiscount = (id: string) => this.api.getDiscount(id)
    .then((res: TrainItem[]) => this.checkoutStore.dispatch({ type: CHECKOUT_ACTION.LOADDISCOUNT, payload: res }))
    .catch(() => this.checkoutStore.dispatch({ type: CHECKOUT_ACTION.LOADDISCOUNT, payload: [] }));

  changeAccept = (value: boolean) => this.checkoutStore.dispatch({
    type: CHECKOUT_ACTION.CHECKOUTUPDATEACCEPT,
    payload: value,
  });

  cancellationInfo = (id: number) => this.api.cancellationInfo(id).then((res: any) => {
    this.checkoutStore.dispatch({
      type: CHECKOUT_ACTION.CHECKOUTUPDATERULES,
      payload: {
        Id: id,
        Rules: res,
      },
    });
  });

  rename = (params: any) => this.api.rename(params).then(() =>
    this.checkoutStore.dispatch({
      type: CHECKOUT_ACTION.CHECKOUTUPDATENAME,
      payload: params,
    }));

  applyDiscount = (value: string) => this.api.applyDiscount(this.get().id, value);

  subscribeCart = (cb: any) => this.checkoutStore.subscribe(cb);
}

export default Checkout;
