import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Text, Button, Price, UniversalTable } from 'new-ui';

import { useStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import EmployeePaymentService from '../../../../bi/services/employeePayment';
import { PersonalPaymentProtocol } from '../../../../bi/protocols/personalPayment';

import { PaymentWidget } from '../../../../components/PaymentWidget';

import { ITEM_PER_PAGE, LABELS, SortTransactionsTypeEnum } from './constants';
import { tableSettings } from './table-settings';

import styles from './index.module.css';

interface IEmployeePersonalAccount {
  employeePaymentService: EmployeePaymentService,
  personalPaymentProtocol: PersonalPaymentProtocol,
}

const EmployeePersonalAccount = observer(({
  employeePaymentService,
  personalPaymentProtocol,
}: IEmployeePersonalAccount) => {
  const [sortBy, setSortBy] = useState('eventDate');
  const [sortOrder, setSortOrder] = useState(SortTransactionsTypeEnum.Desc);
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpenWidget, setIsOpenWidget] = useState(false);
  const {
    employeePaymentStore: {
      employeeTransactionsList: {
        transactions,
        metadata: { totalCount },
      },
      personalBalance,
    } } = useStores([MOBX_STORES.EMPLOYEE_PAYMENT]);

  useEffect(() => {
    employeePaymentService.getPersonalBalance();
  }, []);

  useEffect(() => {
    employeePaymentService.getEmployeePersonalTransactions({
      Limit: ITEM_PER_PAGE,
      Offset: ITEM_PER_PAGE * (currentPage - 1),
      SortBy: sortBy,
      Order: sortOrder,
    });
  }, [sortBy, sortOrder, currentPage]);

  const handleClickSort = (sortByValue: string, order: SortTransactionsTypeEnum) => {
    setSortBy(sortByValue);
    setSortOrder(order);
  };

  const handleChangePage = (page: number) => setCurrentPage(page);

  const handleCloseWidget = () => {
    setIsOpenWidget(false);
  };

  const renderPersonalFunds = () => (
    <div className={ styles.personal_funds_container }>
      <div className={ styles.column }>
        <Text type='bold_20'> { LABELS.AVAILABLE_BALANCE } </Text>
        <Price
          value={ personalBalance }
          type='bold_16'
        />
        <Button onClick={ () => {
          setIsOpenWidget(true);
        } }
        >
          {LABELS.TOP_UP_CARD}
        </Button>
      </div>
      <div className={ styles.column }>
        <Text type='NORMAL_14_130' color='blue' className={ styles.link }> { LABELS.RETURN_TO_CARD } </Text>
      </div>
    </div>
  );

  return (
    <div className={ styles.wrapper }>
      <PaymentWidget
        isOpen={ isOpenWidget }
        onCancel={ handleCloseWidget }
        onChangeDialog={ handleCloseWidget }
        personalPaymentProtocol={ personalPaymentProtocol }
      />
      <Text type='bold_24'> {LABELS.EMPLOYEE_PERSONAL_ACCOUNT} </Text>
      {renderPersonalFunds()}

      <Text type='bold_20'> { LABELS.USER_TRANSACTIONS} </Text>

      <UniversalTable
        settings={ tableSettings({ selectable: false }) }
        items={ transactions }
        onChangePage={ handleChangePage }
        currentPage={ currentPage }
        itemsPerPage={ ITEM_PER_PAGE }
        total={ totalCount }
        sortDirection={ sortOrder }
        sortField={ sortBy }
        onChangeSort={ (column, direction) => {
          handleClickSort(column as string, direction as SortTransactionsTypeEnum);
        } }
      />
    </div>
  );
});

export { EmployeePersonalAccount };
