import React, { useState } from 'react';

import { useServices } from '../../bi/context/services';

import { SERVICETYPE } from '../../bi/constants/serviceType';

import EmployeeItem from './item';

import { IEmployeeItem, IProjects, IOnHandlesMetrics } from '../../bi/types/employees';
import { IEmployeeCartItem } from '../../bi/services/checkout/types';
import { IEmployee, IItem } from '../../bi/types/cart';

import styles from './styles/index.module.css';

const DEFAULT_QA_ATTR_PAID_SEATS = {
  wrapper: '',
  price: '',
  seat: '',
  title: '',
  delete: '',
  employee: '',
  errors: {
    noForeignPassport: '',
    noDomesticPassport: '',
    foulForeignPassport: '',
  },
};

const DEFAULT_ON_HANDLES_METRICS = {
  handleMetricStartSelectEmployee: () => {},
  handleMetricStartDeleteEmployee: () => {},
  handleMetricStartSelectProject: () => {},
  handleMetricStartDeleteProject: () => {},
  handleMetricStartDeleteItem: () => {},
  handleMetricStartSelectTag: () => {},
  endTime: () => {},
};

interface IQaAttrPaidSeats {
  wrapper: string;
  price: string;
  seat: string;
  title: string;
  delete: string;
  employee: string;
  errors: {
    noForeignPassport: string;
    noDomesticPassport: string;
    foulForeignPassport: string;
  },
}

interface Employee {
  Id: number;
  Tags: string[];
}

interface EmployeeListProps {
  items: any[]; // TODO заменить после типизаци cart-wp
  routes?: any[]; // TODO заменить после типизаци cart-wp
  projects: IProjects[] | null;
  employeesWithCompanies: IEmployeeItem[] | IEmployeeCartItem[];
  max: number;
  enumOfDocuments: any;
  buyingEmployee?: any | null;
  onHandlesMetrics?: IOnHandlesMetrics;
  serviceType: string;
  lastAddedEmployee?: any | null;
  chosenApprovedRequest?: any | null;
  qaAttrPaidSeats?: IQaAttrPaidSeats;
  qaAttr?: {
    [key: string]: string | { [key: string]: string },
  } | null;
  approvedList?: any[];
  isMulti?: boolean;
  isCart?: boolean;
  isPersonalBonusCards: boolean;
  showDocument: boolean;
  isBlockEditEmployees?: boolean;
  readOnly?: boolean;
  departmentOnly: boolean;
  readonlyProjects?: boolean;
  mandatoryProject: boolean;
  optionalProject?: boolean;
  isChoiceForEachEmployee: boolean;
  isOptionalEmployee?: boolean;
  isReserved?: boolean;
  highlightNonFilledEmployee?: boolean;
  withNumber?: boolean;
  isTravelApprovalAndNoBooking?: boolean;
  cartEmployeeBirthday?: boolean;
  isSpecificCart?: boolean;
  ticketId: number;
  isHideAdditionalBaggageMixvel?: boolean;
  isTrainKaliningradTicket?: boolean;
  cartId?: number;
  handleChangeLastAddedEmployee?(employee: IEmployee): void;
  redirectToProject(employeeId: number): void;
  onChangeProjectSelect(employeeId: number, projectId: number): void;
  onSetEmployeeInCartItem?: Promise<void> | { (): void };
  handleAddEmployee(employee: IEmployee): Promise<any>;
  handleRemoveEmployee(employee: IEmployee): Promise<any>;
  handleAddDocument(employeeId: number, documentId: number): void;
  handleAddCompany(employeeId: number, companyId: string): void;
  onResetNonFilledItem?(): void;
  redirectToEmployee(employee: Employee): void;
  onSelectedEmployee?(value: number): void;
  onSelectedOptionEmployee?(label: string): void;
  onSelectDocument?(labelType: string): void;
  onSelectCompany?(cart: IItem): void;
  onSelectProject?(): void;
  onRemoveEmployee?(): void;
  onRemoveOptionalEmployee?(): void;
  onAddProject?(employeeId: number, projectId: number): void;
  onValidRequest?(): void;
}

const EmployeeList = ({
  items,
  enumOfDocuments,
  employeesWithCompanies,
  max,
  handleAddEmployee,
  handleRemoveEmployee,
  handleAddDocument,
  handleAddCompany,
  departmentOnly,
  mandatoryProject,
  isChoiceForEachEmployee,
  redirectToProject,
  isPersonalBonusCards,
  serviceType,
  isCart = false,
  isMulti = false,
  showDocument = false,
  isBlockEditEmployees = false,
  readOnly = false,
  isOptionalEmployee = false,
  projects = [],
  routes = [],
  chosenApprovedRequest = null,
  isReserved = false,
  optionalProject = false,
  buyingEmployee = null,
  readonlyProjects = false,
  isTravelApprovalAndNoBooking = false,
  redirectToEmployee = () => {},
  onSetEmployeeInCartItem = () => {},
  onChangeProjectSelect = () => {},
  onSelectedEmployee = () => {},
  onSelectedOptionEmployee = () => {},
  onSelectDocument = () => {},
  onSelectCompany = () => {},
  onSelectProject = () => {},
  onRemoveEmployee = () => {},
  onRemoveOptionalEmployee = () => {},
  handleChangeLastAddedEmployee = () => {},
  onAddProject = () => {},
  onValidRequest = () => {},
  onResetNonFilledItem = () => {},
  onHandlesMetrics = DEFAULT_ON_HANDLES_METRICS,
  highlightNonFilledEmployee = false,
  lastAddedEmployee = null,
  withNumber = false,
  cartEmployeeBirthday = false,
  qaAttrPaidSeats = DEFAULT_QA_ATTR_PAID_SEATS,
  qaAttr = null,
  isSpecificCart = false,
  approvedList = [],
  ticketId,
  isHideAdditionalBaggageMixvel = true,
  isTrainKaliningradTicket = false,
  cartId = 0,
}: EmployeeListProps) => {
  const [addedNewEmployee, setAddedNewEmployee] = useState<{ [key: number]: boolean }>({});

  const {
    workspaceService,
    settingsService,
    employeeService,
    accountSettingsService,
    cloudPaymentService,
    airlineBaggageService: { deleteBaggageForEmployee },
    cartService: { load },
    trainsService: { removeEmployeeFromTransitDocuments, addTrainTransitDocument },
  } = useServices(['Workspace', 'Settings', 'Employee', 'AccountSettings', 'CloudPayment', 'AirlineBaggage', 'Cart', 'Trains']);
  const handleSetAddedNewEmployee = (employeeId: number, addedNow: boolean) =>
    setAddedNewEmployee({ [employeeId]: addedNow });

  const getChosenApprovedEmployees = (value: any) => {
    const res = value && chosenApprovedRequest
    // @ts-ignore
      ? chosenApprovedRequest.Employees.filter(({ Name, Surname }) =>
        Name.toLowerCase().includes(value.toLowerCase()) || Surname.toLowerCase().includes(value.toLowerCase()))
      : chosenApprovedRequest.Employees.slice(0, 5);

    return Promise.resolve(res);
  };

  const maxEmployees = isOptionalEmployee && items.length < max ? items.length + 1 : max;
  const isOptionalTitle = isOptionalEmployee && items.length > 0;
  const selectGetEmployees = chosenApprovedRequest && isTravelApprovalAndNoBooking
    ? getChosenApprovedEmployees
    : employeeService?.autoCompleteEmployeeOnly;
  const isApprovalHotel = isSpecificCart && serviceType === SERVICETYPE.HOTEL;

  return (
    <div className={ styles['list-wrapper'] }>
      { Array(maxEmployees).fill(0).map((item, index) => {
        const employeeHasId = items[index] && items[index].Employee && items[index].Employee.Id;

        const itemKey = employeeHasId
          ? `employee_${items[index].Employee.Id}_${index}`
          : `employee-${index}`;

        const isLastAddedEmployee =
            employeeHasId && lastAddedEmployee && lastAddedEmployee.Id === items[index].Employee.Id;

        const buyingEmp = !!buyingEmployee && buyingEmployee.Id && index === 0 && !item;
        const finderUserEmployee = buyingEmp
        // @ts-ignore
          ? chosenApprovedRequest?.Employees.find(({ Id }) => Id === buyingEmployee.Id)
          : null;
        const setChosenApprovedRequest = finderUserEmployee ?
          { ...chosenApprovedRequest, Employees: [finderUserEmployee] } : chosenApprovedRequest;

        return (
          <EmployeeItem
            withNumber={ withNumber }
            readonlyProjects={ readonlyProjects }
            key={ itemKey }
            index={ index }
            items={ items }
            routes={ routes }
            getEmployee={ employeeService?.getEmployeeById }
            onSetEmployeeInCartItem={ onSetEmployeeInCartItem }
            chosenApprovedRequest={ setChosenApprovedRequest }
            isTravelApprovalAndNoBooking={ isTravelApprovalAndNoBooking }
            projects={ projects }
            isLastAddedEmployee={ isLastAddedEmployee }
            workspaceService={ workspaceService }
            loadCart={ load }
            ticketId={ ticketId }
            deleteBaggageForEmployee={ deleteBaggageForEmployee }
            isHideAdditionalBaggageMixvel={ isHideAdditionalBaggageMixvel }
            settingsService={ settingsService }
            buyingEmployee={ buyingEmployee }
            highlightNonFilledEmployee={ highlightNonFilledEmployee }
            onResetNonFilledItem={ onResetNonFilledItem }
            employeesWithCompanies={ employeesWithCompanies }
            item={ items[index] }
            number={ index + 1 }
            isMulti={ isMulti }
            isCart={ isCart }
            isPersonalBonusCards={ isPersonalBonusCards }
            isChoiceForEachEmployee={ isChoiceForEachEmployee }
            showDocument={ showDocument }
            readOnly={ readOnly }
            isBlockEditEmployees={ isBlockEditEmployees }
            departmentOnly={ departmentOnly }
            mandatoryProject={ mandatoryProject }
            optionalProject={ optionalProject }
            isOptionalTitle={ isOptionalTitle }
            isTitle={ items.length === index }
            enumOfDocuments={ enumOfDocuments }
            getEmployees={ selectGetEmployees }
            onAddEmployee={ handleAddEmployee }
            handleRemoveEmployee={ handleRemoveEmployee }
            onHandlesMetrics={ onHandlesMetrics }
            handleAddDocument={ handleAddDocument }
            onAddCompany={ handleAddCompany }
            handleChangeLastAddedEmployee={ handleChangeLastAddedEmployee }
            redirectToEmployee={ redirectToEmployee }
            redirectToProject={ redirectToProject }
            onChangeProjectSelect={ onChangeProjectSelect }
            onSelectedEmployee={ onSelectedEmployee }
            onSelectedOptionEmployee={ onSelectedOptionEmployee }
            onSelectDocument={ onSelectDocument }
            onSelectCompany={ onSelectCompany }
            onSelectProject={ onSelectProject }
            onRemoveEmployee={ onRemoveEmployee }
            onValidRequest={ onValidRequest }
            onRemoveOptionalEmployee={ onRemoveOptionalEmployee }
            onAddProject={ onAddProject }
            isReserved={ isReserved }
            serviceType={ serviceType }
            manyEmployees={ maxEmployees > 1 }
            cartEmployeeBirthday={ cartEmployeeBirthday }
            qaAttrPaidSeats={ qaAttrPaidSeats }
            qaAttr={ qaAttr }
            isApprovalHotel={ isApprovalHotel }
            setAddedNewEmployee={ handleSetAddedNewEmployee }
            addedNewEmployee={ addedNewEmployee }
            approvedList={ approvedList }
            accountSettingsService={ accountSettingsService }
            cloudPaymentService={ cloudPaymentService }
            cartId={ cartId }
            isTrainKaliningradTicket={ isTrainKaliningradTicket }
            addTrainTransitDocument={ addTrainTransitDocument }
            removeEmployeeFromTransitDocuments={ removeEmployeeFromTransitDocuments }
          />
        );
      }) }
    </div>
  );
};

export default EmployeeList;
