import React, { useMemo, useState } from 'react';
import { Text, Price } from 'new-ui';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { getAirlineType, getPriceWithCertificate } from '../../../../bi/utils/airline';
import { dateWithoutMoment, textualMonthPattern } from '../../../../bi/utils/formatDate';
import { groupBy } from '../../../../bi/utils/groupBy';
import { getPaidBaggageInfo } from './utils/getPaidBaggageInfo';

import { VALID_SYMBLOL } from '../../../../bi/constants/cart';
import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import { AIRLINENAMES, ADDITIONAL_SERVICES } from '../../../../bi/constants/airline';

import AirlineItem from '../../../../components/AirlineItem';
import HotelItem from '../../../../components/HotelItem';
import TransferItem from '../../../../components/TransferItem';
import TrainItem from '../../../../components/TrainItem';
import Timer from '../../../../components/Timer';
import EmployeeSimpleList from '../../../../components/EmployeeSimpleList';
import AeroexpressItem from '../../../../components/AeroexpressItem';
import AnalyticsSelect from '../../../../components/AnalyticsSelect';
import { TaxiVoucherItem } from '../../../../components/TaxiVoucheriItem';
import { InsuranceItem } from '../../../../components/InsuranceItem';

import { ISourcesEmployees, ISourcesItem } from '../../../../bi/services/checkout/types';
import { IPassportView } from '../../../../bi/services/employee/types';
import CheckoutService from '../../../../bi/services/checkout';
import Notepad from '../../../../bi/services/notepad';
import { IAnalyticsType } from '../../../../bi/services/customAnalytics/types';

import { TransferViewers } from './components/TransferViewers';
import { AddFee } from './components/AddFee';

import { AirRoute } from '../../../../bi/types/airline';

import styles from './style.module.css';

const createLabels = (t: TFunction) => ({
  SELECTION_SEATS: t('checkout:item.selectionSeats'),
  SELECTION_ADD_BAGGAGE: t('checkout:item.selectionBaggage'),
  SEATS: {
    ONE: t('checkout:item.seats.one'),
    TWO: t('checkout:item.seats.more'),
  },
  SEAT: t('checkout:item.seat'),
  BAGGAGE: t('checkout:item.baggage'),
});

interface ICheckoutItemProps {
  item: ISourcesItem;
  allowedEmployees: ISourcesEmployees[];
  projects: any[],
  documentType: Partial<IPassportView>;
  notepadService: Notepad;
  checkoutService: CheckoutService;
  isMulti: boolean;
  customAnalytics: IAnalyticsType[];
  cartEmployeeBirthday: boolean;
  isPersonalBonusCards: boolean;
  qaAttrPrice?: string;
  isPersonalPaymentMethod: boolean;
}

const CheckoutItem = ({
  item,
  allowedEmployees,
  projects,
  documentType,
  notepadService,
  checkoutService,
  isMulti,
  customAnalytics,
  cartEmployeeBirthday,
  isPersonalBonusCards,
  qaAttrPrice = '',
  isPersonalPaymentMethod,
}: ICheckoutItemProps) => {
  const { t } = useTranslation();
  const LABELS = useMemo(() => createLabels(t), [t]);

  const [itemData] = useState(JSON.parse(item.Data));
  const [metaData] = useState(JSON.parse(item?.Metadata as string));
  const [showTicketBullits] = useState(true);

  const handleTimeIsUp = (value: ISourcesItem) => {
    // @ts-ignore
    notepadService.updateTimeIsUp(value);
  };

  const handleCheckAirlineName = (name: string) => {
    if (!itemData && !itemData.Routes[0] && !itemData.Routes[0].Segments[0]) return false;

    const airlineType = getAirlineType(itemData.Routes[0].Segments[0]);

    return itemData.Routes[0].Segments[0][airlineType].Name === name;
  };

  const renderTimer = () => {
    if (item.IsReserved && item.ServiceType === SERVICETYPE.AIR && item.BookDeadline
        && handleCheckAirlineName(AIRLINENAMES.POBEDA)) {
      return (
        <div className={ styles.timer }>
          <Timer
            deadLine={ item.BookDeadline }
            // @ts-ignore
            currentTime={ checkoutService.get().serverTime }
            onTimeIsUp={ () => handleTimeIsUp(item) }
          />
        </div>
      );
    }

    return null;
  };

  const getFreeSeatsData = ({ Segments }: any) => {
    // @ts-ignore
    const freeSeatsGroupedBySegments = Segments.reduce((acc, { AdditionalServices = [] }) => {
      const freeSeats = AdditionalServices
      // @ts-ignore
        .filter(({ Type, Service }) => Type === ADDITIONAL_SERVICES.Seat && Service.Price === 0);

      if (!freeSeats.length) {
        return acc;
      }

      return [...acc, freeSeats];
    }, []);

    if (!freeSeatsGroupedBySegments.length) {
      return {
        caption: null,
        data: null,
      };
    }

    const hasOneSegmentWithOneSeat = freeSeatsGroupedBySegments.length === 1
        && freeSeatsGroupedBySegments[0].length === 1;

    const label = hasOneSegmentWithOneSeat ? LABELS.SEATS.ONE : LABELS.SEATS.TWO;

    const freeSeatsAsString = freeSeatsGroupedBySegments
    // @ts-ignore
      .map((seats) => seats.map(({ Service: { Number } }) => Number).join(', '))
      .join(' \\ ');

    return {
      caption: label,
      data: freeSeatsAsString,
    };
  };

  const renderCustomAnalytics = () => {
    if (isPersonalPaymentMethod) {
      return null;
    }

    const { UserAnalytics = [] } = item;

    // @ts-ignore
    const analyticsList = customAnalytics.reduce((acc, analytics) => {
      const value = analytics.Values.find(({ Id }) => UserAnalytics.includes(Id));

      if (!value) {
        return acc;
      }

      return [
        ...acc,
        <div key={ analytics.Id } className={ styles.custom_analytics_item }>
          <AnalyticsSelect
            readonly
            analytics={ analytics }
            value={ value }
            selectedClassName={ styles.custom_analytics_item_selected }
          />
        </div>,
      ];
    }, []);

    return <div className={ styles.custom_analytics }>
      {/* @ts-ignore */}
      {analyticsList}
    </div>;
  };

  const renderItem = () => {
    switch (item.ServiceType) {
      case SERVICETYPE.AIR: {
        let pnr = '';

        if (item.IsReserved && metaData.PNR && handleCheckAirlineName(AIRLINENAMES.POBEDA)) {
          pnr = metaData.PNR;
        }

        return itemData.Routes.map((route: AirRoute) => (
          <AirlineItem
            key={ route.ID }
            route={ route }
            meta={ itemData.Metadata }
            pnr={ pnr }
            getFreeSeatsData={ getFreeSeatsData }
            showTicketBullits={ showTicketBullits }
          />
        ));
      }
      case SERVICETYPE.HOTEL: {
        return (
          <HotelItem
            item={ itemData }
              // @ts-ignore
            additionalData={ item.AdditionalData }
          />
        );
      }
      case SERVICETYPE.TRANSFER: {
        return <TransferItem item={ itemData } />;
      }
      case SERVICETYPE.TRAIN: {
        return <TrainItem isCancellation item={ itemData } />;
      }
      case SERVICETYPE.AEROEXPRESS: {
        return <AeroexpressItem item={ itemData } />;
      }
      case SERVICETYPE.TAXI_VOUCHER: {
        return <TaxiVoucherItem item={ itemData } />;
      }
      case SERVICETYPE.INSURANCE: {
        return <InsuranceItem item={ itemData } />;
      }
    }

    return null;
  };

  const renderEmployees = () => (
    <EmployeeSimpleList
      item={ item }
      allowedEmployees={ allowedEmployees }
      isMulti={ isMulti }
      documentType={ documentType }
      projects={ projects }
      cartEmployeeBirthday={ cartEmployeeBirthday }
      isPersonalBonusCards={ isPersonalBonusCards }
      isPersonalPaymentMethod={ isPersonalPaymentMethod }
      readOnlyCompany
    />
  );

  const renderAdditionalService = (label: string, description: string) => (
    <Text
      color='gray'
      type='NORMAL_12'
      className={ styles.additional_info }
    >
      { label } {description}
    </Text>
  );

  const renderEmployeePaidAddServices = (additionalInfo: any, index: number) => {
    const { TravellerId, Type: typeId, Service: { Number, Description } } = additionalInfo;

    const isAdditionalSeats = typeId === ADDITIONAL_SERVICES.Seat;
    const label = isAdditionalSeats ? LABELS.SEAT : LABELS.BAGGAGE;
    const description = isAdditionalSeats ? Number : Description;

    return (
      <EmployeeSimpleList
        item={ {
          ...item,
          EmployeeCartItems: item.EmployeeCartItems.filter(({ EmployeeId }) => EmployeeId === TravellerId),
        } }
        allowedEmployees={ allowedEmployees }
        isMulti={ isMulti }
        documentType={ documentType }
        projects={ projects }
        isPersonalBonusCards={ isPersonalBonusCards }
        index={ index + 1 }
        additionalInfo={ renderAdditionalService(label, description) }
      />
    );
  };

  const renderPaidServiceWrapper = ({
    keyInd,
    serviceLabel,
    routeTitle,
    customAnalyticsComponent,
    services,
    segmentPrice,
  }: any) => (
    <div className={ styles.paid_seat_wrapper } key={ keyInd }>
      <div className={ styles.item }>
        <Text type='bold_18'>{ serviceLabel }</Text>
        <Text className={ styles.seat_info } type='NORMAL_14'>
          { routeTitle }
        </Text>
      </div>
      { customAnalyticsComponent }
      <div className={ styles.passengers }>
        <div className={ styles.employees }>
          {/* @ts-ignore */}
          {services?.map((segment, indexSegment) => renderEmployeePaidAddServices(segment, indexSegment))}
        </div>
        <Price
          className={ styles.price }
          value={ segmentPrice }
          marginSmall
          type='bold_24'
        />
      </div>
    </div>
  );

  const renderPaidBaggage = () => {
    if (item.ServiceType !== SERVICETYPE.AIR) {
      return null;
    }

    const paidBaggageInfo = getPaidBaggageInfo({
      itemData,
      labels: LABELS.SELECTION_ADD_BAGGAGE,
    });

    if (!paidBaggageInfo) {
      return null;
    }

    return paidBaggageInfo.map(baggageInfo => renderPaidServiceWrapper(baggageInfo));
  };

  const renderPaidSeats = () => {
    if (item.ServiceType !== SERVICETYPE.AIR) {
      return null;
    }

    // @ts-ignore
    const paidSeats = itemData.Routes.flatMap(({ Segments }) =>
      Segments.flatMap(({ AdditionalServices = [] }) => AdditionalServices),
      // @ts-ignore
    ).filter((service) => service.Type === ADDITIONAL_SERVICES.Seat && service.Service.Price > 0);

    if (!paidSeats.length) {
      return null;
    }

    // @ts-ignore
    const paidSeatsByFragments = groupBy(paidSeats, ({ SegmentId }) => SegmentId);

    const customAnalyticsHtml = renderCustomAnalytics();

    return Object.keys(paidSeatsByFragments).map((key, index) => {
      // @ts-ignore
      const currentSegment = itemData.Routes
      // @ts-ignore
        .flatMap(({ Segments }) => Segments)
      // @ts-ignore
        .find(({ ID }) => ID === Number(key));
      const airlineType = getAirlineType(currentSegment);

      const { ID: airlineId } = currentSegment[airlineType];

      const {
        FlightNumber,
        DepartureCityCode,
        ArrivalCityCode,
        DepartureCity,
        ArrivalCity,
        DepartureTime,
      } = currentSegment;

      const departureDate = dateWithoutMoment(DepartureTime, textualMonthPattern);

      // @ts-ignore
      const segmentPrice = paidSeatsByFragments[key].reduce(((acc: number, el) => acc + el.Service.Price), 0);

      const routeTitle = `${airlineId} ${FlightNumber}, ${DepartureCity} (${DepartureCityCode}) - ${ArrivalCity} (${ArrivalCityCode}), ${departureDate}`;

      return renderPaidServiceWrapper({
        keyInd: `${currentSegment.ID}_${index}`,
        serviceLabel: LABELS.SELECTION_SEATS,
        routeTitle,
        customAnalytics: customAnalyticsHtml,
        services: paidSeatsByFragments[key],
        segmentPrice,
      });
    });
  };

  const {
    Routes: routes = [],
    FlightCertificate,
    Metadata,
  } = itemData;

  const html = renderItem();
  const employeesHtml = renderEmployees();
  const additionalServicesPrice = routes
  // @ts-ignore
    .flatMap(({ Segments }) => Segments.flatMap(({ AdditionalServices = [] }) => AdditionalServices))
  // @ts-ignore
    .reduce((acc, service) => {
      if (service.Type === ADDITIONAL_SERVICES.Seat) {
        return acc + service.Service.Price;
      }

      if (service.Type === ADDITIONAL_SERVICES.Baggage) {
        return acc + service.Service.Price;
      }

      return acc;
    }, 0);

  const isHasCertificate = item.ServiceType === SERVICETYPE.AIR && FlightCertificate;

  const price = item.Price - additionalServicesPrice;
  const count = Metadata ? Metadata.TravellersCount : 1;
  const priceWithCertificate = isHasCertificate ? getPriceWithCertificate(count, price) : price;

  const paidSeatsHtml = renderPaidSeats();
  const customAnalyticsHtml = renderCustomAnalytics();
  const paidBaggagesHtml = renderPaidBaggage();

  // @ts-ignore
  const isAddFee = !!item.AgentFee && item.AgentFee !== VALID_SYMBLOL.ZERO;

  return (
    <>
      <div className={ styles.wrapper }>
        <div className={ styles.item }>{html}</div>
        { customAnalyticsHtml }
        <div className={ styles.passengers }>
          <div
            className={ styles.employees }
          >
            { employeesHtml }
          </div>
          <div>
            <Price
              qaAttr={ qaAttrPrice }
              className={ styles.price }
              value={ priceWithCertificate }
              marginSmall
              type='bold_24'
            />
            {/* @ts-ignore */}
            <AddFee agentFee={ item.AgentFee } isAddFee={ isAddFee } />
          </div>
        </div>
        <TransferViewers item={ item } itemData={ itemData }/>
        { renderTimer() }
      </div>
      { paidSeatsHtml }
      { paidBaggagesHtml }
    </>
  );
};

export default CheckoutItem;
