import React, { useEffect, useState } from 'react';
import { Button, Input, Text, Textarea } from 'new-ui';

import { getText } from '../../../../../../../i18n';

import { INTEGER } from '../../../../../../bi/constants/regExp';
import { DIRECTION } from '../../../../const';

import { ICalculatorResMini } from '../../../../../../bi/services/events/type';

import styles from './styles/index.module.css';

const LABELS = {
  PEOPLE: getText('event:calculator.city.people'),
  DAY: getText('event:calculator.city.day'),
  COMMENT: getText('event:calculator.city.comment'),
  BUTTON: {
    DONE: getText('event:calculator.button.more'),
    CANCEL: getText('event:calculator.button.cancel'),
  },
  ERROR: getText('event:calculator.errorInput'),
  SUBTITLE_FOR: getText('event:calculator.city.subtitleForgein'),
};

const getIntegerNumber = (value: string) => INTEGER.test(value);

export interface IForeignCity {
  setShowCalculator(value: boolean): void,
  setCalculatorResMini(value: ICalculatorResMini): void,
  onOpenAddEvent(value: boolean): void,
  setIsValue(value: boolean): void,
}

const ForeignCity = ({
  setShowCalculator,
  setCalculatorResMini,
  onOpenAddEvent,
  setIsValue,
}: IForeignCity) => {
  const [people, setPeople] = useState('');
  const [day, setDay] = useState('');
  const [comment, setComment] = useState('');
  const [errorPeople, setErrorPeople] = useState('');
  const [errorDay, setErrorDay] = useState('');

  useEffect(() => {
    setIsValue(!!(day || people || comment));
  }, [day, people, comment]);

  const setValue = (value: string, setValues: (value: string) => void, isPeople = false) => {
    if (getIntegerNumber(value) || value === '') {
      setValues(value);
    }

    if (isPeople) {
      setCalculatorResMini({
        ParticipantsCount: Number(value),
      });
    }
  };

  const setCommentary = (value: string) => {
    setComment(value);

    setCalculatorResMini({
      ParticipantsCount: Number(people),
      Comment: value,
    });
  };

  const handleDone = () => {
    if (!!day && !!people) {
      setErrorDay('');
      setErrorPeople('');

      setCalculatorResMini({
        IsValue: true,
        Direction: DIRECTION.FOREIGN_CITY,
        DaysCount: Number(day),
        ParticipantsCount: Number(people),
        Comment: comment,
      });

      onOpenAddEvent(true);
    } else {
      if (!day) {
        setErrorDay(LABELS.ERROR);
      } else {
        setErrorDay('');
      }

      if (!people) {
        setErrorPeople(LABELS.ERROR);
      } else {
        setErrorPeople('');
      }
    }
  };

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.wrapper_sublabel }>
        <Text type='NORMAL_16'>
          { LABELS.SUBTITLE_FOR }
        </Text>
      </div>
      <div className={ styles.wrapper_input }>
        <div className={ styles.subwrapper_input }>
          <Text className={ styles.text }>
            { LABELS.PEOPLE }
          </Text>
          <Input
            className={ styles.input }
            value={ people }
            error={ errorPeople }
            onChange={ (value) => setValue(value, setPeople, true) }
          />
        </div>
        <div className={ styles.subwrapper_input }>
          <Text className={ styles.text }>
            { LABELS.DAY }
          </Text>
          <Input
            className={ styles.input }
            value={ day }
            error={ errorDay }
            onChange={ (value) => setValue(value, setDay) }
          />
        </div>
      </div>
      <div className={ styles.wrapper_subinput }>
        <Text className={ styles.text }>
          { LABELS.COMMENT }
        </Text>
        <Textarea
          withInput
          noneResize
          className={ styles.text_area }
          value={ comment }
          onChange={ (value) => setCommentary(value) }
        />
      </div>
      <div className={ styles.wrapper_buttons }>
        <Button
          type='secondary'
          onClick={ handleDone }
        >
          { LABELS.BUTTON.DONE }
        </Button>
        <Button
          type='textual'
          className={ styles.second }
          onClick={ () => setShowCalculator(false) }
        >
          { LABELS.BUTTON.CANCEL }
        </Button>
      </div>
    </div>
  );
};

export { ForeignCity };
