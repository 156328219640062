import React, { Component } from 'react';
import { Moment } from 'moment';
import { Paginate, Text, MultiSelect, PageLoader } from 'new-ui';
import { matchPath, RouteComponentProps } from 'react-router-dom';

import { getText } from '../../../i18n';

import FormatService from '../../bi/services/format';
import RequestService from '../../bi/services/requests';

import RequestsItem from '../../components/RequestsItem';
import { CombinedDatepicker } from '../../components/CombinedDatepicker';

import { isBetweenDate } from '../../bi/utils/formatDate';
import scrollToTop from '../utils/scrollToTop';

import { FILTERS_TYPES } from '../../../constants/Request';

import {
  IRequestsData,
  IFilterObject,
  IFilterStatuses,
  IFilterDates,
  IRequestsStore,
} from '../../bi/types/requests';

import styles from './styles/requests.module.css';

interface IRequestsProps {
  history: RouteComponentProps['history'];
  formatService: FormatService;
  requestsService: RequestService;
}

interface IRequestsState {
  loading: boolean;
  requestsList: IRequestsData[];
  filteredRequestsList: IRequestsData[];
  loadingRequestsFlag: boolean;
  visibleRequests: IRequestsData[];
  itemsOnPage: number;
  currentPage: number;
  departments: IFilterObject,
  employees: IFilterObject,
  statuses: IFilterStatuses,
  dates: IFilterDates,
}

const LABELS = {
  ALL: getText('requests:all'),
  EMPLOYEES: getText('requests:employees'),
  DEPARMENTS: getText('requests:departments'),
  COMPANY_AND_DEPARMENTS: getText('requests:companyAndDepartments'),
  SEARCH_FROM_COMPANY_AND_DEPARTMENTS: getText('requests:searchFromCompanyAndDepartments'),
  NO_REQUESTS: getText('requests:noRequests'),
  REQUESTS_1_C: getText('requests:requests1c'),
};

class Requests extends Component<IRequestsProps, IRequestsState> {
  unsubscribeFn: () => void;

  constructor(props: IRequestsProps) {
    super(props);

    const {
      loading,
      requestsList,
      filteredRequestsList,
      loadingRequestsFlag,
      pagination: {
        visibleRequests,
        itemsOnPage,
        currentPage,
      },
      filters: {
        departments,
        statuses,
        employees,
        dates: {
          start,
          end,
        },
      },
    } = this.props.requestsService.get();

    this.state = {
      loading,
      requestsList,
      filteredRequestsList,
      loadingRequestsFlag,
      visibleRequests,
      itemsOnPage,
      currentPage,
      departments,
      employees,
      statuses,
      dates: {
        start,
        end,
      },
    };
  }

  componentDidMount() {
    const { requestsService } = this.props;

    this.unsubscribeFn = requestsService.subscribe(this.update);

    this.openPage();
  }

  componentWillUnmount() {
    const { requestsService } = this.props;

    if (!matchPath(location.pathname, { path: '/requests/:id', exact: true })) {
      requestsService.clearStore();
    }

    this.unsubscribeFn();
  }

  update = ({
    loading,
    requestsList,
    filteredRequestsList,
    loadingRequestsFlag,
    filters: {
      statuses,
      departments,
      employees,
      dates: {
        start,
        end,
      },
    },
    pagination: { visibleRequests, itemsOnPage, currentPage },
  }: IRequestsStore) =>
    this.setState({
      loading,
      loadingRequestsFlag,
      requestsList,
      filteredRequestsList,
      visibleRequests,
      itemsOnPage,
      currentPage,
      departments,
      employees,
      statuses,
      dates: {
        start,
        end,
      },
    });

  openPage = () => {
    const { requestsService } = this.props;
    const { loadingRequestsFlag, requestsList } = this.state;

    return !loadingRequestsFlag && requestsList.length === 0 && requestsService.updateLoadingRequestsFlag();
  };

  handleUseFiletrs = () => {
    const {
      requestsList,
      statuses,
      employees,
      departments,
      dates: {
        start,
        end,
      },
    } = this.state;

    const newRequests = start ? [] : requestsList;
    const date = !end ? start : end;

    const filters = () => newRequests.reduce((acc: IRequestsData[], request: IRequestsData) => {
      const employeesFilter = () => (employees.selected.some(selectedsName =>
        request.EmployeesNames.find(name => name === selectedsName)));
      const departmentsFilter = () => (departments.selected.some(({ main, nested = [] }) =>
        (nested.length ?
          request.Departments.some(department => nested.some((nestedValue: number) => Number(department) === Number(nestedValue)))
          : request.Companies.some(Company => Number(Company) === Number(main))
        ),
      ));
      const statusesFilter = () => (statuses.selected.includes(request.Status));

      const employee = employees.selected.length !== 0 ? employeesFilter() : true;
      const department = departments.selected.length !== 0 ? departmentsFilter() : true;
      const status = statuses.selected.length !== 0 ? statusesFilter() : true;

      const conclusion = employee && department && status && request;

      if (conclusion) {
        return [...acc, conclusion];
      }

      return acc;
    }, []);

    const checkDate = (startDate: Moment | null, endDate: Moment | null) =>
      requestsList.forEach(el =>
        (isBetweenDate(el.CreatedDate, startDate, endDate) && newRequests.push(el)));

    checkDate(start, date);
    this.props.requestsService.changeFilters({ value: filters(), statuses, employees, departments, dates: { start, end } });
  };

  handleDatesChange = (start: Moment | null, end: Moment | null) => {
    this.setState({
      dates: {
        start,
        end,
      },
    }, this.handleUseFiletrs);
  };

  handleChangeSelect = (selected: any[], key: string) => {
    this.setState({
      ...this.state,
      [key]: {
        // @ts-ignore
        list: this.state[key].list,
        selected,
      },
    }, this.handleUseFiletrs);
  };

  handleChangeCompanySelect = (selected: any[], key: string) => {
    this.setState({
      ...this.state,
      [key]: {
        // @ts-ignore
        list: this.state[key].list,
        selected,
      },
    }, this.handleUseFiletrs);
  };

  handlePageChange = (page: number) => {
    // @ts-ignore
    const { history, history: { location: { pathname, query } } } = this.props;

    this.props.requestsService.changePage(page);
    scrollToTop();
    history.push({
      pathname,
      // @ts-ignore
      query: { ...query, currentPage: page > 1 ? page : [] },
    });
  };

  renderLoading = () => (
    <div className={ styles.loader }>
      <PageLoader />
    </div>
  );

  renderEmpty() {
    return (
      <div className={ styles.empty }>
        <div className={ styles['icon-wrapper'] }>
          <span className='smartway-document icon' />
        </div>
        <div className={ styles['title-wrapper'] }>
          <span>{LABELS.NO_REQUESTS}</span>
        </div>
      </div>
    );
  }

  renderDateSelect = () => {
    const { dates } = this.state;

    return (
      <div className={ styles['select-wrapper'] }>
        <CombinedDatepicker
          date={ dates.start }
          optionalDate={ dates.end }
          // @ts-ignore
          onChange={ this.handleDatesChange }
          className={ styles['select-class'] }
        />
      </div>
    );
  };

  renderStatusSelect = () => {
    const { statuses: { list, selected } } = this.state;

    return (
      <div className={ styles['select-wrapper'] }>
        <MultiSelect
          list={ list }
          values={ selected }
          placeholder={ LABELS.ALL }
          onChange={ values => this.handleChangeSelect(values, FILTERS_TYPES.STATUSES) }
        />
      </div>
    );
  };

  renderEmployeeSelect = () => {
    const { employees: { list, selected } } = this.state;

    return (
      <div className={ styles['select-wrapper'] }>
        <MultiSelect
          search
          list={ list }
          values={ selected }
          placeholder={ LABELS.EMPLOYEES }
          onChange={ values => this.handleChangeSelect(values, FILTERS_TYPES.EMPLOYEES) }
        />
      </div>
    );
  };

  renderDepartmentSelect = () => {
    const { departments: { list, selected } } = this.state;
    const hasDepartments = list.some(({ nested }) => nested && nested.length);

    if (list.length === 1 && hasDepartments) {
      const companyId = list[0].value;
      const values = selected[0] ? selected[0].nested : [];

      return (
        <div className={ styles['select-wrapper'] }>
          <MultiSelect
            withLabel
            placeholder={ LABELS.DEPARMENTS }
            list={ list.reduce((res, { nested }) => ([...res, ...nested]), []) }
            values={ values }
            onChange={ nested => this.handleChangeCompanySelect([{ main: `${companyId}`, nested }], FILTERS_TYPES.DEPARTMENTS) }
          />
        </div>
      );
    }

    if (list.length > 1) {
      return (
        <div className={ styles['select-wrapper'] }>
          <MultiSelect
            placeholder={ LABELS.COMPANY_AND_DEPARMENTS }
            values={ selected }
            list={ list }
            search
            searchPlaceholder={ LABELS.SEARCH_FROM_COMPANY_AND_DEPARTMENTS }
            onChange={ values => this.handleChangeCompanySelect(values, FILTERS_TYPES.DEPARTMENTS) }
          />
        </div>
      );
    }

    return null;
  };

  renderFilter = () => (
    <div className={ styles['filters-wrapper'] }>
      {this.renderStatusSelect()}
      {this.renderDateSelect()}
      {this.renderEmployeeSelect()}
      {this.renderDepartmentSelect()}
    </div>
  );

  renderRequests = () => this.state.visibleRequests.map(request => (
    <RequestsItem
      key={ request.Id }
      request={ request }
      formatService={ this.props.formatService }
    />
  ));

  renderList() {
    const {
      filteredRequestsList,
      visibleRequests,
      itemsOnPage,
      currentPage,
      requestsList,
    } = this.state;

    const requestsHtml = visibleRequests.length
      ? this.renderRequests()
      : this.renderEmpty();

    const filtersHtml = requestsList.length > 0 ? this.renderFilter() : null;

    return (
      <div className={ styles.wrapper }>
        <div className={ styles.header }>
          <Text
            type='bold_32'
            className={ styles.header }
          >
            {LABELS.REQUESTS_1_C}
          </Text>
          {filtersHtml}
        </div>
        <div className={ styles.list }>
          { requestsHtml }
          <div className={ styles.paginate }>
            <Paginate
              onChange={ this.handlePageChange }
              page={ currentPage }
              itemsPerPage={ itemsOnPage }
              total={ filteredRequestsList.length }
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { loading } = this.state;

    return loading ? this.renderLoading() : this.renderList();
  }
}

export default Requests;
