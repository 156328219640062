import React from 'react';
import { Dialog, Button, Text } from 'new-ui';

import { getText } from '../../../../i18n';

import styles from '../index.module.css';

const LABELS = {
  TITLE: getText('payment:commissionErrorDialog.title'),
  DESCRIPTION: getText('payment:commissionErrorDialog.description'),
  REFRESH: getText('payment:commissionErrorDialog.refresh'),
  CLOSE: getText('payment:commissionErrorDialog.close'),
};

interface ICommissionErrorDialogProps {
  show: boolean;
  onToggleShow: (show: boolean) => void;
}

export const CommissionErrorDialog = ({
  show,
  onToggleShow,
}: ICommissionErrorDialogProps) => {
  const handleRefreshPage = () => {
    document.location.reload();
  };

  const handleCloseDialog = () => {
    onToggleShow(false);
  };

  return (
    <Dialog
      show={ show }
      onChange={ onToggleShow }
      className={ styles.error_dialog }
    >
      <Text type='bold_20'>
        { LABELS.TITLE }
      </Text>

      <Text type='NORMAL_16'>
        { LABELS.DESCRIPTION }
      </Text>

      <div className={ styles.dialog_actions }>
        <Button
          onClick={ handleRefreshPage }
          type={ 'secondary' }
        >
          { LABELS.REFRESH }
        </Button>
        <Button
          onClick={ handleCloseDialog }
          type={ 'textual-medium' }
        >
          { LABELS.CLOSE }
        </Button>
      </div>
    </Dialog>
  );
};
